import {IEntityTagContainer} from '../../CommonInterfaces/EntityTag';

export interface IEditableSchool {
  'name_short': string,
  'name_full': string,
  'address': string,
  'phone_number': string,
  'email': string,
  'district': string,
  'tags': IEntityTagContainer
}

export const createEmptyEditableSchool = (): IEditableSchool => {
  return {
    'name_short': '',
    'name_full': '',
    'address': '',
    'phone_number': '',
    'email': '',
    'district': '',
    'tags': {
      0: {
        name: '',
      },
    },
  };
};
