import {Typography} from '@material-ui/core';
import React from 'react';
import {IWrapper} from '../../../../../CommonInterfaces/IWrapper';

export const LineBody = ({children}: IWrapper) => {
  return (
    <Typography variant="body1"
      gutterBottom>
      {children}
    </Typography>
  );
};
