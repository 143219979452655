import {vsprintf} from 'sprintf-js';
import {LMessages} from '../Localization/Localization';

interface IUriGenerator {
  action: string,
  id?: string | string[],
}

interface IURiElement {
  api: string,
  redirect: string,
  success_message: string,
  id_required: boolean
}

interface IUriStorage {
  [key: string]: IURiElement
}

const idPlaceholder = '%s';

export const actions: IUriStorage =
  {
    /*
        get entities data for table filters
     */
    'DISTRICT_LIST': {
      api: process.env.REACT_APP_API_URI + `districts/list`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'ACADEMIC_YEAR_LIST': {
      api: process.env.REACT_APP_API_URI + `academic_years/list`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'DOMAIN_LIST': {
      api: process.env.REACT_APP_API_URI + `domains/list`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'LEVEL_LIST': {
      api: process.env.REACT_APP_API_URI + `levels/list`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'TYPES_LIST': {
      api: process.env.REACT_APP_API_URI + `types/list`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'CHAMPIONSHIPS_LIST': {
      api: process.env.REACT_APP_API_URI + `championships/list`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'FORMS_LIST': {
      api: process.env.REACT_APP_API_URI + `forms/list`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'STATUS_LIST': {
      api: process.env.REACT_APP_API_URI + `statuses/list`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'EVENT_LIST': {
      api: process.env.REACT_APP_API_URI + `events/list`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'SUBJECTS_LIST': {
      api: process.env.REACT_APP_API_URI + `subjects/list`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'SCHOOLS_LIST': {
      api: process.env.REACT_APP_API_URI + `schools/list`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'EVENT_SCHOOLS_ALLOWED_LIST': {
      api: process.env.REACT_APP_API_URI + `schools-allowed/list`,
      redirect: '',
      success_message: '',
      id_required: false,
    },

    'USER_CREATE': {
      api: process.env.REACT_APP_API_URI + 'user',
      redirect: '/users/list',
      success_message: LMessages.USER_CREATED_SUCCESS,
      id_required: false,
    },
    'USER_UPDATE': {
      api: process.env.REACT_APP_API_URI + `user/${idPlaceholder}`,
      redirect: `/user/${idPlaceholder}`,
      success_message: LMessages.USER_UPDATED_SUCCESS,
      id_required: true,
    },
    'DISTRICT_SCHOOL_LIST': {
      api: process.env.REACT_APP_API_URI + `district/${idPlaceholder}/schools`,
      redirect: '',
      success_message: '',
      id_required: true,
    },
    'DISTRICT_NAME': {
      api: process.env.REACT_APP_API_URI + `district/${idPlaceholder}/name`,
      redirect: '',
      success_message: '',
      id_required: true,
    },
    'SCHOOL_NAME': {
      api: process.env.REACT_APP_API_URI + `school/${idPlaceholder}/name`,
      redirect: '',
      success_message: '',
      id_required: true,
    },
    'USERS_INDEX': {
      api: process.env.REACT_APP_API_URI + `users`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'STUDENTS_INDEX': {
      api: process.env.REACT_APP_API_URI + `students`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'TEACHERS_INDEX': {
      api: process.env.REACT_APP_API_URI + `teachers`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'SCHOOLS_INDEX': {
      api: process.env.REACT_APP_API_URI + `schools`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'SCHOOL_TAGS_LIST': {
      api: process.env.REACT_APP_API_URI + `school-tags`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'SCHOOL_CREATE': {
      api: process.env.REACT_APP_API_URI + 'school',
      redirect: '/schools/list',
      success_message: LMessages.SCHOOL_CREATED_SUCCESS,
      id_required: false,
    },
    'SCHOOL_UPDATE': {
      api: process.env.REACT_APP_API_URI + `school/${idPlaceholder}`,
      redirect: `/school/${idPlaceholder}`,
      success_message: LMessages.SCHOOL_UPDATED_SUCCESS,
      id_required: true,
    },
    'SCHOOL_DELETE': {
      api: process.env.REACT_APP_API_URI + `school/${idPlaceholder}/delete`,
      redirect: `/schools/list`,
      success_message: LMessages.SCHOOL_DELETED_SUCCESS,
      id_required: true,
    },

    'DISTRICTS_INDEX': {
      api: process.env.REACT_APP_API_URI + `districts`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'DISTRICTS_TAGS_LIST': {
      api: process.env.REACT_APP_API_URI + `district-tags`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'DISTRICT_CREATE': {
      api: process.env.REACT_APP_API_URI + 'district',
      redirect: '/districts/list',
      success_message: LMessages.DISTRICT_CREATED_SUCCESS,
      id_required: false,
    },
    'DISTRICT_UPDATE': {
      api: process.env.REACT_APP_API_URI + `district/${idPlaceholder}`,
      redirect: `/district/${idPlaceholder}`,
      success_message: LMessages.DISTRICT_UPDATED_SUCCESS,
      id_required: true,
    },
    'DISTRICT_DELETE': {
      api: process.env.REACT_APP_API_URI + `district/${idPlaceholder}/delete`,
      redirect: `/districts/list`,
      success_message: LMessages.DISTRICT_DELETED_SUCCESS,
      id_required: true,
    },

    'EVENTS_INDEX': {
      api: process.env.REACT_APP_API_URI + `events`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'EVENT_CREATE': {
      api: process.env.REACT_APP_API_URI + 'event',
      redirect: '/events/list',
      success_message: LMessages.EVENT_CREATED_SUCCESS,
      id_required: false,
    },
    'EVENT_UPDATE': {
      api: process.env.REACT_APP_API_URI + `event/${idPlaceholder}`,
      redirect: `/event/${idPlaceholder}`,
      success_message: LMessages.EVENT_UPDATED_SUCCESS,
      id_required: true,
    },
    'EVENT_DELETE': {
      api: process.env.REACT_APP_API_URI + `event/${idPlaceholder}/delete`,
      redirect: `/events/list`,
      success_message: LMessages.EVENT_DELETED_SUCCESS,
      id_required: true,
    },

    'RESULTS_INDEX': {
      api: process.env.REACT_APP_API_URI + `results`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'RESULT_CREATE': {
      api: process.env.REACT_APP_API_URI + 'result',
      redirect: '/results/list',
      success_message: LMessages.RESULT_CREATED_SUCCESS,
      id_required: false,
    },
    'RESULT_CREATE_AND_STAY': {
      api: process.env.REACT_APP_API_URI + 'result',
      redirect: '',
      success_message: LMessages.RESULT_CREATED_SUCCESS,
      id_required: false,
    },
    'RESULT_UPDATE': {
      api: process.env.REACT_APP_API_URI + `result/${idPlaceholder}`,
      redirect: '/results/list',
      success_message: LMessages.RESULT_UPDATED_SUCCESS,
      id_required: true,
    },
    'RESULT_DELETE': {
      api: process.env.REACT_APP_API_URI + `result/${idPlaceholder}/delete`,
      redirect: `/results/list`,
      success_message: LMessages.RESULT_DELETED_SUCCESS,
      id_required: true,
    },

    'RESULT_UPLOADS_INDEX': {
      api: process.env.REACT_APP_API_URI + `result-uploads`,
      redirect: '',
      success_message: '',
      id_required: false,
    },

    'RESULT_UPLOAD_CANCEL': {
      api: process.env.REACT_APP_API_URI +
        `result-upload/${idPlaceholder}/cancel`,
      redirect: '',
      success_message: LMessages.RESULT_UPLOAD_CANCEL_SUCCESS,
      id_required: true,

    },
    'RESULT_UPLOAD_APPLY': {
      api: process.env.REACT_APP_API_URI +
        `result-upload/${idPlaceholder}/apply`,
      redirect: '',
      success_message: LMessages.RESULT_UPLOAD_APPLY_SUCCESS,
      id_required: true,
    },

    'RESULTS_IMPORT': {
      api: process.env.REACT_APP_API_URI + `results/import/${idPlaceholder}`,
      redirect: '/result-upload/',
      success_message: LMessages.RESULT_IMPORT_SUCCESS,
      id_required: true,
    },
    'RESULTS_DOWNLOAD_BLANK': {
      api: process.env.REACT_APP_API_URI + `results/blank`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'LOGIN': {
      api: process.env.REACT_APP_API_URI + `login`,
      redirect: '',
      success_message: LMessages.LOGIN_SUCCESS,
      id_required: false,
    },
    'LOGOUT': {
      api: process.env.REACT_APP_API_URI + `logout`,
      redirect: '',
      success_message: LMessages.LOGOUT_SUCCESS,
      id_required: false,
    },
    'SCRF_COOKIE': {
      api: process.env.REACT_APP_API_URI + `csrf-cookie`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'ME': {
      api: process.env.REACT_APP_API_URI + `me`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'FORGOT_PASSWORD': {
      api: process.env.REACT_APP_API_URI + `forgot-password`,
      redirect: '',
      success_message: '',
      id_required: false,
    },
    'RESET_PASSWORD': {
      api: process.env.REACT_APP_API_URI + `reset-password`,
      redirect: '/',
      success_message: '',
      id_required: false,
    },

    'REPORT_MAIN_INDICATORS': {
      api: process.env.REACT_APP_API_URI + `report/main-indicators`,
      redirect: '',
      success_message: LMessages.REPORT_SUCCESS,
      id_required: false,
    },
    'REPORT_SUBJECTS': {
      api: process.env.REACT_APP_API_URI + `report/subjects`,
      redirect: '',
      success_message: LMessages.REPORT_SUCCESS,
      id_required: false,
    },
    'REPORT_FULL_STUDENTS': {
      api: process.env.REACT_APP_API_URI + `report/full-students`,
      redirect: '',
      success_message: LMessages.REPORT_SUCCESS,
      id_required: false,
    },
    'REPORT_STUDENT_RESULTS': {
      api: process.env.REACT_APP_API_URI + `report/student-results`,
      redirect: '',
      success_message: LMessages.REPORT_SUCCESS,
      id_required: false,
    },
    'REPORT_TUTORS': {
      api: process.env.REACT_APP_API_URI + `report/tutors`,
      redirect: '',
      success_message: LMessages.REPORT_SUCCESS,
      id_required: false,
    },
    'REPORT_ORGANIZATIONS': {
      api: process.env.REACT_APP_API_URI + `report/organizations`,
      redirect: '',
      success_message: LMessages.REPORT_SUCCESS,
      id_required: false,
    },

    'CONFLICT_RESOLVE_OPTIONS': {
      api: process.env.REACT_APP_API_URI +
        `conflict/${idPlaceholder}/options/${idPlaceholder}`,
      redirect: '',
      success_message: '',
      id_required: true,
    },
    'CONFLICT_RESOLVE': {
      api: process.env.REACT_APP_API_URI +
        `conflict/${idPlaceholder}/resolve`,
      redirect: '',
      success_message: LMessages.RESOLVE_SUCCESS,
      id_required: true,
    },
  };

interface IGetURI {
  entity: string,
  id: string
}

export const getEntityDetailsURI = ({entity, id}: IGetURI) => {
  return `${process.env.REACT_APP_API_URI}${entity}/${id}`;
};

interface IUriGeneratorResult {
  api: string,
  redirect: string,
  success_message: string
}

export const uriGenerator = ({
  action,
  id,
}: IUriGenerator): IUriGeneratorResult => {
  const rule = actions[action];
  if (rule.id_required && !id) {
    throw new Error(`URIGenerator: id should be provided for rule ${action}`);
  }
  if (rule.id_required && id) {
    let fId: string[];
    if (!Array.isArray(id)) {
      fId = [id];
    } else {
      fId = id;
    }

    return {
      api: vsprintf(rule.api, fId),
      redirect: vsprintf(rule.redirect, fId),
      success_message: rule.success_message,
    };
  } else {
    return {
      api: rule.api,
      redirect: rule.redirect,
      success_message: rule.success_message,
    };
  }
};
