export interface IMainIndicatorRequest {
  [index: string]: string,

  'academic_year': string,
  'event_id': string,
  'district_id': string,
}

export const createMainIndicatorRequest = (): IMainIndicatorRequest => {
  return {
    'academic_year': '',
    'event_id': '',
    'district_id': '',
  };
};

export interface ICommonRequest {
  [index: string]: string,

  'academic_year': string,
  'event_id': string,
  'district_id': string,
  'level_id': string,
  'subject_id': string,
  'school_id': string,
  'championship_id': string,
}

export const createCommonRequest = (): ICommonRequest => {
  return {
    'academic_year': '',
    'event_id': '',
    'district_id': '',
    'level_id': '',
    'subject_id': '',
    'school_id': '',
    'championship_id': '',
  };
};
