import {navigate} from '@reach/router';
import React, {useContext, useEffect} from 'react';
import {apiService} from '../../Helpers/apiService';
import {uriGenerator} from '../../Helpers/UriGenerator';
import {useAppDispatch} from '../../hooks';
import LoginCard from '../../pages/auth/LoginCard';
import {up} from '../../Store/lastRequest';
import {unauthorizedUser, UserContext} from './UserContext';

/**
 * Get current user details.
 * @param setCurrentUser
 * @returns {Promise<void>}
 */
export async function getMe(setCurrentUser: any) {
  const {api} = uriGenerator({action: 'ME'});
  await apiService.get(api).then((response) => {
    setCurrentUser(response.data.data.user);
  });
}

const PrivateRoute = ({as: Component, location, ...props}: any) => {
  if (['/logout', '/login'].indexOf(location?.pathname) !== -1) {
    navigate('/');
  }

  const {currentUser, setCurrentUser} = useContext(UserContext);

  useEffect(() => {
    getMe(setCurrentUser);
  }, [currentUser.authorized, setCurrentUser]);

  const apiDefaultErrorHandler = (error: any) => {
    if ([401, 419].indexOf(error.response.status) !== -1) {
      setCurrentUser(unauthorizedUser);
    }
  };

  const dispatch = useAppDispatch();

  apiService.interceptors.response.use(function(response) {
    dispatch(up());
    return response;
  }, function(error) {
    apiDefaultErrorHandler(error);
    return Promise.reject(error);
  });

  if (currentUser.authorized === undefined) {
    return <div/>;
  }

  return (
    <div>
      {currentUser.authorized ? <Component {...props}/> : <LoginCard/>}
    </div>
  );
};

export default PrivateRoute;
