import {Link, RouteComponentProps} from '@reach/router';
import {Column} from 'material-table';
import React from 'react';
import {IRowData} from '../../../CommonInterfaces/IRowData';
import {ExportButton} from '../../../Components/Buttons/ExportButton';
import Row from '../../../Components/Layout/Row';
import {RowOfButtons} from '../../../Components/Layout/RowOfButtons';
import PageTitle from '../../../Components/PageTitle/PageTitle';
import {SchoolFilter} from '../../../Components/UniversalRListTable/Filters/SchoolFilter';
import {DistrictContext} from '../../../Components/UniversalRListTable/Helpers/DistrictContext';
import {useDistrictState} from '../../../Components/UniversalRListTable/Helpers/useDistrictState';
import {UniversalRListTable} from '../../../Components/UniversalRListTable/UniversalRListTable';
import {IChildTable} from './IChildTable';

/**
 * School's teacher r-list.
 * @param {RouteComponentProps} props
 * @return {JSX.Element}
 * @constructor
 */
export default function SchoolTeachersRList({
  parentId,
  titleSuffix,
}: IChildTable & RouteComponentProps) {
  const DC = useDistrictState();

  const apiUriPrefix = '/teacher/';
  const Columns: Column<IRowData>[] =
    [
      {
        title: '#',
        field: 'id',
        filtering: false,
      },
      {
        title: 'Фамилия',
        field: 'last_name',
        render: (rowData): React.ReactNode =>
          <Link to={`${apiUriPrefix}${rowData.id}`}>{rowData.last_name}</Link>,
      },
      {
        title: 'Имя',
        field: 'first_name',
        render: (rowData): React.ReactNode =>
          <Link to={`${apiUriPrefix}${rowData.id}`}>{rowData.first_name}</Link>,
      },
      {
        title: 'Отчество',
        field: 'second_name',
        render: (rowData): React.ReactNode =>
          <Link
            to={`${apiUriPrefix}${rowData.id}`}>{rowData.second_name}</Link>,
      },
      {
        title: 'Организация',
        field: 'school.name',
        defaultFilter: parentId,
        hidden: true,
        filterComponent: SchoolFilter,
      },
    ];
  const lastQueryKeyName = 'schoolTeachersReadList';

  return (
    <React.Fragment>
      <PageTitle title={`Наставники ${titleSuffix}`}/>
      <RowOfButtons>
        <ExportButton name={lastQueryKeyName}/>
      </RowOfButtons>

      <Row>
        <DistrictContext.Provider value={DC}>
          <UniversalRListTable
            columns={Columns}
            action={'TEACHERS_INDEX'}
            tableName={lastQueryKeyName}
          />
        </DistrictContext.Provider>
      </Row>

    </React.Fragment>
  );
}
