import {Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {IIdNameEntityContainer} from '../../CommonInterfaces/IIdNameObject';
import {apiService} from '../../Helpers/apiService';
import {uriGenerator} from '../../Helpers/UriGenerator';

export interface IASCContainer {
  [index: number]: IIdNameEntityContainer

  'allowed_schools': IIdNameEntityContainer,
  'allowed_school_tags': IIdNameEntityContainer,
  'allowed_district_tags': IIdNameEntityContainer,
}

export const emptyASCContainer = {
  'allowed_schools': {},
  'allowed_school_tags': {},
  'allowed_district_tags': {},
};

interface IAllowedSchoolsCard {
  allowedBy: IASCContainer
}


export const AllowedSchoolsCard = ({allowedBy}: IAllowedSchoolsCard) => {
  const [schools, setSchools] = useState<string[]>();
  useEffect(() => {
    const responseHandler = (response: any) => {
      setSchools(response.data.data);
    };
    const {api} = uriGenerator({action: 'EVENT_SCHOOLS_ALLOWED_LIST'});
    apiService.post(api, allowedBy).then(responseHandler);
  }, [allowedBy]);

  const line = schools ? schools.map((el: any, index) => {
      return <span key={el.id}>{el.name}
        {(index !== schools.length - 1) ?
          ',' :
          ''} </span>;
    },
  ) : '';

  return (
    <React.Fragment>
      <Typography variant="body1">
        {line}
      </Typography>
    </React.Fragment>
  );
};
