import {
  Button,
  makeStyles,
  MobileStepper,
  Typography,
  useTheme,
} from '@material-ui/core';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@material-ui/icons';
import produce from 'immer';
import {useSnackbar} from 'notistack';
import React, {Dispatch, SetStateAction} from 'react';
import {IZeroArgumentVoidCallback} from '../../../CommonInterfaces/Callbacks';
import Row from '../../../Components/Layout/Row';
import PageTitle from '../../../Components/PageTitle/PageTitle';
import {makeApiPostActionHandler} from '../../../Helpers/ApiRequestHelper';
import {ConflictDecisions, Decision} from './ResolveConflictCard';
import {StudentSchoolConflictResolveCard} from './StudentSchoolConflictResolveCard';
import {TeacherSchoolConflictResolveCard} from './TeacherSchoolConflictResolveCard';

interface IResolveConflictCardContent {
  data: any
  conflicts: {
    type: number
  }[]
  id: string
  reload: IZeroArgumentVoidCallback
}

interface ICanMakeDecisions {
  decisions: ConflictDecisions
  setDecisions: Dispatch<SetStateAction<ConflictDecisions>>
}

export const ResolveConflictCardContent = ({
  data,
  conflicts,
  id,
  decisions,
  setDecisions,
  reload,
}: IResolveConflictCardContent & ICanMakeDecisions) => {
  const useStyles = makeStyles({
    root: {
      maxWidth: 400,
      flexGrow: 1,
    },
  });

  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxStep = conflicts.length - 1;
  const handleNext = () => {
    if (activeStep === maxStep) {
      handleSave();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const type = conflicts[activeStep].type;

  const getDecisionIndexByType = (type: number) => {
    return decisions.resolve.findIndex((el) => {
      return el.conflict === type;
    });
  };

  const getDecisionValueByType = (type: number): Decision => {
    const idx = getDecisionIndexByType(type);
    if (idx === -1) {
      return {conflict: type, decision: 0, payload: 0};
    }
    return decisions.resolve[idx];
  };

  const saveDecision = (decision: Decision) => {
    const push = ((prev: ConflictDecisions) => {
      return produce(prev, (draft: ConflictDecisions) => {
        draft.resolve.push(decision);
      });
    });
    const update = ((prev: ConflictDecisions) => {
      return produce(prev, (draft: ConflictDecisions) => {
        draft.resolve[idx] = decision;
      });
    });
    const idx = getDecisionIndexByType(decision.conflict);
    const action = (idx === -1) ? push : update;
    setDecisions(action);
  };
  const {enqueueSnackbar} = useSnackbar();

  const handleSave = makeApiPostActionHandler({
    action: 'CONFLICT_RESOLVE', id: id as string,
    snackBar: enqueueSnackbar,
    onSuccess: reload,
    data: decisions,
  });

  return (
    <React.Fragment>
      <PageTitle
        title={`Разрешение конфликтов загрузки #${data.id}`}/>
      <Row>
        <Typography variant={'subtitle1'}>
          {`Строк с конфликтами: ${data.meta.total} `}
        </Typography>
      </Row>

      <Row>
        {[1, 4].indexOf(type) !== -1 ?
          <StudentSchoolConflictResolveCard
            decision={getDecisionValueByType(type)}
            saveDecision={saveDecision}
            id={id}
            data={data}
            type={type as unknown as string}
          /> :
          null}
        {[2, 5].indexOf(type) !== -1 ?
          <TeacherSchoolConflictResolveCard
            decision={getDecisionValueByType(type)}
            saveDecision={saveDecision}
            id={id}
            data={data}
            type={type as unknown as string}
          /> :
          null}
        {[3, 6].indexOf(type) !== -1 ?
          <TeacherSchoolConflictResolveCard
            decision={getDecisionValueByType(type)}
            saveDecision={saveDecision}
            id={id}
            data={data}
            type={type as unknown as string}
          /> :
          null}

        <MobileStepper
          variant="dots"
          steps={maxStep + 1}
          position="static"
          activeStep={activeStep}
          className={classes.root}
          nextButton={
            <Button onClick={handleNext}
              disabled={getDecisionValueByType(type).decision === 0}>
              {activeStep === maxStep ? 'Сохранить' : 'Следующий'}
              {theme.direction === 'rtl' ?
                <KeyboardArrowLeft/> :
                <KeyboardArrowRight/>}
            </Button>
          }
          backButton={
            <Button onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ?
                <KeyboardArrowRight/> :
                <KeyboardArrowLeft/>}
              Предыдущий
            </Button>
          }
        />
      </Row>
    </React.Fragment>
  );
};
