import {Dispatch, SetStateAction} from 'react';
import {
  IdNameEntity,
  IIdNameObject,
  ValueTitleObject,
} from '../../../../CommonInterfaces/IIdNameObject';

export const responseToIdNameOptions = (
  response: any, setOptions: Dispatch<SetStateAction<any>>) => {
  setOptions(buildResponseToIdNameOptions(response));
};

export const buildResponseToIdNameOptions = (response: any) => {
  const data = response.data.data;
  const resObj: IIdNameObject = {};
  const selectOptions: ValueTitleObject[] = data.map(
    (value: IdNameEntity) => {
      resObj[value.id] = value.name;
      return {
        value: value.id,
        title: value.name,
      };
    });
  return selectOptions;
};
