import {Typography} from '@material-ui/core';
import * as R from 'ramda';
import React, {useEffect, useState} from 'react';
import {SubjectClass} from '../../../CommonInterfaces/IIdNameObject';
import {ITeacherEntity} from '../../../CommonInterfaces/ITeacherEntity';
import {LineBody} from './Lines/Elements/LineBody';
import {IGetLineValue} from './LineValue';

const makeSubjectClass = (value: any) => {
  return value.map((el: SubjectClass, index: number) => {
    return <Typography variant="body1"
      gutterBottom key={index}>{el.subject.name}: {el.class}</Typography>;
  });
};
const makeTeachers = (value: any) => {

  return value.map((el: ITeacherEntity, index: number) => {
    return <LineBody key={index}>{el.last_name} {el.first_name}</LineBody>;
  });
};

/**
 *
 * @param {string} fieldName
 * @param {IUserData} content
 * @returns {JSX.Element}
 * @constructor
 */
export function LineList({
  fieldName,
  content,
}: IGetLineValue) {
  const [data, setData] = useState<string | JSX.Element[] | null>(null);
  useEffect(() => {
    if (R.isEmpty(content)) {
      return;
    }
    const value = content[fieldName];

    if (Array.isArray(value)) {
      let line;
      if (fieldName === 'subject_class') {
        line = makeSubjectClass(value);
      }
      if (fieldName === 'teachers') {
        line = makeTeachers(value);
      }

      setData(line);
    }
  }, [fieldName, content]);

  return (
    <React.Fragment>
      {data}
    </React.Fragment>
  );
}
