import {EditCellColumnDef} from 'material-table';
import React, {Dispatch, SetStateAction} from 'react';
import {IFilterGenerator} from '../IFilterGenerator';

/**
 * Build handler of filter change.
 * @param {React.ChangeEvent} e
 * @param setValue
 * @param props
 */
export function buildApplyFilterChange(
  setValue: Dispatch<SetStateAction<any>>, props: IFilterGenerator) {
  return (e: React.ChangeEvent<{ value: unknown }>) => {
    const val = e.target.value as string;
    setValue(val);
    props.onFilterChanged(
      (props.columnDef as EditCellColumnDef).tableData.id.toString(), val);
  };
}
