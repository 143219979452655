import {Link, RouteComponentProps} from '@reach/router';
import {Column} from 'material-table';
import React from 'react';
import {IRowData} from '../../../CommonInterfaces/IRowData';
import {ExportButton} from '../../../Components/Buttons/ExportButton';
import Row from '../../../Components/Layout/Row';
import {RowOfButtons} from '../../../Components/Layout/RowOfButtons';
import PageTitle from '../../../Components/PageTitle/PageTitle';
import {SchoolFilter} from '../../../Components/UniversalRListTable/Filters/SchoolFilter';
import {
  AccessByFilter,
  roleFilter,
  yesNoFilter,
} from '../../../Components/UniversalRListTable/Filters/StaticFilters';
import {DistrictContext} from '../../../Components/UniversalRListTable/Helpers/DistrictContext';
import {useDistrictState} from '../../../Components/UniversalRListTable/Helpers/useDistrictState';
import {UniversalRListTable} from '../../../Components/UniversalRListTable/UniversalRListTable';
import {IChildTable} from './IChildTable';

/**
 * School's users R-list.
 * @param {RouteComponentProps} props
 * @return {JSX.Element}
 * @constructor
 */
export default function SchoolUsersRList({
  parentId,
  titleSuffix,
}: IChildTable & RouteComponentProps) {
  const DC = useDistrictState();

  const apiUriPrefix = '/user/';
  const Columns: Column<IRowData>[] =
    [
      {
        title: '#',
        field: 'id',
        filtering: false,
      },
      {
        title: 'Логин',
        field: 'login',
      },
      {
        title: 'Фамилия',
        field: 'last_name',
        render: (rowData): React.ReactNode =>
          <Link to={`${apiUriPrefix}${rowData.id}`}>{rowData.last_name}</Link>,
      },
      {
        title: 'Имя',
        field: 'first_name',
        render: (rowData): React.ReactNode =>
          <Link to={`${apiUriPrefix}${rowData.id}`}>{rowData.first_name}</Link>,
      },
      {
        title: 'Отчество',
        field: 'second_name',
        render: (rowData): React.ReactNode =>
          <Link
            to={`${apiUriPrefix}${rowData.id}`}>{rowData.second_name}</Link>,
      },
      {
        title: 'Email',
        field: 'email',
      },
      {
        title: 'Организация',
        field: 'school.name',
        defaultFilter: parentId,
        filterComponent: SchoolFilter,
        hidden: true,
      },
      {
        title: 'Телефон личный',
        field: 'phone_number_personal',
      },
      {
        title: 'Телефон рабочий',
        field: 'phone_number_work',
      },
      {
        title: 'Активный',
        field: 'is_active',
        defaultFilter: 2,
        lookup: {
          1: 'Нет',
          2: 'Да',
        },
        filterComponent: yesNoFilter,
      },
      {
        title: 'Роль',
        field: 'role_id',
        lookup: {
          1: 'Администратор',
          2: 'Куратор',
        },
        defaultFilter: 0,
        filterComponent: roleFilter,
      },
      {
        title: 'Доступ по',
        field: 'access_rule',
        defaultFilter: 0,
        lookup: {
          1: 'Школе',
          2: 'Району',
        },
        filterComponent: AccessByFilter,
      },
      {
        title: 'Последняя авторизация',
        field: 'last_auth',
        filtering: false,
      },
    ];
  const lastQueryKeyName = 'schoolUsersReadList';

  return (
    <React.Fragment>
      <PageTitle title={`Пользователи ${titleSuffix}`}/>
      <RowOfButtons>
        <ExportButton name={lastQueryKeyName}/>
      </RowOfButtons>
      <Row>
        <DistrictContext.Provider value={DC}>
          <UniversalRListTable
            columns={Columns}
            action={'USERS_INDEX'}
            tableName={lastQueryKeyName}
          />
        </DistrictContext.Provider>
      </Row>
    </React.Fragment>

  );
}
