import {useSnackbar} from 'notistack';
import * as R from 'ramda';
import React from 'react';
import {ICardData} from '../../CommonInterfaces/Form';
import {AdminElement} from '../../Components/auth/AdminElement';
import {BackButton} from '../../Components/Buttons/BackButton';
import {DeleteButton} from '../../Components/Buttons/DeleteButton';
import {UpdateButton} from '../../Components/Buttons/UpdateButton';
import Card from '../../Components/Card/Card';
import {CardContainer} from '../../Components/CardContainer/CardContainer';
import {RowOfButtons} from '../../Components/Layout/RowOfButtons';
import PageTitle from '../../Components/PageTitle/PageTitle';
import RedirectTo404 from '../../Components/RedirectTo404';
import {makeDeleteHandler} from '../../Helpers/ApiRequestHelper';
import {IPageInterface} from '../entity/IPageInterface';
import {
  DistrictEntity,
  useGetEntityDetails,
} from '../entity/useGetEntityDetails';
import {DistrictFields} from './DistrictFields';
import DistrictUsersRList from './DistrictUsersRList';

/**
 * District's Read card.
 * @param {string | undefined} id
 * @return {JSX.Element}
 * @constructor
 */
export default function DistrictReadCard({id}: IPageInterface) {
  const empty: ICardData = {};
  const {data, isError} = useGetEntityDetails<ICardData>(DistrictEntity.name,
    id as string,
    empty);
  const {enqueueSnackbar} = useSnackbar();
  const handleDelete = makeDeleteHandler('DISTRICT_DELETE', id as string,
    enqueueSnackbar);

  const titleSuffix = (data && !R.isEmpty(data)) ? `района ${data.name}` : null;

  return (
    <React.Fragment>
      <RedirectTo404 isError={isError}/>

      {!R.isEmpty(data) && data ?
        <PageTitle
          title={`Район ${data.name}`}/> :
        null}

      <RowOfButtons>
        <BackButton/>
        <AdminElement>
          <DeleteButton handleDelete={handleDelete}/>
          <UpdateButton entity={'district'} id={id as string}/>
        </AdminElement>
      </RowOfButtons>

      {
        data ?
          <CardContainer>
            <Card data={data} fields={DistrictFields}/>
          </CardContainer> : ''
      }
      {id && titleSuffix ?
        <DistrictUsersRList parentId={id} titleSuffix={titleSuffix}/> :
        null}


    </React.Fragment>
  );
}
