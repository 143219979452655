import {Typography} from '@material-ui/core';
import React, {useEffect} from 'react';
import Row from '../../../Components/Layout/Row';
import {saveDecisionWrapper, useSelectedDecision} from './helpers';
import {ISchoolConflictResolveCard} from './interfaces';
import TeacherSchoolChangedReadList from './TeacherSchoolChangedReadList';

const getTeacherData = (data: any, key: number) => {
  const firstName = data?.data?.teachers?.[key]?.first_name;
  const secondName = data?.data?.teachers?.[key]?.second_name;
  const lastName = data?.data?.teachers?.[key]?.last_name;
  return {lastName, firstName, secondName};
};

export const TeacherSchoolConflictResolveCard = ({
  data,
  id,
  type,
  decision,
  saveDecision,
}: ISchoolConflictResolveCard) => {
  const teacherKey = ([2, 5].indexOf(type as unknown as number) !== -1) ? 0 : 1;
  const {secondName, firstName, lastName} = getTeacherData(data,
    teacherKey);
  const [selected, setSelected] = useSelectedDecision({def: decision.payload});

  useEffect(() => {
    saveDecisionWrapper({decision, saveDecision, selected});
  }, [selected]);

  const schoolChangedTemplate = `Учитель (${lastName} ${firstName} ${secondName}) сменил образовательное учреждение?`;
  const typoTemplate = `Учитель (${lastName} ${firstName} ${secondName}) дублирует другого из-за опечатки?`;
  const head = (type as unknown as number === 1) ?
    schoolChangedTemplate :
    typoTemplate;

  return (
    <React.Fragment>
      <Row>
        <Typography variant={'h5'}>
          {head}
        </Typography>
      </Row>
      <Row>
        <Typography variant={'h6'}>
          {`Выберите существующего учителя или создание нового`}
        </Typography>
      </Row>
      <TeacherSchoolChangedReadList
        selected={selected}
        setSelected={setSelected}
        actionUri={'CONFLICT_RESOLVE_OPTIONS'}
        actionIds={[id, type as unknown as string]}/>
    </React.Fragment>
  );
};
