import {configureStore} from '@reduxjs/toolkit';
import {exportQueryReducer} from './exportQuery';
import {districtFilterReducer} from './filterDistrict';
import {lastFiltersReducer} from './lastFilters';
import {lastOrdersReducer} from './lastOrder';
import {lastPageReducer} from './lastPage';
import {lastRequestReducer} from './lastRequest';

export const store = configureStore({
  reducer: {
    lastRequest: lastRequestReducer,
    exportQuery: exportQueryReducer,
    districtFilter: districtFilterReducer,
    lastFilters: lastFiltersReducer,
    lastOrders: lastOrdersReducer,
    lastPage: lastPageReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

