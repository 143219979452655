import * as R from 'ramda';
import React from 'react';
import {ICardData} from '../../CommonInterfaces/Form';
import {BackButton} from '../../Components/Buttons/BackButton';
import Card from '../../Components/Card/Card';
import {CardContainer} from '../../Components/CardContainer/CardContainer';
import {RowOfButtons} from '../../Components/Layout/RowOfButtons';
import PageTitle from '../../Components/PageTitle/PageTitle';
import RedirectTo404 from '../../Components/RedirectTo404';
import {IPageInterface} from '../entity/IPageInterface';
import {
  TeacherEntity,
  useGetEntityDetails,
} from '../entity/useGetEntityDetails';
import {TeacherReadFields} from './TeacherReadFields';
import TeacherStudentsRList from './TeacherStudentsRList';

/**
 * Teacher's R-card.
 * @param {string | undefined} id
 * @return {JSX.Element}
 * @constructor
 */
export default function TeacherRCard({id}: IPageInterface) {
  const empty: ICardData = {};
  const {data, isError} = useGetEntityDetails<ICardData>(TeacherEntity.name,
    id as string,
    empty);
  const titleSuffix = (data && !R.isEmpty(data)) ?
    `наставника ${data.first_name} ${data.last_name}` :
    null;

  return (
    <React.Fragment>
      <RedirectTo404 isError={isError}/>

      {!R.isEmpty(data) && data ?
        <PageTitle
          title={`Наставник ${data.first_name} ${data.last_name}`}/> :
        null}

      <RowOfButtons>
        <BackButton/>
      </RowOfButtons>

      {
        data && !R.isEmpty(data) ?
          <CardContainer>
            <Card data={data} fields={TeacherReadFields}/>
          </CardContainer> : ''
      }
      {id && titleSuffix ?
        <TeacherStudentsRList teacherId={id} titleSuffix={titleSuffix}/> :
        null}


    </React.Fragment>
  );
}
