import {Button, Grid} from '@material-ui/core';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import {Link} from '@reach/router';
import {useSnackbar} from 'notistack';
import * as R from 'ramda';
import React from 'react';
import ResultsChildReadList from '../../ChildList/ResultsChildReadList';
import {ICardData} from '../../CommonInterfaces/Form';
import {AdminElement} from '../../Components/auth/AdminElement';
import {BackButton} from '../../Components/Buttons/BackButton';
import {DeleteButton} from '../../Components/Buttons/DeleteButton';
import {UpdateButton} from '../../Components/Buttons/UpdateButton';
import Card from '../../Components/Card/Card';
import {CardContainer} from '../../Components/CardContainer/CardContainer';
import {RowOfButtons} from '../../Components/Layout/RowOfButtons';
import PageTitle from '../../Components/PageTitle/PageTitle';
import RedirectTo404 from '../../Components/RedirectTo404';
import {makeDeleteHandler} from '../../Helpers/ApiRequestHelper';
import {IPageInterface} from '../entity/IPageInterface';
import {EventEntity, useGetEntityDetails} from '../entity/useGetEntityDetails';
import {EventReadFields} from './fields';

/**
 * Event's read card.
 * @param {string | undefined} id
 * @returns {JSX.Element}
 * @constructor
 */
export default function EventReadCard({id}: IPageInterface) {
  const empty: ICardData = {};
  const {data, isError} = useGetEntityDetails<ICardData>(EventEntity.name,
    id as string,
    empty);
  const {enqueueSnackbar} = useSnackbar();
  const handleDelete = makeDeleteHandler('EVENT_DELETE', id as string,
    enqueueSnackbar);

  return (
    <React.Fragment>
      <RedirectTo404 isError={isError}/>

      {!R.isEmpty(data) && data ?
        <PageTitle
          title={`Мероприятие ${data.name_short}`}/> :
        null}

      <RowOfButtons>
        <BackButton/>

        <AdminElement>
          <DeleteButton handleDelete={handleDelete}/>

          <Grid item>
            <Button
              component={Link}
              to={`/event/${id}/create-like`}
              variant={'outlined'}
              color={'primary'}
              startIcon={<AddBoxOutlinedIcon/>}>
              Создать на основе
            </Button>
          </Grid>
          <UpdateButton entity={'event'} id={id as string}
            variant={'contained'}/>
        </AdminElement>
      </RowOfButtons>

      {
        data ?
          <CardContainer>
            <Card data={data} fields={EventReadFields}/>
          </CardContainer> : ''
      }

      {id ?
        <AdminElement>
          <ResultsChildReadList
            titleSuffix={'мероприятия'}
            parentId={id}
            parentName={'event_id'}
            withDelete={true}
            aternateCreate={true}
          />
        </AdminElement> :
        null}

    </React.Fragment>
  );
}
