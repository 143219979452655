import {Button, Typography} from '@material-ui/core';
import {navigate} from '@reach/router';
import {unflatten} from 'flat';
import {useFormik} from 'formik';
import {FormikErrors} from 'formik/dist/types';
import {stringify} from 'query-string';
import * as R from 'ramda';
import React, {useState} from 'react';
import {ICreateUpdateFormField} from '../../CommonInterfaces/Form';
import {
  IPreprocessPromiseGenerator,
  IPromiseGenerator,
  IResponseHandler,
} from '../../CommonInterfaces/IPromiseGenerator';
import {apiService} from '../../Helpers/apiService';
import {uriGenerator} from '../../Helpers/UriGenerator';
import UniversalInput from './Inputs/UniversalInput/UniversalInput';

interface FormProps<EntityData> {
  action: string,
  action_alternative?: string,
  action_alternative_title?: string,
  data: EntityData,
  id?: string,
  snackBar: any,
  fields: ICreateUpdateFormField[],
  beforeSubmit?: IPromiseGenerator,
  preProcessData?: IPreprocessPromiseGenerator<EntityData>,
  onSuccess?: IResponseHandler,
  submitTitle?: string,
  onChange?: any,
  afterSuccess?: any,
  isGet?: boolean,
}

const processNotFormErrors = (errors: any, snackBar: any) => {
  if (!R.isEmpty(errors)) {
    const notFormErrors: string[] = [];

    if (typeof errors === 'string') {
      notFormErrors.push(errors);
    } else if (Array.isArray(errors)) {
      errors.forEach((el: string, index: unknown) => {
        Number.isInteger(index);
        notFormErrors.push(el);
      });
    }

    if (notFormErrors.length > 0) {
      snackBar(notFormErrors, {
        variant: 'error',
      });
    }
  }
};

const processSuccess = (data: any, snackBar: any) => {
  if (data.data.messages) {
    if (data.data.messages.length > 0) {
      snackBar(data.data.messages, {
        variant: 'success',
      });
    }
  }
};

/**
 * Create and update form generator.
 *
 * @param {string} action
 * @param action_alternative
 * @param action_alternative_title
 * @param {EntityData} data
 * @param {string | undefined} id
 * @param {any} snackBar
 * @param {ICreateUpdateFormField[]} fields
 * @param beforeSubmit
 * @param preProcessData
 * @param onSuccess
 * @param submitTitle
 * @param onChange
 * @param afterSuccess
 * @param isGet
 * @return {JSX.Element}
 * @constructor
 */
export function CreateAndUpdateForm<EntityData>({
  action,
  action_alternative,
  action_alternative_title,
  data,
  id,
  snackBar,
  fields,
  beforeSubmit,
  preProcessData,
  onSuccess,
  submitTitle,
  onChange,
  afterSuccess,
  isGet = false,
}: FormProps<EntityData>) {
  const [actionNumber, setActionNumber] = useState<number>(1);

  const submitProcessing = (values: EntityData) => {
    if (preProcessData && values) {
      values = preProcessData(values);
    }
    const activeAction = (action_alternative && actionNumber === 2) ?
      action_alternative :
      action;
    const {api, redirect, success_message} = uriGenerator(
      {action: activeAction, id});
    const successHandler = (response: any) => {
      if (onSuccess) {
        onSuccess(response);
      }
      processSuccess(response, snackBar);
      const afterNavigate = () => {
        if (success_message !== '') {
          snackBar(success_message, {
            variant: 'success',
          });
        }
      };
      if (redirect) {
        navigate(redirect).then(afterNavigate);
      } else {
        afterNavigate();
      }
      if (afterSuccess) {
        afterSuccess(formik);
      }
    };
    const errorHandler = (response: any) => {
      const errors = response.response.data.errors;
      processNotFormErrors(errors, snackBar);
      if (!R.isEmpty(errors) && typeof errors !== 'string') {
        const nested = unflatten(errors);
        // @ts-ignore
        formik.setTouched(nested, false);
        formik.setErrors(nested as FormikErrors<any>);
      }
    };

    if (isGet) {
      window.open(api + '?' + stringify(values));
    } else {
      apiService.post(api, values).then(successHandler).catch(errorHandler);
    }
  };

  const submitHandler = (values: EntityData) => {
    if (beforeSubmit) {
      beforeSubmit().then(() => {
        submitProcessing(values);
      });
    } else {
      submitProcessing(values);
    }
  };

  const formik = useFormik<EntityData>({
    initialValues: data,
    validateOnChange: false,
    onSubmit: submitHandler,
  });

  const formElements = fields.map((el) =>
    <UniversalInput
      key={el.name}
      formik={formik}
      name={el.name}
      title={el.title}
      type={el.type}
      onChange={onChange}
      child={el.child}
      parent={el.parent}
      nullable={el.nullable}
      disabled={el.disabled}
      minimum_access={el.minimum_access}
    />,
  );
  let buttonTitle;

  if (submitTitle) {
    buttonTitle = submitTitle;
  } else {
    if (id) {
      buttonTitle = 'Сохранить';
    } else {
      buttonTitle = 'Создать';
    }
  }

  const handleDefaultButton = () => {
    setActionNumber(1);
  };
  const handleAlternateButton = () => {
    setActionNumber(2);
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        {formElements}
        <Button onClick={handleDefaultButton} color="primary"
          variant="contained" fullWidth type="submit">
          {buttonTitle}
        </Button>
        {action_alternative ?
          <React.Fragment>
            <Typography
              color={'textSecondary'}
              variant="overline" display="block"
              align={'center'}
            >
              Или
            </Typography>
            <Button onClick={handleAlternateButton} color="secondary"
              variant="contained" fullWidth type="submit">
              {action_alternative_title}
            </Button>
          </React.Fragment> : null}
      </form>
    </div>
  );
};

