import {navigate} from '@reach/router';
import {useSnackbar} from 'notistack';
import React, {useState} from 'react';
import {sprintf} from 'sprintf-js';
import {ResolveConflictCardURITemplate} from '../../../App';
import {ICardData} from '../../../CommonInterfaces/Form';
import {RenderIfLoaded} from '../../../Components/RenderIfLoaded';
import {LMessages} from '../../../Localization/Localization';
import {IPageInterface} from '../../entity/IPageInterface';
import {
  ConflictEntity,
  useGetEntityDetails,
} from '../../entity/useGetEntityDetails';
import {ResolveConflictCardContent} from './ResolveConflictCardContent';

export const makeResolveConflictURI = (id: number) => {
  return sprintf(ResolveConflictCardURITemplate, id);
};

export interface Decision {
  decision: number,
  conflict: number,
  payload: number
}

export interface IDecisionSaverCallback {
  (el: Decision): void;
}

export interface ConflictDecisions {
  resolve: Decision[]
}

export const ResolveConflictCard = ({id}: IPageInterface) => {
  const empty: ICardData = {};
  const [counter, setCounter] = useState<number>(1);
  const {data, setData, code} = useGetEntityDetails<any>(
    ConflictEntity.name,
    id as string,
    empty, undefined, [counter]);

  const conflicts = data?.conflicts;

  const emptyDecisions = {resolve: []};
  const [decisions, setDecisions] = useState<ConflictDecisions>(emptyDecisions);
  const reload = () => {
    setCounter((p => p + 1));
    setData(empty);
    setDecisions(emptyDecisions);
  };
  const {enqueueSnackbar} = useSnackbar();

  if (code === 404) {
    navigate(`/result-upload/${id}`).then(() => {
      enqueueSnackbar(LMessages.ALL_CONFLICTS_RESOLVED, {
        variant: 'info',
      });
    });
  }

  return (
    <React.Fragment>
      {id ? <RenderIfLoaded data={data}>
        <ResolveConflictCardContent
          key={counter}
          reload={reload}
          decisions={decisions}
          setDecisions={setDecisions}
          id={id}
          conflicts={conflicts}
          data={data}/>
      </RenderIfLoaded> : null}
    </React.Fragment>
  );
};


