import {useSnackbar} from 'notistack';
import * as R from 'ramda';
import React from 'react';
import {ICardData} from '../../CommonInterfaces/Form';
import {BackButton} from '../../Components/Buttons/BackButton';
import {DeleteButton} from '../../Components/Buttons/DeleteButton';
import {UpdateButton} from '../../Components/Buttons/UpdateButton';
import Card from '../../Components/Card/Card';
import {CardContainer} from '../../Components/CardContainer/CardContainer';
import {RowOfButtons} from '../../Components/Layout/RowOfButtons';
import PageTitle from '../../Components/PageTitle/PageTitle';
import RedirectTo404 from '../../Components/RedirectTo404';
import {makeDeleteHandler} from '../../Helpers/ApiRequestHelper';
import {IPageInterface} from '../entity/IPageInterface';
import {
  ResultEntity,
  useGetEntityDetails,
} from '../entity/useGetEntityDetails';
import {ResultReadFields} from './fields';

/**
 * Result's read card.
 * @param {string | undefined} id
 * @returns {JSX.Element}
 * @constructor
 */
export default function ResultReadCard({id}: IPageInterface) {
  const empty: ICardData = {};
  const {data, isError} = useGetEntityDetails<any>(ResultEntity.name,
    id as string,
    empty);
  const {enqueueSnackbar} = useSnackbar();
  const handleDelete = makeDeleteHandler('RESULT_DELETE', id as string,
    enqueueSnackbar);

  return (
    <React.Fragment>
      <RedirectTo404 isError={isError}/>

      {!R.isEmpty(data) && data ?
        <PageTitle
          title={`Результат #${data.result.id}`}/> :
        null}

      <RowOfButtons>
        <BackButton/>
        <DeleteButton handleDelete={handleDelete}/>


        <UpdateButton entity={ResultEntity.name} id={id as string}
          variant={'contained'}/>

      </RowOfButtons>

      {
        data ?
          <CardContainer>
            <Card data={data} fields={ResultReadFields}/>
          </CardContainer> : ''
      }

    </React.Fragment>
  );
}
