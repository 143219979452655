import {Link, RouteComponentProps} from '@reach/router';
import {Column} from 'material-table';
import React from 'react';
import {CreateButton} from '../../Components/Buttons/CreateButton';
import {ExportButton} from '../../Components/Buttons/ExportButton';
import Row from '../../Components/Layout/Row';
import {RowOfButtons} from '../../Components/Layout/RowOfButtons';
import PageTitle from '../../Components/PageTitle/PageTitle';
import {TeachersRowRenderer} from '../../Components/TeachersRowRenderer';
import {buildUniversalEntityFilter} from '../../Components/UniversalRListTable/Filters/buildUniversalEntityFilter';
import {DistrictFilter} from '../../Components/UniversalRListTable/Filters/DistrictFilter';
import {SchoolFilter} from '../../Components/UniversalRListTable/Filters/SchoolFilter';
import {
  PlaceTypeFilter,
  PlaceTypeLookup,
} from '../../Components/UniversalRListTable/Filters/StaticFilters';
import {DistrictContext} from '../../Components/UniversalRListTable/Helpers/DistrictContext';
import {useDistrictState} from '../../Components/UniversalRListTable/Helpers/useDistrictState';
import {UniversalRListTable} from '../../Components/UniversalRListTable/UniversalRListTable';

/**
 * Result's read list.
 * @param {RouteComponentProps} props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ResultsReadList(props: RouteComponentProps) {
  const DC = useDistrictState();

  const apiUriPrefix = '/result/';

  const eventFilter = buildUniversalEntityFilter('EVENT_LIST', 3);
  const subjectFilter = buildUniversalEntityFilter('SUBJECTS_LIST');

  const Columns: Column<any>[] =
    [
      {
        title: '#',
        field: 'id',
        filtering: false,
        render: (rowData): React.ReactNode =>
          <Link to={`${apiUriPrefix}${rowData.id}`}>{rowData.id}</Link>,
      },
      {
        title: 'Параллель',
        field: 'event_class',
      },
      {
        title: 'Класс',
        field: 'student_class',
      },
      {
        title: 'Балл',
        field: 'points',
        filtering: false,
      },
      {
        title: 'Место',
        field: 'place',
      },
      {
        title: 'Диплом',
        field: 'place_type',
        filterComponent: PlaceTypeFilter,
        defaultFilter: 0,
        lookup: PlaceTypeLookup,
      },
      {
        title: 'Команда',
        field: 'command_name',
      },
      {
        title: 'Наставники',
        field: 'teacher',
        sorting: false,
        filtering: false,
        render: TeachersRowRenderer,
      },

      {
        title: 'Ученик',
        field: 'student.name',
        render: (rowData): React.ReactNode =>
          <Link
            to={`/student/${rowData.student.id}`}>
            {rowData.student.name}
          </Link>,
      },

      {
        title: 'Пользователь',
        field: 'user.name',
        render: (rowData): React.ReactNode =>
          <Link
            to={`/user/${rowData.user.id}`}>
            {rowData.user.name}
          </Link>,
      },
      {
        title: 'Мероприятие',
        field: 'event.name',
        filterComponent: eventFilter,
        defaultFilter: 0,
        render: (rowData): React.ReactNode =>
          <Link
            to={`/event/${rowData.event.id}`}>
            {rowData.event.name}
          </Link>,
      },
      {
        title: 'Предмет',
        field: 'subject.name',
        filterComponent: subjectFilter,
        defaultFilter: 0,
      },
      {
        title: 'Загрузка',
        field: 'upload.name',
        filtering: false,
        sorting: false,
      },

      {
        title: 'Район',
        field: 'district.name',
        defaultFilter: DC.district,
        filterComponent: DistrictFilter,
        render: (rowData): React.ReactNode =>
          <Link
            to={`/district/${rowData.district.id}`}>
            {rowData.district.name}
          </Link>,
      },
      {
        title: 'Организация',
        field: 'school.name',
        filterComponent: SchoolFilter,
        render: (rowData): React.ReactNode =>
          <Link
            to={`/school/${rowData.school.id}`}>
            {rowData.school.name}
          </Link>,
      },
      {
        title: 'Изменён',
        field: 'updated_at',
        filtering: false,
      },
      {
        title: 'Создан',
        field: 'created_at',
        filtering: false,
      },
    ];

  const exceptions = [
    'student.name',
    'user.name',
    'teacher.name',
  ];

  const lastQueryKeyName = 'resultsReadList';

  return (
    <React.Fragment>
      <PageTitle title={'Результаты'}/>
      <RowOfButtons>
        <ExportButton name={lastQueryKeyName}/>
        <CreateButton entity={'result'}/>
      </RowOfButtons>

      <Row>
        <DistrictContext.Provider value={DC}>
          <UniversalRListTable
            searchExceptions={exceptions}
            columns={Columns}
            action={'RESULTS_INDEX'}
            tableName={lastQueryKeyName}
          />
        </DistrictContext.Provider>
      </Row>

    </React.Fragment>
  );
}
