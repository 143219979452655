import produce from 'immer';
import React, {useState} from 'react';
import {CardContainer} from '../../../Components/CardContainer/CardContainer';
import {FormHeading} from '../../../Components/Typography/FormHeading';
import {
  AllowedSchoolsCard,
  emptyASCContainer,
  IASCContainer,
} from '../AllowedSchoolsCard';

/**
 * Get data and hook required for AllowedSchoolsElement.
 * @returns {(IASCContainer | ((data: IASCContainer) => void))[]}
 */
export function useAllowed() {
  const [allowedBy, setAllowedBy] = useState<IASCContainer>(emptyASCContainer);
  const onDataChange = (data: IASCContainer) => {
    const result = produce(allowedBy, (draft) => {
      Object.assign(draft, data);
    });
    setAllowedBy(result);
  };
  return [allowedBy, onDataChange];
}

interface IAllowedSchoolsElement {
  allowedBy: IASCContainer
}

/**
 * Event's Paper with allowed school list for create and update cards.
 * @param {IASCContainer} allowedBy
 * @returns {JSX.Element}
 * @constructor
 */
export const AllowedSchoolsElement = ({allowedBy}: IAllowedSchoolsElement) => {
  return (
    <CardContainer outlined={true}>
      <React.Fragment>
        <FormHeading
          title={'Организации с правом на редактирование результатов (после сохранения)'}/>
        <AllowedSchoolsCard allowedBy={allowedBy}/>
      </React.Fragment>
    </CardContainer>
  );
};
