import {useSnackbar} from 'notistack';
import React from 'react';
import {ICreateUpdateFormField} from '../../CommonInterfaces/Form';
import {CardContainer} from '../../Components/CardContainer/CardContainer';
import {CreateAndUpdateForm} from '../../Components/FormGenerators/CreateAndUpdateForm';
import PageTitle from '../../Components/PageTitle/PageTitle';
import {IPageInterface} from '../entity/IPageInterface';

interface IUniversalReportPage<T> {
  title: string,
  emptyEntity: T,
  fields: ICreateUpdateFormField[],
  action: string,
}

export function UniversalReportPage<T>({
  title,
  emptyEntity,
  fields,
  action,
}: IPageInterface & IUniversalReportPage<T>) {
  const {enqueueSnackbar} = useSnackbar();

  return (
    <React.Fragment>

      <PageTitle title={title}/>

      <CardContainer>
        <CreateAndUpdateForm<T>
          action={action}
          fields={fields}
          data={emptyEntity}
          snackBar={enqueueSnackbar}
          isGet={true}
        />
      </CardContainer>
    </React.Fragment>
  );
};
