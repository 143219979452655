import {RouteComponentProps} from '@reach/router';
import {useSnackbar} from 'notistack';
import React from 'react';
import {CardContainer} from '../../Components/CardContainer/CardContainer';
import {CreateAndUpdateForm} from '../../Components/FormGenerators/CreateAndUpdateForm';
import {
  createEmptyReset,
  IReset,
} from '../../Components/FormGenerators/ILoginCredentials';
import PageTitle from '../../Components/PageTitle/PageTitle';
import {LMessages} from '../../Localization/Localization';
import {IResetPasswordPageInterface} from '../entity/IPageInterface';
import {ResetPasswordFields} from './fields';
import {getCSRFCookie} from './getCSRFCookie';

export default function ResetPasswordCard({
  token,
  email,
}: RouteComponentProps & IResetPasswordPageInterface) {
  const emptyReset = createEmptyReset(token as string, email as string);
  const {enqueueSnackbar} = useSnackbar();

  return (
    <React.Fragment>
      <PageTitle title={LMessages.RESET_PASSWORD_TITLE}/>
      <CardContainer>
        <CreateAndUpdateForm<IReset>
          action={'RESET_PASSWORD'}
          fields={ResetPasswordFields}
          beforeSubmit={getCSRFCookie}
          data={emptyReset}
          snackBar={enqueueSnackbar}
          submitTitle={'Сменить пароль'}
        />
      </CardContainer>
    </React.Fragment>
  );
}

