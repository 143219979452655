import {Button, Typography} from '@material-ui/core';
import {navigate} from '@reach/router';
import {useSnackbar} from 'notistack';
import React, {useContext} from 'react';
import {UserContext} from '../../Components/auth/UserContext';
import {CardContainer} from '../../Components/CardContainer/CardContainer';
import {CreateAndUpdateForm} from '../../Components/FormGenerators/CreateAndUpdateForm';
import {
  createEmptyLoginCredentials,
  ILoginCredentials,
} from '../../Components/FormGenerators/ILoginCredentials';
import {LoginCredentialFields} from './fields';
import {getCSRFCookie} from './getCSRFCookie';

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const LoginForm = (props: any) => {
  const {setCurrentUser} = useContext(UserContext);

  const emptyLogin = createEmptyLoginCredentials();
  const {enqueueSnackbar} = useSnackbar();
  const onSuccessHandler = (e: any) => {
    setCurrentUser({authorized: true});
  };

  const handleAlternateButton = () => {
    navigate('/forgot-password');
  };

  return (
    <CardContainer>
      <React.Fragment>
        <CreateAndUpdateForm<ILoginCredentials>
          action={'LOGIN'}
          fields={LoginCredentialFields}
          beforeSubmit={getCSRFCookie}
          onSuccess={onSuccessHandler}
          data={emptyLogin}
          snackBar={enqueueSnackbar}
          submitTitle={'Войти'}
        />
        <Typography
          color={'textSecondary'}
          variant="overline" display="block"
          align={'center'}
        >
          Или
        </Typography>
        <Button onClick={handleAlternateButton} color="secondary"
          variant="contained" fullWidth type="submit">
          {'Забыли пароль?'}
        </Button>
      </React.Fragment>
    </CardContainer>
  );
};
