import {
  emptyIdNameEntity,
  IdNameEntity,
  IIdNameEntityContainer,
} from '../../CommonInterfaces/IIdNameObject';

export interface IEditableEvent {
  [index: string]: string | ISubjectClassContainer | IIdNameEntityContainer | Date | null,

  'name_short': string,
  'name_full': string,
  'start': Date | null,
  'end': Date | null,
  'description': string,
  'points': string,
  'is_open': string,

  'domain': string,
  'level': string,
  'type': string,
  'championship': string,
  'form': string,
  'status': string,
  'academic_year': string,
  'school': string,
  'subject_class': ISubjectClassContainer,
  'allowed_schools': IIdNameEntityContainer,
  'allowed_school_tags': IIdNameEntityContainer,
  'allowed_district_tags': IIdNameEntityContainer,
}

export interface IFieldsThatShouldBeEmpty {
  [index: string]: string | ISubjectClassContainer | IIdNameEntityContainer | Date | null,

  'start': Date | null,
  'end': Date | null,
  'school': string,
}

export interface ISubjectClassContainer {
  [key: number]: ISubjectClass
}

export interface ISubjectClass {
  'subject': IdNameEntity,
  'class': string
}

export const createEmptyEditableEvent = (): IEditableEvent => {
  return {
    'name_short': '',
    'name_full': '',
    'start': null,
    'end': null,
    'description': '',
    'points': '',
    'is_open': '2',

    'domain': '',
    'level': '',
    'type': '',
    'championship': '',
    'form': '',
    'status': '',
    'academic_year': '',
    'school': '',
    'subject_class': {
      0: {
        subject: {
          id: '',
          name: '',
        },
        class: '',
      },
    },
    'allowed_schools': {0: emptyIdNameEntity},
    'allowed_school_tags': {0: emptyIdNameEntity},
    'allowed_district_tags': {0: emptyIdNameEntity},
  };
};

export const fieldsThatShouldBeEmpty: IFieldsThatShouldBeEmpty = {
  'start': null,
  'end': null,
  'school': '',
};

export const emptySubjectClass: ISubjectClass = {
  subject: {
    id: '',
    name: '',
  },
  class: '',
};

