import {FormControlLabel, Radio, RadioGroup} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {Link, RouteComponentProps} from '@reach/router';
import {Column} from 'material-table';
import React from 'react';
import {IRowData} from '../../../CommonInterfaces/IRowData';
import Row from '../../../Components/Layout/Row';
import {UniversalRListTable} from '../../../Components/UniversalRListTable/UniversalRListTable';
import {IResolveCard} from './interfaces';
import {timestampToDate} from '../../../Localization/DateFormatter';

/**
 * R-list of Users .
 * @param {RouteComponentProps} props
 * @constructor
 */
export default function StudentSchoolChangedReadList({
  actionUri,
  actionIds,
  selected,
  setSelected,
}: IResolveCard & RouteComponentProps) {
  const apiUriPrefix = '/student/';
  const yesNoLookup = {
    1: 'Нет',
    2: 'Да',
  };
  const Columns: Column<IRowData>[] =
    [
      {
        title: '#',
        field: 'id',
      },
      {
        title: 'Фамилия',
        field: 'last_name',
        render: (rowData): React.ReactNode =>
          <Link to={`${apiUriPrefix}${rowData.id}`}>{rowData.last_name}</Link>,
      },
      {
        title: 'Имя',
        field: 'first_name',
        render: (rowData): React.ReactNode =>
          <Link to={`${apiUriPrefix}${rowData.id}`}>{rowData.first_name}</Link>,
      },
      {
        title: 'Отчество',
        field: 'second_name',
        render: (rowData): React.ReactNode =>
          <Link
            to={`${apiUriPrefix}${rowData.id}`}>{rowData.second_name}</Link>,
      },
      {
        title: 'Район',
        field: 'district.name',
      },
      {
        title: 'Организация',
        field: 'school.name',
      },
      {
        title: 'Пол',
        field: 'gender',
        lookup: {
          2: 'Ж',
          1: 'М',
        },
      },
      {
        title: 'Гражданин',
        field: 'is_citizen',
        lookup: yesNoLookup,
      },
      {
        title: 'ОВЗ',
        field: 'has_disability',
        lookup: yesNoLookup,
      },
      {
        title: 'Дата рождения',
        field: 'birthday',
      },
    ];
  const lastQueryKeyName = 'StudentSchoolChangedResolveCard';

  const tableActions = [
    {
      icon: () => <DeleteOutlineIcon/>,
      onClick: () => {
      },
    },
  ];

  const component = {
    Action: (props: any) => (
      <Radio
        onClick={(event) => {
          setSelected(props.data.id);
          return props.action.onClick(event, props.data);
        }}
        checked={selected == props.data.id}
      />
    ),
  };

  const dataPreprocessor=(rawData:any)=>{
      rawData.forEach((val:any,index:any)=>{
          rawData[index].birthday=timestampToDate(rawData[index]?.birthday)
      })
      return rawData
    }

  return (
    <React.Fragment>
      <Row>
        <UniversalRListTable
          components={component}
          tableActions={tableActions}
          filtering={false}
          sorting={false}
          columns={Columns}
          actionUri={actionUri}
          actionIds={actionIds}
          tableName={lastQueryKeyName}
          dataPreprocessor={dataPreprocessor}
        />
      </Row>
      <Row>
        <RadioGroup value={selected}>
          <FormControlLabel control={<Radio
            onClick={(event) => {
              setSelected(-1);
            }}
            checked={selected == -1}
          />} label="Создать нового ученика"/>
        </RadioGroup>
      </Row>
    </React.Fragment>
  );
}
