import {useSnackbar} from 'notistack';
import * as R from 'ramda';
import React from 'react';
import {ICardData} from '../../CommonInterfaces/Form';
import {BackButton} from '../../Components/Buttons/BackButton';
import {CardContainer} from '../../Components/CardContainer/CardContainer';
import {CreateAndUpdateForm} from '../../Components/FormGenerators/CreateAndUpdateForm';
import {IEditableDistrict} from '../../Components/FormGenerators/IEditableDistrict';
import {
  entityTagsArrayToObject,
  removeLastEmptyEntityTag,
} from '../../Components/FormGenerators/Inputs/SubjectClassField/EntityTag';
import {RowOfButtons} from '../../Components/Layout/RowOfButtons';
import PageTitle from '../../Components/PageTitle/PageTitle';
import RedirectTo404 from '../../Components/RedirectTo404';
import {LMessages} from '../../Localization/Localization';
import {IPageInterface} from '../entity/IPageInterface';
import {
  DistrictEntity,
  useGetEntityDetails,
} from '../entity/useGetEntityDetails';
import {DistrictUpdateFields} from './DistrictFields';

/**
 * District's update card.
 * @param {string | undefined} id
 * @return {JSX.Element}
 * @constructor
 */
export default function DistrictUpdateCard({id}: IPageInterface) {
  const emptyEntity: ICardData = {};
  const {data, isError} = useGetEntityDetails<IEditableDistrict>(
    DistrictEntity.name,
    id as string,
    emptyEntity as unknown as IEditableDistrict,
    entityTagsArrayToObject);

  const {enqueueSnackbar} = useSnackbar();

  return (
    <React.Fragment>
      <RedirectTo404 isError={isError}/>

      {data && !R.isEmpty(data) ? <PageTitle
        title={`${LMessages.DISTRICT_U_CARD_TITLE} ${data.name}`}
      /> : null}

      <RowOfButtons>
        <BackButton/>
      </RowOfButtons>

      {
        !R.isEmpty(data) ?
          <CardContainer>
            <CreateAndUpdateForm<IEditableDistrict>
              action={'DISTRICT_UPDATE'}
              id={id}
              fields={DistrictUpdateFields}
              data={data as unknown as IEditableDistrict}
              snackBar={enqueueSnackbar}
              preProcessData={removeLastEmptyEntityTag}
            />
          </CardContainer> :
          null
      }

    </React.Fragment>
  );
}
