import {Link} from '@material-ui/core';
import React from 'react';
import {Caption} from './Elements/Caption';
import {LineBody} from './Elements/LineBody';
import {ISimpleLine} from './Interfaces';

/**
 * Read-Card line for file downloading.
 * @param {string} title
 * @param {string} content
 * @returns {JSX.Element}
 * @constructor
 */
export function DownloadFileLine({
  title,
  content,
}: ISimpleLine) {
  return (
    <React.Fragment>
      <Caption value={title}/>

      <LineBody>
        <Link target='_blank' href={content}>
          Скачать
        </Link>
      </LineBody>
    </React.Fragment>
  );
}
