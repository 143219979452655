export interface IIdNameObject {
  [key: string]: string
}

export interface IdNameEntity {
  id: number | '',
  name: string
}

export interface IdEntity {
  id: number | '',
}

export const emptyIdEntity: IdEntity = {
  id: '',
};

export interface IIdNameEntityContainer {
  [key: number]: IdNameEntity
}

export interface SubjectClass {
  class: string,
  subject: IdNameEntity
}

export interface ValueTitleObject {
  value: string | number,
  title: string
}

export interface IBoolIndexedByString {
  [key: string]: boolean
}
export interface IStringIndexedByNumber {
  [key: number]: string
}

export const emptyIdNameEntity: IdNameEntity = {
  id: '',
  name: '',
};
