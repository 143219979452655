import {AppBar, Toolbar} from '@material-ui/core';
import React, {useContext} from 'react';
import {UserContext} from '../auth/UserContext';
import {DropDownMenu} from '../Menu/DropDownMenu/DropDownMenu';
import IMenuButtonLink from '../Menu/IMenuButtonLink';
import MenuButton from '../Menu/MenuButton/MenuButton';
import MenuContainer from '../Menu/MenuContainer/MenuContainer';

/**
 * Top navigation bar.
 * @constructor
 */
export default function NavigationBar() {
  const reportElements: IMenuButtonLink[] = [
    {
      title: 'Основные показатели',
      link: 'report/main-indicators',
    },
    {
      title: 'Отчет по предметам',
      link: 'report/subjects',
    },
    {
      title: 'Полный отчет по участникам',
      link: 'report/full-students',
    },
    {
      title: 'Список участников',
      link: 'report/student-results',
    },
    {
      title: 'По наставникам',
      link: 'report/tutors',
    },
    {
      title: 'По организациям',
      link: 'report/organizations',
    },
  ];
  const entityElements: IMenuButtonLink[] = [
    {
      title: 'Пользователи',
      link: 'users/list',
    },
    {
      title: 'Ученики',
      link: 'students/list',
    },
    {
      title: 'Наставники',
      link: 'teachers/list',
    },
    {
      title: 'Организации',
      link: 'schools/list',
    },
    {
      title: 'Районы',
      link: 'districts/list',
    },
    {
      title: 'Результаты',
      link: 'results/list',
    },
  ];
  const {currentUser} = useContext(UserContext);

  return (
    <AppBar position="static">
      <Toolbar>
        <MenuContainer>
          {currentUser.authorized ?
            <React.Fragment>
              <MenuButton title={'Приборная панель'}
                link={'/'}/>
              <MenuButton title={'Мероприятия'}
                link={'events/list'}/>
              <MenuButton title={'Загрузка результатов'}
                link={'/results/import'}/>
              <DropDownMenu elements={reportElements} title={'Отчёты'}/>
              <DropDownMenu elements={entityElements} title={'Справочники'}/>
            </React.Fragment> : null}

        </MenuContainer>

        {currentUser.authorized && currentUser.login ?
          <MenuButton title={currentUser.login}
            link={`/user/${currentUser.id}`}/> :
          ''}
        {currentUser.authorized ?
          <MenuButton title={'Выйти'} link={'/logout'}/> :
          <MenuButton title={'Войти'} link={'/login'}/>
        }

      </Toolbar>
    </AppBar>
  );
}
