import {AxiosError} from 'axios';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {getEntityDetailsHelper} from '../../Helpers/ApiRequestHelper';

/**
 * Fetch data of Entity by ID. Stateful.
 * @param {string} entity
 * @param {number} id
 * @param {R} empty
 * @param dataPreprocessor
 * @param depends
 * @return {R | null} data
 */
export function useGetEntityDetails<R>(
  entity: string, id: string, empty: R, dataPreprocessor?: any,
  depends?: any[]) {
  const [data, setData] = useState<R | null>(empty);
  const [isError, setIsError] = useState(false);
  const [code, setCode] = useState<number | undefined>(undefined);
  const baseDependsOn = [entity, id];
  const dependsOn = (depends) ? [...baseDependsOn, ...depends] : baseDependsOn;

  useEffect(() => {
    const fetchData = async () => {
      const responseHandler = (result: any) => {
        let data = result.data.data;
        if (dataPreprocessor) {
          data = dataPreprocessor(data);
        }
        setData(data);
        setCode(200);
      };
      const errorHandler = (result: AxiosError<any>) => {
        setCode(result?.response?.status);
        setIsError(true);
      };
      getEntityDetailsHelper({
        entity, id, responseHandler, errorHandler,
      });
    };

    fetchData();
  }, dependsOn);
  return {data, isError, setData, setIsError, code};
}

interface IGetEntityDetails<T> {
  entity: string,
  id: string,
  empty: T,
  setData: Dispatch<SetStateAction<T>>,
  setError: Dispatch<SetStateAction<boolean>>,
  dataPreprocessor?: any
}

/**
 * Fetch data of Entity by ID. Stateless.
 * @param {string} entity
 * @param {number} id
 * @param {R} empty
 * @param setError
 * @param dataPreprocessor
 * @param setData
 * @return {R | null} data
 */
export function getEntityDetails<R>({
    entity,
    id,
    empty,
    setData,
    setError,
    dataPreprocessor,
  }: IGetEntityDetails<R>,
) {
  const fetchData = async () => {
    const responseHandler = (result: any) => {
      let data = result.data.data;
      if (dataPreprocessor) {
        data = dataPreprocessor(data);
      }
      setData(data);
    };
    const errorHandler = (result: any) => {
      setError(true);
    };
    getEntityDetailsHelper({
      entity, id, responseHandler, errorHandler,
    });
  };

  fetchData();
  return true;
}

interface IEntity {
  name: string
}

export const UserEntity: IEntity = {
  name: 'user',
};

export const StudentEntity: IEntity = {
  name: 'student',
};

export const TeacherEntity: IEntity = {
  name: 'teacher',
};

export const SchoolEntity: IEntity = {
  name: 'school',
};

export const DistrictEntity: IEntity = {
  name: 'district',
};

export const EventEntity: IEntity = {
  name: 'event',
};

export const ResultEntity: IEntity = {
  name: 'result',
};

export const ResultUploadEntity: IEntity = {
  name: 'result-upload',
};
export const ConflictEntity: IEntity = {
  name: 'conflict',
};
