import {MenuItem, Select} from '@material-ui/core';
import {Column, EditCellColumnDef} from 'material-table';
import React, {useState} from 'react';
import {ValueTitleObject} from '../../../CommonInterfaces/IIdNameObject';
import {IRowData} from '../../../CommonInterfaces/IRowData';

export interface IFilterGenerator {
  options: any,
  columnDef: Column<IRowData>;
  onFilterChanged: (rowId: string, value: any) => void;
}

export const filterGenerator = (
  options: ValueTitleObject[], changeHandler?: any) => {
  return (props: IFilterGenerator): React.ReactElement => {
    const [selectedVal, setSelectedVal] = useState<string | number>(
      props.columnDef.defaultFilter);

    /**
     * Apply filtered value to table.
     * @param {React.ChangeEvent} e
     */
    function handleChange(e: React.ChangeEvent<{ value: unknown }>) {
      const val = e.target.value as string;
      setSelectedVal(val);
      props.onFilterChanged(
        (props.columnDef as EditCellColumnDef).tableData.id.toString(), val);
      if (changeHandler) {
        changeHandler(val);
      }
    }

    return (
      <Select value={selectedVal} onChange={handleChange}>
        {options.map(Option)}
      </Select>
    );
  };
};

const Option = (el: ValueTitleObject) => {
  return <MenuItem key={el.value} value={el.value}>{el.title}</MenuItem>;
};
