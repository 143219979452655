import {Button, Grid} from '@material-ui/core';
import PresentToAllOutlinedIcon from '@material-ui/icons/PresentToAllOutlined';
import React from 'react';
import {IZeroArgumentVoidCallback} from '../../CommonInterfaces/Callbacks';

interface IDeleteButton {
  handleDelete: IZeroArgumentVoidCallback
}

export const DeleteButton = ({handleDelete}: IDeleteButton) => {
  return (
    <Grid item>
      <Button
        onClick={handleDelete}
        variant={'outlined'}
        color={'secondary'}
        startIcon={<PresentToAllOutlinedIcon/>}>
        Удалить
      </Button>
    </Grid>
  );
};
