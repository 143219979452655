import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React from 'react';
import {ITextField} from '../../../../CommonInterfaces/Form';
import {IdNameEntity as IOption} from '../../../../CommonInterfaces/IIdNameObject';
import {ArrayOfSelect} from '../SubjectClassField/ArrayOfSelect';
import {EntityTag} from '../SubjectClassField/EntityTag';
import {SubjectClassField} from '../SubjectClassField/SubjectClassField';
import {UniversalEntitySelectInput} from '../UniversalEntitySelectInput';
import {CURadio} from './Elements/CURadio';
import {CUSelect} from './Elements/CUSelect';
import {
  arrayTypeNameToAction,
  arrayTypeNameToCaption,
  nameToAction,
} from './nameToAction';
import {availableTypes, typeToOptions} from './typeToOptions';

interface IUniversalInputBuilder extends ITextField {
  dotValue: any
  dotErrors: any
  dotTouched: any
  name: string
  title: string
  currentDistrict?: string
}

const yesNoOptions: IOption[] = [
  {
    id: 2,
    name: 'Да',
  },
  {
    id: 1,
    name: 'Нет',
  },
];
export const UniversalInputBuilder = ({
  dotErrors,
  dotTouched,
  dotValue,
  type,
  disabled,
  nullable,
  child,
  title,
  formik,
  name,
  onChange,
  currentDistrict,
}: IUniversalInputBuilder) => {
  if (type === 'district') {
    const onDistrictChange = () => {
      formik.setFieldValue(child ? child : 'school_id', 0);
    };

    return (
      <UniversalEntitySelectInput
        name={name}
        formik={formik}
        title={title}
        onChange={onDistrictChange}
        action={'DISTRICT_LIST'}
        isNullable={nullable}
      />
    );
  }

  //School in district
  if (type === 'district_school') {
    return (
      <UniversalEntitySelectInput
        name={name}
        formik={formik}
        title={title}
        id={currentDistrict}
        action={'DISTRICT_SCHOOL_LIST'}
        isNullable={nullable}
      />
    );
  }

  //Static selects
  if (typeof type === 'string' && availableTypes.indexOf(type) !== -1) {
    return (
      <CUSelect
        data={typeToOptions(type)}
        name={name}
        formik={formik}
        title={title}
      />
    );
  }

  if (nameToAction[name]) {
    return (
      <UniversalEntitySelectInput
        name={name}
        formik={formik}
        title={title}
        action={nameToAction[name]}
        isNullable={nullable}
      />
    );
  }

  if (type === 'yesNo') {
    return (
      <CUSelect
        data={yesNoOptions}
        name={name}
        formik={formik}
        title={title}
      />
    );
  }
  if (type === 'place_type') {
    const data: IOption[] = [
      {
        id: 1,
        name: 'Участник',
      },
      {
        id: 4,
        name: 'Лауреат',
      },
      {
        id: 2,
        name: 'Призёр',
      },
      {
        id: 3,
        name: 'Победитель',
      },
    ];
    return (
      <CUSelect
        data={data}
        name={name}
        formik={formik}
        title={title}
      />
    );
  }
  if (type === 'gender') {
    const data: IOption[] = [
      {
        id: 1,
        name: 'Мужской',
      },
      {
        id: 2,
        name: 'Женский',
      },
    ];
    return (
      <CURadio
        data={data}
        name={name}
        formik={formik}
        title={title}
      />
    );
  }
  if (type === 'student.yesNo') {
    return (
      <CURadio
        data={yesNoOptions}
        name={name}
        formik={formik}
        title={title}
      />
    );
  }

  //SubjectClass
  if (type === 'subject_class') {
    return (
      <SubjectClassField
        name={name}
        formik={formik}
      />
    );
  }

  //EntityTag
  if (type === 'tags') {
    return (
      <EntityTag
        name={name}
        formik={formik}
      />
    );
  }

  if (arrayTypeNameToAction[name]) {
    return (
      <ArrayOfSelect
        name={name}
        formik={formik}
        title={title}
        action={arrayTypeNameToAction[name]}
        caption={arrayTypeNameToCaption[name]}
        onChange={onChange}
      />
    );
  }

  if (type === 'date') {
    const dateFormat = 'dd-MM-yyyy';

    const handleDateChange = (date: Date | null) => {
      if (date) {
        formik.setFieldValue(name, date, false);
      }
    };
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format={dateFormat}
          error={dotTouched && Boolean(dotErrors)}
          helperText={dotTouched && dotErrors}
          label={title}
          name={name}
          value={dotValue as Date}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'изменить дату',
          }}
        />
      </MuiPickersUtilsProvider>
    );
  }

  return (
    <TextField
      fullWidth
      disabled={disabled}
      id={name}
      name={name}
      label={title}
      type={type}
      value={dotValue}
      onChange={formik.handleChange}
      error={dotTouched && Boolean(dotErrors)}
      helperText={dotTouched && dotErrors}
      multiline={type === 'multiline'}
    />
  );
};
