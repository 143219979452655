import {RouteComponentProps} from '@reach/router';
import React from 'react';
import {IWrapper} from '../../CommonInterfaces/IWrapper';

/**
 *
 * @param {RouteComponentProps} props
 * @returns {JSX.Element}
 * @constructor
 */
export default function AuthWrapper({children}: IWrapper & RouteComponentProps) {
  return (
    children
  );
}
