import {Localization} from 'material-table';

export const UTableLocalization: Localization = {
  body: {
    emptyDataSourceMessage: 'Записи отсутствую',
    filterRow: {
      filterTooltip: 'Фильтровать',
    },
  },
  header: {
    actions: '',
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} из {count}',

    firstAriaLabel: 'Первая страница',
    firstTooltip: 'Первая страница',

    previousAriaLabel: 'Предыдущая страница',
    previousTooltip: 'Предыдущая страница',

    nextAriaLabel: 'Следующая страница',
    nextTooltip: 'Следующая страница',

    lastAriaLabel: 'Последняя страница',
    lastTooltip: 'Последняя страница',
  },

};
