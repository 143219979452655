import {Button, Grid, Theme, Tooltip, withStyles} from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import HealingOutlinedIcon from '@material-ui/icons/HealingOutlined';
import {navigate} from '@reach/router';
import {useSnackbar} from 'notistack';
import * as R from 'ramda';
import React, {useEffect, useState} from 'react';
import ResultsChildReadList from '../../ChildList/ResultsChildReadList';
import {ICardData} from '../../CommonInterfaces/Form';
import {AdminElement} from '../../Components/auth/AdminElement';
import {BackButton} from '../../Components/Buttons/BackButton';
import Card from '../../Components/Card/Card';
import {CardContainer} from '../../Components/CardContainer/CardContainer';
import {RowOfButtons} from '../../Components/Layout/RowOfButtons';
import PageTitle from '../../Components/PageTitle/PageTitle';
import RedirectTo404 from '../../Components/RedirectTo404';
import {makeApiPostActionHandler} from '../../Helpers/ApiRequestHelper';
import {LMessages} from '../../Localization/Localization';
import {IPageInterface} from '../entity/IPageInterface';
import {
  getEntityDetails,
  ResultUploadEntity,
  useGetEntityDetails,
} from '../entity/useGetEntityDetails';
import {makeResolveConflictURI} from './conflict/ResolveConflictCard';
import {ResultUploadReadFields} from './fields';

/**
 * Result Upload's read card.
 * @param {string | undefined} id
 * @returns {JSX.Element}
 * @constructor
 */
export default function ResultUploadReadCard({id}: IPageInterface) {
  const empty: ICardData = {};
  const {data, isError, setData, setIsError} = useGetEntityDetails<any>(
    ResultUploadEntity.name,
    id as string,
    empty);
  const [isChange, setIsChanged] = useState<boolean>(false);
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    if (isChange) {
      getEntityDetails<any>({
        entity: ResultUploadEntity.name,
        id: id as string,
        empty,
        setData,
        setError: setIsError,
      });
      setIsChanged(false);
      setCounter(() => {
        return counter + 1;
      });
    }
  }, [isChange]);

  const reloadEntity = () => {
    setIsChanged(true);
  };

  const handleCancel = makeApiPostActionHandler({
    action: 'RESULT_UPLOAD_CANCEL', id: id as string,
    snackBar: enqueueSnackbar,
    onSuccess: reloadEntity,
  });
  const handleApply = makeApiPostActionHandler({
    action: 'RESULT_UPLOAD_APPLY', id: id as string,
    snackBar: enqueueSnackbar,
    onSuccess: reloadEntity,
  });
  const handleResolve = () => {
    if (id) {
      navigate(makeResolveConflictURI(id as unknown as number));
    }
  };

  const canApply = data.status && [2, 3].indexOf(data.status.id) !== -1;
  const canCancel = data.status && data.status.id === 1;
  const canResolve = data.status && [5].indexOf(data.status.id) !== -1;

  const [counter, setCounter] = useState<number>(0);

  const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      fontSize: 18,
    },
  }))(Tooltip);

  return (
    <React.Fragment>
      <RedirectTo404 isError={isError}/>
      {!R.isEmpty(data) && data ?
        <PageTitle
          title={`Событие загрузки результата #${data.id}`}/> :
        null}

      <RowOfButtons>
        <BackButton/>
        <Grid item>
          <LightTooltip title={LMessages.RESULT_UPLOAD_CANCEL_WARNING}>
            <Button
              disabled={!canCancel}
              onClick={handleCancel}
              variant={'outlined'}
              color={'secondary'}
              startIcon={<ClearOutlinedIcon/>}>
              Отменить
            </Button>
          </LightTooltip>
        </Grid>
        <Grid item>
          <AdminElement>
            <Button
              disabled={!canApply}
              onClick={handleApply}
              variant={'outlined'}
              color={'primary'}
              startIcon={<CheckOutlinedIcon/>}>
              Применить
            </Button>
          </AdminElement>
        </Grid>
        {canResolve ?
          <Grid item>
            <Button
              onClick={handleResolve}
              variant={'outlined'}
              color={'secondary'}
              startIcon={<HealingOutlinedIcon/>}>
              Разрешить конфликты
            </Button>
          </Grid> : null}
      </RowOfButtons>

      {
        !R.isEmpty(data) ?? data ?
          <CardContainer>
            <Card data={data} fields={ResultUploadReadFields}/>
          </CardContainer> : ''
      }
      {id ?
        <ResultsChildReadList
          titleSuffix={'события загрузки'}
          parentId={id}
          parentName={'upload.name'}
          key={counter}
        /> :
        null}


    </React.Fragment>
  );
}
