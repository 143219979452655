import React from 'react';
import {LMessages} from '../../Localization/Localization';
import {IPageInterface} from '../entity/IPageInterface';
import {MainIndicatorsFields} from './fields';
import {
  createMainIndicatorRequest,
  IMainIndicatorRequest,
} from './ReportInterfaces';
import {UniversalReportPage} from './UniversalReportPage';

export const MainIndicatorReport = ({}: IPageInterface) => {
  const emptyEntity: IMainIndicatorRequest = createMainIndicatorRequest();

  return (
    <UniversalReportPage<IMainIndicatorRequest>
      action={'REPORT_MAIN_INDICATORS'}
      emptyEntity={emptyEntity}
      fields={MainIndicatorsFields}
      title={LMessages.REPORT_MAIN_INDICATORS_CARD_TITLE}
    />
  );
};
