import {Typography} from '@material-ui/core';
import React from 'react';
import {FullWidthDivider} from './FullWidthDivider';

interface IFormHeading {
  title: string
}

export const FormHeading = ({title}: IFormHeading) => {
  return (
    <React.Fragment>
      <Typography
        color={'textSecondary'}
        variant="h6" display="block"
        align={'center'}
      >
        {title}
      </Typography>
      <FullWidthDivider/>
    </React.Fragment>
  );
};
