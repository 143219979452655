import {Link, RouteComponentProps} from '@reach/router';
import {Column} from 'material-table';
import React from 'react';
import {AdminElement} from '../../Components/auth/AdminElement';
import {CreateButton} from '../../Components/Buttons/CreateButton';
import {ExportButton} from '../../Components/Buttons/ExportButton';
import Row from '../../Components/Layout/Row';
import {RowOfButtons} from '../../Components/Layout/RowOfButtons';
import PageTitle from '../../Components/PageTitle/PageTitle';
import {buildUniversalEntityFilter} from '../../Components/UniversalRListTable/Filters/buildUniversalEntityFilter';
import {DistrictFilter} from '../../Components/UniversalRListTable/Filters/DistrictFilter';
import {SchoolFilter} from '../../Components/UniversalRListTable/Filters/SchoolFilter';
import {yesNoFilter} from '../../Components/UniversalRListTable/Filters/StaticFilters';
import {DistrictContext} from '../../Components/UniversalRListTable/Helpers/DistrictContext';
import {useDistrictState} from '../../Components/UniversalRListTable/Helpers/useDistrictState';
import {UniversalRListTable} from '../../Components/UniversalRListTable/UniversalRListTable';

/**
 * Event's read list.
 * @param {RouteComponentProps} props
 * @returns {JSX.Element}
 * @constructor
 */
export default function EventsReadList(props: RouteComponentProps) {
  const DC = useDistrictState();

  const apiUriPrefix = '/event/';
  const yesNoLookup = {
    1: 'Нет',
    2: 'Да',
  };

  const academicYearFilter = buildUniversalEntityFilter('ACADEMIC_YEAR_LIST');
  const domainFilter = buildUniversalEntityFilter('DOMAIN_LIST');
  const levelFilter = buildUniversalEntityFilter('LEVEL_LIST');
  const typeFilter = buildUniversalEntityFilter('TYPES_LIST');
  const championshipFilter = buildUniversalEntityFilter('CHAMPIONSHIPS_LIST');
  const formFilter = buildUniversalEntityFilter('FORMS_LIST');
  const statusFilter = buildUniversalEntityFilter('STATUS_LIST');

  const Columns: Column<any>[] =
    [
      {
        title: '#',
        field: 'id',
        filtering: false,
      },
      {
        title: 'Название краткое',
        field: 'name_short',
        render: (rowData): React.ReactNode =>
          <Link to={`${apiUriPrefix}${rowData.id}`}>{rowData.name_short}</Link>,
      },
      {
        title: 'Название',
        field: 'name_full',
        render: (rowData): React.ReactNode =>
          <Link to={`${apiUriPrefix}${rowData.id}`}>{rowData.name_full}</Link>,
      },
      {
        title: 'Начало',
        field: 'start',
        filtering: false,
      },
      {
        title: 'Окончание',
        field: 'end',
        filtering: false,
      },
      {
        title: 'Открыто',
        field: 'is_open',
        lookup: yesNoLookup,
        defaultFilter: 0,
        filterComponent: yesNoFilter,
      },

      {
        title: 'Район',
        field: 'district.name',
        defaultFilter: DC.district,
        filterComponent: DistrictFilter,
        sorting: false,
      },
      {
        title: 'Организация',
        field: 'school.name',
        filterComponent: SchoolFilter,
      },
      {
        title: 'Год',
        field: 'academic_year.name',
        filterComponent: academicYearFilter,
      },
      {
        title: 'Направление',
        field: 'domain.name',
        filterComponent: domainFilter,
      },
      {
        title: 'Уровень',
        field: 'level.name',
        filterComponent: levelFilter,
      },
      {
        title: 'Тип',
        field: 'type.name',
        filterComponent: typeFilter,
      },
      {
        title: 'Первенство',
        field: 'championship.name',
        filterComponent: championshipFilter,
      },
      {
        title: 'Форма',
        field: 'form.name',
        filterComponent: formFilter,
      },
      {
        title: 'Статус',
        field: 'status.name',
        filterComponent: statusFilter,
      },
    ];
  const lastQueryKeyName = 'eventsReadList';

  return (
    <React.Fragment>
      <PageTitle title={'Мероприятия'}/>
      <RowOfButtons>
        <ExportButton name={lastQueryKeyName}/>
        <AdminElement>
          <CreateButton entity={'event'}/>
        </AdminElement>
      </RowOfButtons>

      <Row>
        <DistrictContext.Provider value={DC}>
          <UniversalRListTable
            columns={Columns}
            action={'EVENTS_INDEX'}
            tableName={lastQueryKeyName}
          />
        </DistrictContext.Provider>
      </Row>

    </React.Fragment>
  );
}
