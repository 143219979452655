import {Link, RouteComponentProps} from '@reach/router';
import {Column} from 'material-table';
import React from 'react';
import {IRowData} from '../../CommonInterfaces/IRowData';
import {ExportButton} from '../../Components/Buttons/ExportButton';
import Row from '../../Components/Layout/Row';
import {RowOfButtons} from '../../Components/Layout/RowOfButtons';
import PageTitle from '../../Components/PageTitle/PageTitle';
import {DistrictFilter} from '../../Components/UniversalRListTable/Filters/DistrictFilter';
import {SchoolFilter} from '../../Components/UniversalRListTable/Filters/SchoolFilter';
import {
  genderFilter,
  yesNoFilter,
} from '../../Components/UniversalRListTable/Filters/StaticFilters';
import {DistrictContext} from '../../Components/UniversalRListTable/Helpers/DistrictContext';
import {useDistrictState} from '../../Components/UniversalRListTable/Helpers/useDistrictState';
import {UniversalRListTable} from '../../Components/UniversalRListTable/UniversalRListTable';

/**
 * R-list of Students.
 * @param {RouteComponentProps} props
 * @constructor
 */
export default function StudentsRList(props: RouteComponentProps) {
  const DC = useDistrictState();

  const apiUriPrefix = '/student/';
  const yesNoLookup = {
    1: 'Нет',
    2: 'Да',
  };
  const Columns: Column<IRowData>[] =
    [
      {
        title: '#',
        field: 'id',
        filtering: false,
      },
      {
        title: 'Фамилия',
        field: 'last_name',
        render: (rowData): React.ReactNode =>
          <Link to={`${apiUriPrefix}${rowData.id}`}>{rowData.last_name}</Link>,
      },
      {
        title: 'Имя',
        field: 'first_name',
        render: (rowData): React.ReactNode =>
          <Link to={`${apiUriPrefix}${rowData.id}`}>{rowData.first_name}</Link>,
      },
      {
        title: 'Отчество',
        field: 'second_name',
        render: (rowData): React.ReactNode =>
          <Link
            to={`${apiUriPrefix}${rowData.id}`}>{rowData.second_name}</Link>,
      },
      {
        title: 'Район',
        field: 'district.name',
        defaultFilter: DC.district,
        filterComponent: DistrictFilter,
      },
      {
        title: 'Организация',
        field: 'school.name',
        filterComponent: SchoolFilter,
      },
      {
        title: 'Пол',
        field: 'gender',
        lookup: {
          2: 'Ж',
          1: 'М',
        },
        defaultFilter: 0,
        filterComponent: genderFilter,
      },
      {
        title: 'Гражданин',
        field: 'is_citizen',
        lookup: yesNoLookup,
        defaultFilter: 0,
        filterComponent: yesNoFilter,
      },
      {
        title: 'ОВЗ',
        field: 'has_disability',
        lookup: yesNoLookup,
        defaultFilter: 0,
        filterComponent: yesNoFilter,
      },
      {
        title: 'Дата рождения',
        field: 'birthday',
        filtering: false,
      },
    ];
  const lastQueryKeyName = 'studentsReadList';

  return (
    <React.Fragment>
      <PageTitle title={'Ученики'}/>
      <RowOfButtons>
        <ExportButton name={lastQueryKeyName}/>
      </RowOfButtons>

      <Row>
        <DistrictContext.Provider value={DC}>
          <UniversalRListTable
            columns={Columns}
            action={'STUDENTS_INDEX'}
            tableName={lastQueryKeyName}
          />
        </DistrictContext.Provider>
      </Row>

    </React.Fragment>
  );
}
