import {navigate} from '@reach/router';
import {ProviderContext} from 'notistack';
import * as R from 'ramda';
import {IZeroArgumentVoidCallback} from '../CommonInterfaces/Callbacks';
import {IStringIndexedByNumber} from '../CommonInterfaces/IIdNameObject';
import {apiService} from './apiService';
import {getEntityDetailsURI, uriGenerator} from './UriGenerator';

interface IApiRequestHelper {
  action: string,
  id?: string | string[],
  responseHandler: any,
  errorHandler?: any | undefined,
  config?: any | undefined
}

interface IApiByUriRequestHelper {
  actionUri: string,
  id?: string,
  responseHandler: any,
  errorHandler?: any | undefined,
  config?: any | undefined
}

const defaultErrorHandler = (response: any) => {
  console.log(response); //todo: make error handling
};

export const apiRequestHelper = ({
  action,
  id,
  responseHandler,
  errorHandler,
  config,
}: IApiRequestHelper) => {
  const {api} = uriGenerator({action, id});
  const rConfig = (config && !R.isEmpty(config)) ? config : {};
  apiService.get(api, rConfig).then(
    (response) => {
      responseHandler(response);
    }).catch((response) => {
    if (errorHandler) {
      errorHandler(response);
    } else {
      defaultErrorHandler(response);
    }
  });
};

export const apiByURIRequestHelper = ({
  actionUri,
  id,
  responseHandler,
  errorHandler,
  config,
}: IApiByUriRequestHelper) => {
  const rConfig = (config && !R.isEmpty(config)) ? config : {};
  apiService.get(actionUri, rConfig).then(
    (response) => {
      responseHandler(response);
    }).catch((response) => {
    if (errorHandler) {
      errorHandler(response);
    } else {
      defaultErrorHandler(response);
    }
  });
};

export const responseToSuccessMessageBuilder = (
  success_message: string,
  snackBar: ProviderContext['enqueueSnackbar'],
  redirect?: string,
  onSuccess?: IZeroArgumentVoidCallback) => {
  return () => {
    const afterRedirect = () => {
      snackBar(success_message, {
        variant: 'success',
      });
      if (onSuccess) {
        onSuccess();
      }
    };
    if (redirect) {
      navigate(redirect).then(afterRedirect);
    } else {
      afterRedirect();
    }

  };
};

export interface ImportResponseMessages extends IStringIndexedByNumber {
  1: string,
  5: string
}

export const responseToImportSuccessBuilder = (
  messages: ImportResponseMessages,
  snackBar: ProviderContext['enqueueSnackbar'],
  redirect: string) => {
  return (response: any) => {
    const id = response.data?.data?.id;
    const status = response.data?.data?.status;
    const afterRedirect = () => {
      const variant = status === 5 ? 'warning' : 'success';
      const message = status === 5 ? messages['5'] : messages['1'];
      snackBar(message, {
        variant: variant,
      });
    };
    navigate(redirect + id).then(afterRedirect);
  };
};

/**
 * Make delete handler.
 * @param {string} action
 * @param {string} id
 * @param {any} snackBar
 * @return {() => void}
 */
export function makeDeleteHandler(action: string, id: string, snackBar: any) {
  return () => {
    const {api, redirect, success_message} = uriGenerator({action, id});
    const responseWithMessage = responseToSuccessMessageBuilder(success_message,
      snackBar, redirect);
    apiService.post(api).then(responseWithMessage).catch((response) => {
      const error = response.response.data.errors;
      snackBar(error, {
        variant: 'error',
      });
    });
  };
}

/**
 * Make delete handler for read list.
 * @param {string} action
 * @param {string} id
 * @param {any} snackBar
 * @return {() => void}
 */
export function makeRowDeleteHandler(
  action: string, id: string, snackBar: any) {
  return () => {
    const {api, success_message} = uriGenerator({action, id});
    const responseWithMessage = responseToSuccessMessageBuilder(success_message,
      snackBar);
    apiService.post(api).then(responseWithMessage).catch((response) => {
      const error = response.response.data.errors;
      snackBar(error, {
        variant: 'error',
      });
    });
  };
}

interface IMakeApiPostActionHandler {
  action: string,
  id: string,
  snackBar: any,
  onSuccess?: IZeroArgumentVoidCallback
  data?: {}
}

/**
 * Make handler for entity modification.
 * @param {string} action
 * @param {string} id
 * @param {any} snackBar
 * @param onSuccess
 * @param data
 * @return {() => void}
 */
export function makeApiPostActionHandler(
  {action, id, snackBar, onSuccess, data = {}}: IMakeApiPostActionHandler) {
  return () => {
    const {api, redirect, success_message} = uriGenerator({action, id});
    const responseWithMessage = responseToSuccessMessageBuilder(success_message,
      snackBar, redirect, onSuccess);
    apiService.post(api, data).then(responseWithMessage).catch((response) => {
      const error = response.response.data.errors;
      snackBar(error, {
        variant: 'error',
      });
    });
  };
}

interface IGetEntityDetailsHelper {
  id: string,
  entity: string,
  responseHandler: any,
  errorHandler?: any | undefined,
}

export const getEntityDetailsHelper = ({
  id,
  entity,
  responseHandler,
  errorHandler,
}: IGetEntityDetailsHelper) => {
  const uri = getEntityDetailsURI({
    entity, id,
  });
  apiService.get(uri).then(
    (response) => {
      responseHandler(response);
    }).catch((response) => {
    if (errorHandler) {
      errorHandler(response);
    } else {
      defaultErrorHandler(response);
    }
  });

};
