import {ReadCardField} from '../../CommonInterfaces/Form';

export const StudentReadFields: ReadCardField[] = [
  {
    title: 'Идентификатор',
    name: 'id',
  },
  {
    title: 'Имя',
    name: 'first_name',
  },
  {
    title: 'Отчество',
    name: 'second_name',
  },
  {
    title: 'Фамилия',
    name: 'last_name',
  },
  {
    title: 'Дата рождения',
    name: 'birthday',
  },
  {
    title: 'Пол',
    name: 'gender',
  },
  {
    title: 'Гражданство',
    name: 'is_citizen',
  },
  {
    title: 'ОВЗ',
    name: 'has_disability',
  },
  {
    title: 'Район',
    name: 'district',
    type: 'district',
  },
  {
    title: 'Организация',
    name: 'school',
    type: 'organization',
  },
];
