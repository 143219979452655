import TextField from '@material-ui/core/TextField';
import React from 'react';
import {getFieldProps} from '../../../Helpers';
import {ITextField} from './IFormField';

export const CUSelect = ({
  formik,
  name,
  title,
  data,
  concise = false,
}: ITextField) => {
  const {dotValue, dotErrors, dotTouched} = getFieldProps({formik, name});

  return (
    <TextField
      fullWidth={!concise}
      select
      id={name}
      name={name}
      label={!concise ? title : null}
      value={dotValue}
      onChange={formik.handleChange}
      error={dotTouched && Boolean(dotErrors)}
      helperText={dotTouched && dotErrors}
    >
      {data.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </TextField>
  );
};

