export interface ILoginCredentials {
  'login': string,
  'password': string
}

export const createEmptyLoginCredentials = (): ILoginCredentials => {
  return {
    'login': '',
    'password': '',
  };
};

export interface IForget {
  'email': string,
}

export const createEmptyForget = (): IForget => {
  return {
    'email': '',
  };
};

export interface IReset {
  'email': string,
  'password': string,
  'password_confirmation': string,
  'token': string,
}

export const createEmptyReset = (token: string, email: string): IReset => {
  return {
    'email': email,
    'password': '',
    'password_confirmation': '',
    'token': token,
  };
};
