import {apiService} from '../../Helpers/apiService';
import {uriGenerator} from '../../Helpers/UriGenerator';

/**
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getCSRFCookie() {
  const {api} = uriGenerator({action: 'SCRF_COOKIE'});
  return apiService.get(api);
}
