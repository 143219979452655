import {Checkbox, ListItemText, MenuItem, Select} from '@material-ui/core';
import {EditCellColumnDef} from 'material-table';
import React, {useEffect, useState} from 'react';
import {ValueTitleObject} from '../../../CommonInterfaces/IIdNameObject';
import {apiRequestHelper} from '../../../Helpers/ApiRequestHelper';
import {responseToIdNameOptions} from './Helpers/responseToIdNameOptions';
import {IFilterGenerator} from './IFilterGenerator';

/**
 * Generate Tags filter for Read list table.
 * @param {string} action
 * @return {(props: IFilterGenerator) => React.ReactElement}
 * @constructor
 */
export function buildUniversalTagsFilter(action: string) {
  return (props: IFilterGenerator): React.ReactElement => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selected, setSelected] = useState<string[]>([]);
    const [isDefaultLoaded, setIsDefaultLoaded] = useState<boolean>(false);

    if (!isDefaultLoaded) {
      if (props.columnDef.defaultFilter) {
        setSelected(props.columnDef.defaultFilter);
      }
      setIsDefaultLoaded(true);
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [options, setOptions] = useState<ValueTitleObject[]>(
      []);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const responseHandler = (response: any) => {
        responseToIdNameOptions(response, setOptions);
      };
      apiRequestHelper({
        action: action, responseHandler,
      });
    }, []);
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      const val = event.target.value as string[];

      setSelected(val);
      props.onFilterChanged(
        (props.columnDef as EditCellColumnDef).tableData.id.toString(), val);
    };

    const renderSelectedOptions = (selected: any) => {
      const selectedText = selected.map((el: number) => {
        for (let i = 0; i < options.length; i++) {
          if (options[i].value === el) {
            return options[i].title;
          }
        }
      });
      return selectedText.join(', ');
    };
    return (
      <Select
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={renderSelectedOptions}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={selected.indexOf(option.value as string) > -1}/>
            <ListItemText primary={option.title}/>
          </MenuItem>
        ))}
      </Select>
    );
  };
}
