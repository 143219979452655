import {createAction, createReducer} from '@reduxjs/toolkit';

export interface ISavedOrder {
  fieldIndex: number,
  order?: 'asc' | 'desc'
}

interface SOC {
  [index: string]: ISavedOrder
}

interface lastOrdersState {
  list: SOC
}

export const setLastOrder = createAction<{ key: string, value: ISavedOrder }>(
  'lastOrders/set');
const initState = {list: {}} as lastOrdersState;
export const lastOrdersReducer = createReducer(initState, (builder) => {
  builder.addCase(setLastOrder, (state, action) => {
    const {key, value} = action.payload;
    state.list[key] = value;
  });
});
