import {Grid} from '@material-ui/core';
import React from 'react';
import {useStyles} from './styles';

interface IRow {
  children: React.ReactNode,
  size?: any
}

/**
 * Row with title.
 *
 * @param {string} title
 * @constructor
 */
export default function Row({children, size = 12}: IRow) {
  const classes = useStyles();

  return (
    <Grid item xl={size} className={classes.row}>
      {children}
    </Grid>
  );
}
