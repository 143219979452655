import {useSnackbar} from 'notistack';
import * as R from 'ramda';
import React, {useContext} from 'react';
import {ICardData} from '../../CommonInterfaces/Form';
import {UserContext} from '../../Components/auth/UserContext';
import {BackButton} from '../../Components/Buttons/BackButton';
import {CardContainer} from '../../Components/CardContainer/CardContainer';
import {CreateAndUpdateForm} from '../../Components/FormGenerators/CreateAndUpdateForm';
import {IEditableUser} from '../../Components/FormGenerators/IEditableUser';
import {RowOfButtons} from '../../Components/Layout/RowOfButtons';
import PageTitle from '../../Components/PageTitle/PageTitle';
import RedirectTo404 from '../../Components/RedirectTo404';
import {IPageInterface} from '../entity/IPageInterface';
import {useGetEntityDetails, UserEntity} from '../entity/useGetEntityDetails';
import {UserUpdateFieldsCoordinator, UserUpdateFieldsFull} from './fields';

/**
 * User's U-Card.
 * @param {string} id
 * @constructor
 */
export default function UserUCard({id}: IPageInterface) {
  const emptyUser: ICardData = {};
  const {data, isError} = useGetEntityDetails<IEditableUser>(UserEntity.name,
    id as string,
    emptyUser as unknown as IEditableUser);

  const {enqueueSnackbar} = useSnackbar();
  const {currentUser} = useContext(UserContext);

  return (
    <React.Fragment>
      <RedirectTo404 isError={isError}/>

      {data && !R.isEmpty(data) ? <PageTitle
        title={`Изменение пользователя ${data.first_name} ${data.last_name}`}
      /> : null}

      <RowOfButtons>
        <BackButton/>
      </RowOfButtons>

      {
        !R.isEmpty(data) ?
          <CardContainer>
            <CreateAndUpdateForm<IEditableUser>
              action={'USER_UPDATE'}
              id={id}
              fields={currentUser.role_id === 1 ?
                UserUpdateFieldsFull :
                UserUpdateFieldsCoordinator}
              data={data as unknown as IEditableUser}
              snackBar={enqueueSnackbar}
            />
          </CardContainer> :
          null
      }

    </React.Fragment>
  );
}
