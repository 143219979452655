import React from 'react';
import {LMessages} from '../../Localization/Localization';
import {IPageInterface} from '../entity/IPageInterface';
import {ReportByStudentFields} from './fields';
import {createCommonRequest, ICommonRequest} from './ReportInterfaces';
import {UniversalReportPage} from './UniversalReportPage';

export const StudentResultsReport = ({}: IPageInterface) => {
  return (
    <UniversalReportPage<ICommonRequest>
      action={'REPORT_STUDENT_RESULTS'}
      emptyEntity={createCommonRequest()}
      fields={ReportByStudentFields}
      title={LMessages.REPORT_STUDENT_RESULTS_CARD_TITLE}
    />
  );
};
