import React, {useState} from 'react';

interface IUser {
  id: number | undefined | null,
  login: string | undefined | null,
  authorized: boolean | undefined,
  role_id: number | undefined,
  is_special: boolean | undefined,
}

interface IUserContext {
  currentUser: IUser,
  setCurrentUser: any
}

export const undefinedUser: IUser = {
  id: undefined,
  login: undefined,
  authorized: undefined,
  role_id: undefined,
  is_special: undefined,
};

export const unauthorizedUser: IUser = {
  id: undefined,
  login: undefined,
  authorized: false,
  role_id: undefined,
  is_special: undefined,
};

const defaultUserContext = {
  currentUser: undefinedUser,
  setCurrentUser: undefined,
};

export const UserContext = React.createContext<IUserContext>(
  defaultUserContext);

export const useUserState = (): IUserContext => {
  const [currentUser, setCurrentUser] = useState<IUser>(undefinedUser);
  return {
    currentUser: currentUser,
    setCurrentUser: setCurrentUser,
  };
};
