import TextField from '@material-ui/core/TextField';
import * as dotProp from 'dot-prop';
import * as R from 'ramda';
import React, {useEffect, useState} from 'react';
import {IdNameEntity as IDistrict} from '../../../CommonInterfaces/IIdNameObject';
import {apiRequestHelper} from '../../../Helpers/ApiRequestHelper';

interface ITextField extends IFormField {
  formik: any,
}

export interface IFormField {
  name: string,
  nameDot?: string,
  title: string,
  action: string
  id?: string,
  onChange?: any,
  isNullable?: boolean,
  concise?: boolean
}

const getEmptyDistricts = (): IDistrict[] => {
  return [];
};

export const UniversalEntitySelectInput = ({
  formik,
  name,
  nameDot,
  title,
  action,
  id,
  onChange,
  isNullable,
  concise = false,
}: ITextField) => {
  const [data, setData] = useState<IDistrict[]>(getEmptyDistricts());

  useEffect(() => {
    let isCanceled = false;
    if (action === 'DISTRICT_SCHOOL_LIST' && !id) {
      setData([]);
      return;
    }
    const responseHandler = (response: any) => {
      if (isCanceled) {
        return;
      }
      const prefix = isNullable ? [{id: 0, name: ''}] : [];
      setData([...prefix, ...response.data.data]);
    };
    apiRequestHelper({
      action, id, responseHandler,
    });
    return () => {
      isCanceled = true;
    };
  }, [id, action]);

  const onChangeHandler = (typeof onChange === 'function') ?
    (...props: any) => {
      onChange(...props);
      formik.handleChange(...props);
    } :
    formik.handleChange;

  const [isInOptions, setIsInOptions] = useState<boolean>(false);
  useEffect(() => {
    if (action === 'DISTRICT_SCHOOL_LIST') {
      let isInOptions = false;
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === dotProp.get(formik.values, name)) {
          isInOptions = true;

        }
      }
      setIsInOptions(true);
    }
  }, [data]);

  const propName = nameDot ? nameDot : name;

  const value = ((isInOptions || action !== 'DISTRICT_SCHOOL_LIST') &&
    dotProp.get(formik.values, propName) !== undefined) ?
    dotProp.get(formik.values, propName) :
    '';

  return (
    <TextField
      fullWidth={!concise}
      select
      id={name}
      name={name}
      label={!concise ? title : null}
      value={!R.isEmpty(data) ? value : ''}
      onChange={onChangeHandler}
      error={dotProp.get(formik.touched, propName) as [] &&
      Boolean(dotProp.get(formik.errors, propName))}
      helperText={dotProp.get(formik.touched, propName) as boolean &&
      dotProp.get(formik.errors, propName) as []}
    >
      {data.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </TextField>
  );
};

