import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React from 'react';
import {getFieldProps} from '../../../Helpers';
import {ITextField} from './IFormField';

export const CURadio = ({
  formik,
  name,
  title,
  data,
}: ITextField) => {
  const {dotValue, dotErrors, dotTouched} = getFieldProps({formik, name});

  return (
    <FormControl component="fieldset" fullWidth
      error={dotTouched && Boolean(dotErrors)}>
      <FormLabel component="legend">{title}</FormLabel>
      <RadioGroup aria-label="gender"
        name={name}
        value={dotValue}
        onChange={formik.handleChange}
      >
        {data.map((option) => (
          <FormControlLabel
            checked={(dotValue == option.id)}
            value={(option.id)}
            key={option.id}
            label={option.name}
            control={<Radio/>}/>
        ))}
      </RadioGroup>
      <FormHelperText>{dotTouched && dotErrors}</FormHelperText>
    </FormControl>
  );
};
