import {MenuItem, Select} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {ValueTitleObject} from '../../../CommonInterfaces/IIdNameObject';
import {apiRequestHelper} from '../../../Helpers/ApiRequestHelper';
import {buildApplyFilterChange} from './Helpers/buildApplyFilterChange';
import {buildResponseToIdNameOptions} from './Helpers/responseToIdNameOptions';
import {IFilterGenerator} from './IFilterGenerator';
import {
  AnyOptionFemale,
  AnyOptionMale,
  AnyOptionNeutered,
} from './StaticFilters';

/**
 * Build entity filter for read list table.
 *
 * @param {string} action
 * @param gender
 * @returns {(props: IFilterGenerator) => React.ReactElement}
 * @constructor
 */
export function buildUniversalEntityFilter(action: string, gender = 0) {
  return (props: IFilterGenerator): React.ReactElement => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setValue] = useState<string>('');
    const [isDefaultLoaded, setIsDefaultLoaded] = useState<boolean>(false);
    if (!isDefaultLoaded) {
      if (props.columnDef.defaultFilter) {
        setValue(props.columnDef.defaultFilter);
      }
      setIsDefaultLoaded(true);
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [options, setOptions] = useState<ValueTitleObject[]>(
      []);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      let isCanceled = false;
      const responseHandler = (response: any) => {
        const selectOptions = buildResponseToIdNameOptions(response);
        let anyOption;
        switch (gender) {
          case 1:
            anyOption = AnyOptionMale;
            break;
          case 2:
            anyOption = AnyOptionFemale;
            break;
          case 3:
            anyOption = AnyOptionNeutered;
            break;
          default:
            anyOption = AnyOptionMale;
        }
        if (!isCanceled) {
          setOptions([anyOption, ...selectOptions]);
        }
      };
      apiRequestHelper({
        action: action, responseHandler,
      });
      return () => {
        isCanceled = true;
      };
    }, []);

    const handleChange = buildApplyFilterChange(setValue, props);

    return (
      <Select value={value}
        onChange={handleChange}>
        {options.map(SelectOption)}
      </Select>
    );
  };
}

const SelectOption = (el: ValueTitleObject) => {
  return <MenuItem key={el.value} value={el.value}>{el.title}</MenuItem>;
};
