import {createStyles, IconButton, makeStyles, Theme} from '@material-ui/core';
import DeleteOutlineOutlinedIcon
  from '@material-ui/icons/DeleteOutlineOutlined';
import produce from 'immer';
import React, {useEffect, useState} from 'react';
import {FormHeading} from '../../../Typography/FormHeading';
import {FullWidthDivider} from '../../../Typography/FullWidthDivider';
import {
  emptySubjectClass,
  IEditableEvent,
  ISubjectClassContainer,
} from '../../IEditableEvent';
import {UniversalEntitySelectInput} from '../UniversalEntitySelectInput';
import {CUSelect} from '../UniversalInput/Elements/CUSelect';
import {typeToOptions} from '../UniversalInput/typeToOptions';

interface ISubjectClassField {
  name: string
  formik: any,
}

export const subjectClassArrayToObject = (data: any) => {
  if (data && Array.isArray(data.subject_class)) {
    const scObject: ISubjectClassContainer = {};
    for (let i = 0; i < data.subject_class.length; i++) {
      scObject[i] = data.subject_class[i];
    }
    data.subject_class = scObject;
  }
  return data;
};

export const removeLastEmptySubjectClass = (data: IEditableEvent) => {
  const keys = Object.keys(data.subject_class);
  const key: number = keys.pop() as unknown as number;

  const elToRemove = data.subject_class[key];

  if (elToRemove && elToRemove.subject.id === '' && elToRemove.class === '') {
    return produce(data, (draft) => {
      delete draft.subject_class[key];
    });
  }
  return data;
};

export const SubjectClassField = ({
  name,
  formik,
}: ISubjectClassField) => {
  const [rows, setRows] = useState<any>([]);
  const [values, setValues] = useState<ISubjectClassContainer>({});
  const [errors, setErrors] = useState<any>([]);

  const addPair = () => {
    const newEl = produce(values, (draft: ISubjectClassContainer) => {
      const key: number = parseInt(Object.keys(draft).pop() as string);
      const newKey = key + 1;
      draft[newKey] = emptySubjectClass;
    });
    formik.setFieldValue(name, newEl, false);
    setValues(newEl);
  };

  useEffect(() => {
    setValues(formik.values[name]);
    setErrors(formik.errors[name]);
  }, [formik.values[name], formik.errors[name]]);

  useEffect(() => {
    const lastEl = values[Object.keys(values).pop() as unknown as number];
    if (lastEl) {
      if (lastEl.class !== '' || lastEl.subject.id !== '') {
        addPair();
      }
    }

    let i = -1;
    const rows = Object.keys(values).map((el) => {
      i++;
      const deleteHandler = (i: number) => {
        let newEl: ISubjectClassContainer;
        newEl = produce(values, (draft) => {
          delete draft[i];
        });

        if (Object.keys(newEl).length === 0) {
          newEl = {};
          newEl[0] = emptySubjectClass;
        }

        formik.setFieldValue(name, newEl, false);
        setValues(newEl);
      };

      return <SubjectClassRow name={name} formik={formik}
        index={el as unknown as number}
        onDelete={deleteHandler}
        key={el}/>;
    });
    setRows(rows);
  }, [values, errors]);

  return (
    <React.Fragment>
      <FullWidthDivider/>
      <FormHeading title={'Предметы и классы'}/>
      {rows}
    </React.Fragment>
  );

};

interface ISubjectClassRow {
  formik: any
  name: string
  index: number
  onDelete: any
}

const SubjectClassRow = ({
  formik,
  name,
  index,
  onDelete,
}: ISubjectClassRow) => {
  const classDot = `${name}.${index}.class`;

  const subjectName = `${name}[${index}].subject.id`;
  const subjectNameDot = `${name}.${index}.subject.id`;

  const deleteHandler = () => {
    onDelete(index);
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        '& .MuiTextField-root': {
          margin: theme.spacing(1),
          width: '27ch',
        },
      },
    }),
  );
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <UniversalEntitySelectInput
        concise={true}
        name={subjectName}
        formik={formik}
        title={'Предмет'}
        action={'SUBJECTS_LIST'}
        nameDot={subjectNameDot}
      />
      <CUSelect
        concise={true}
        data={typeToOptions('class')}
        name={classDot}
        formik={formik}
        title={'Класс'}
      />
      <IconButton color="default" component="span" onClick={deleteHandler}>
        <DeleteOutlineOutlinedIcon/>
      </IconButton>
      <FullWidthDivider/>
    </div>
  );
};
