import {FormControlLabel, Radio, RadioGroup} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {Link, RouteComponentProps} from '@reach/router';
import {Column} from 'material-table';
import React from 'react';
import {IRowData} from '../../../CommonInterfaces/IRowData';
import Row from '../../../Components/Layout/Row';
import {UniversalRListTable} from '../../../Components/UniversalRListTable/UniversalRListTable';
import {IResolveCard} from './interfaces';

/**
 * Teacher's R-List.
 * @param {RouteComponentProps} props
 * @return {JSX.Element}
 * @constructor
 */
export default function TeacherSchoolChangedReadList({
  actionUri,
  actionIds,
  selected,
  setSelected,
}: IResolveCard & RouteComponentProps) {
  const apiUriPrefix = '/teacher/';
  const Columns: Column<IRowData>[] =
    [
      {
        title: '#',
        field: 'id',
      },
      {
        title: 'Фамилия',
        field: 'last_name',
        render: (rowData): React.ReactNode =>
          <Link to={`${apiUriPrefix}${rowData.id}`}>{rowData.last_name}</Link>,
      },
      {
        title: 'Имя',
        field: 'first_name',
        render: (rowData): React.ReactNode =>
          <Link to={`${apiUriPrefix}${rowData.id}`}>{rowData.first_name}</Link>,
      },
      {
        title: 'Отчество',
        field: 'second_name',
        render: (rowData): React.ReactNode =>
          <Link
            to={`${apiUriPrefix}${rowData.id}`}>{rowData.second_name}</Link>,
      },
      {
        title: 'Район',
        field: 'district.name',
      },
      {
        title: 'Организация',
        field: 'school.name',
      },
    ];
  const lastQueryKeyName = 'teachersReadList';

  const tableActions = [
    {
      icon: () => <DeleteOutlineIcon/>,
      onClick: () => {
      },
    },
  ];

  const component = {
    Action: (props: any) => (
      <Radio
        onClick={(event) => {
          setSelected(props.data.id);
          return props.action.onClick(event, props.data);
        }}
        checked={selected == props.data.id}
      />
    ),
  };

  return (
    <React.Fragment>
      <Row>
        <UniversalRListTable
          components={component}
          tableActions={tableActions}
          filtering={false}
          sorting={false}
          columns={Columns}
          actionUri={actionUri}
          actionIds={actionIds}
          tableName={lastQueryKeyName}
        />
      </Row>
      <Row>
        <RadioGroup value={selected}>
          <FormControlLabel control={<Radio
            onClick={(event) => {
              setSelected(-1);
            }}
            checked={selected == -1}
          />} label="Создать нового учителя"/>
        </RadioGroup>
      </Row>
    </React.Fragment>
  );
}
