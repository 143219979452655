import {useSnackbar} from 'notistack';
import * as R from 'ramda';
import React from 'react';
import {BackButton} from '../../Components/Buttons/BackButton';
import {CardContainer} from '../../Components/CardContainer/CardContainer';
import {CreateAndUpdateForm} from '../../Components/FormGenerators/CreateAndUpdateForm';
import {IEditableEvent} from '../../Components/FormGenerators/IEditableEvent';
import {RowOfButtons} from '../../Components/Layout/RowOfButtons';
import PageTitle from '../../Components/PageTitle/PageTitle';
import RedirectTo404 from '../../Components/RedirectTo404';
import {IPageInterface} from '../entity/IPageInterface';
import {IASCContainer} from './AllowedSchoolsCard';
import {
  AllowedSchoolsElement,
  useAllowed,
} from './Elements/AllowedSchoolsElement';
import {useEventUpdateData} from './Elements/useEventUpdateData';
import {EventUpdateFields} from './fields';
import {processBeforeSend} from './helpers';

/**
 * Event's update card.
 * @param {string | undefined} id
 * @return {JSX.Element}
 * @constructor
 */
export default function EventUpdateCard({id}: IPageInterface) {
  const {data, isError} = useEventUpdateData(id as string);

  const {enqueueSnackbar} = useSnackbar();

  const [allowedBy, onDataChange] = useAllowed();

  return (
    <React.Fragment>
      <RedirectTo404 isError={isError}/>

      {data && !R.isEmpty(data) ? <PageTitle
        title={`Изменение мероприятия ${data.name_short}`}
      /> : null}

      <RowOfButtons>
        <BackButton/>
      </RowOfButtons>

      {
        !R.isEmpty(data) ?
          <CardContainer>
            <CreateAndUpdateForm<IEditableEvent>
              action={'EVENT_UPDATE'}
              id={id}
              fields={EventUpdateFields}
              data={data as unknown as IEditableEvent}
              snackBar={enqueueSnackbar}
              preProcessData={processBeforeSend}
              onChange={onDataChange}
            />
          </CardContainer> :
          null
      }
      <AllowedSchoolsElement allowedBy={allowedBy as IASCContainer}/>
    </React.Fragment>
  );
}
