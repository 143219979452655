import {createStyles, Divider, makeStyles, Theme} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => {
    const spacing = theme.spacing(2);

    return createStyles({
      divider: {
        margin: `${spacing}px -${spacing}px ${spacing}px -${spacing}px`,
      },
    });
  },
);

export const FullWidthDivider = () => {
  const classes = useStyles();

  return (
    <Divider className={classes.divider}/>
  );
};
