import * as dotProp from 'dot-prop';
import React from 'react';
import {IFormik} from '../../CommonInterfaces/IFormik';
import {dotGet} from '../../Helpers/dotGet';

interface IGetFieldProps {
  formik: IFormik<any>,
  name: string
}

export const getFieldProps = ({formik, name}: IGetFieldProps) => {
  const dotValue = dotProp.get(formik.values, name);
  const dotErrors: React.ReactNode = dotGet(name)(formik.errors);
  const dotTouched: boolean = dotProp.get(formik.touched, name) as boolean;
  return {
    dotValue, dotErrors, dotTouched,
  };
};
