import {Button, Grid} from '@material-ui/core';
import PresentToAllOutlinedIcon from '@material-ui/icons/PresentToAllOutlined';
import React from 'react';
import {useAppSelector} from '../../hooks';

interface IExportButton {
  query?: string,
}

export interface ICanUseExportQuerySelector {
  name: string
}

/**
 * Button for export from Read List.
 * @returns {JSX.Element}
 * @constructor
 */
export const ExportButton = ({
  name,
}: IExportButton & ICanUseExportQuerySelector) => {
  const exportQuery = useAppSelector(
    state => state.exportQuery.list[name]);

  const handler = () => {
    if (exportQuery) {
      window.open(exportQuery.replace('api/', 'api/export/'));
    }
  };
  return (
    <Grid item>
      <Button
        variant={'outlined'}
        color={'secondary'}
        onClick={handler}
        startIcon={<PresentToAllOutlinedIcon/>}>
        Экспорт
      </Button>
    </Grid>
  );
};
