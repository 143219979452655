import 'date-fns';
import * as dotProp from 'dot-prop';
import React, {useEffect, useState} from 'react';
import {ITextField} from '../../../../CommonInterfaces/Form';
import {AdminOrSpecialCoordinatorElement} from '../../../auth/AdminOrSpecialCoordinatorElement';
import {FormHeading} from '../../../Typography/FormHeading';
import {FullWidthDivider} from '../../../Typography/FullWidthDivider';
import {getFieldProps} from '../../Helpers';
import {UniversalInputBuilder} from './UniversalInputBuilder';

/**
 * Build text field with Formik.
 * @param {any} formik
 * @param {string} name
 * @param {string} title
 * @param {string | undefined} type
 * @param onChange
 * @param child
 * @param parent
 * @param nullable
 * @param disabled
 * @param minimum_access
 * @constructor
 */
export default function UniversalInput({
  formik,
  name,
  title = '',
  type = undefined,
  onChange,
  child,
  parent,
  nullable,
  disabled = false,
  minimum_access,
}: ITextField) {
  // District
  const [currentDistrict, setCurrentDistrict] = useState();//TODO: refactor

  useEffect(() => {
    setCurrentDistrict(
      dotProp.get(formik.values, parent ? parent : 'district.id'));
  }, [formik]);

  if (type === 'caption' && !name) {
    return (
      <FormHeading title={title}/>
    );
  }
  if (type === 'divider') {
    return (
      <FullWidthDivider/>
    );
  }
  if (!name) {
    return (
      <span>
        name property required
      </span>
    );
  }
  const {dotValue, dotErrors, dotTouched} = getFieldProps({formik, name});

  const input = <UniversalInputBuilder
    title={title}
    formik={formik}
    name={name}
    currentDistrict={currentDistrict}
    dotErrors={dotErrors}
    dotTouched={dotTouched}
    dotValue={dotValue}
    minimum_access={minimum_access}
    disabled={disabled}
    nullable={nullable}
    parent={parent}
    child={child}
    onChange={onChange}
    type={type}
  />;
  if (minimum_access) {
    return (
      <AdminOrSpecialCoordinatorElement>
        {input}
      </AdminOrSpecialCoordinatorElement>
    );
  } else {
    return input;
  }

}
