import {FormControl, InputLabel, MenuItem, Select} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {ValueTitleObject} from '../../../CommonInterfaces/IIdNameObject';
import {apiRequestHelper} from '../../../Helpers/ApiRequestHelper';
import {responseToIdNameOptions} from './Helpers/responseToIdNameOptions';

/**
 * Build dropdown for Entity.
 *
 * @param {string} action
 * @param gender
 * @returns {(props: IFilterGenerator) => React.ReactElement}
 * @constructor
 */
export function buildEntityDropdown(
  action: string, gender = 0, event: string, setEvent: any) {
  return (): React.ReactElement => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [options, setOptions] = useState<ValueTitleObject[]>(
      []);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const responseHandler = (response: any) => {
        responseToIdNameOptions(response, setOptions);
      };
      apiRequestHelper({
        action: action, responseHandler,
      });
    }, []);

    /**
     * Apply filtered value to table.
     * @param {React.ChangeEvent} e
     */
    function handleChange(e: React.ChangeEvent<{ value: unknown }>) {
      const val = e.target.value as string;
      setEvent(val);
    }

    const id = 'event';

    return (
      <React.Fragment>
        <FormControl fullWidth>
          <InputLabel id={`${id}-label`}>Мероприятие</InputLabel>
          <Select
            labelId={`${id}-label`}
            fullWidth
            value={event}
            onChange={handleChange}>
            {options.map(Option)}
          </Select>
        </FormControl>
      </React.Fragment>
    );
  };
}

const Option = (el: ValueTitleObject) => {
  return <MenuItem key={el.value} value={el.value}>{el.title}</MenuItem>;
};
