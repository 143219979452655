interface IL {
  [index: string]: string
}

export const LMessages: IL = {
  USER_UPDATED_SUCCESS: 'Пользователь успешно обновлён',
  USER_CREATED_SUCCESS: 'Пользователь успешно создан',
  USER_C_CARD_TITLE: 'Создание пользователя',

  SCHOOL_UPDATED_SUCCESS: 'Организация успешно обновлена',
  SCHOOL_CREATED_SUCCESS: 'Организация успешно создана',
  SCHOOL_DELETED_SUCCESS: 'Организация успешно удалена',
  SCHOOL_C_CARD_TITLE: 'Создание организации',
  SCHOOL_C_LIKE_CARD_TITLE: 'Создание организации на основе',

  DISTRICT_UPDATED_SUCCESS: 'Район успешно обновлен',
  DISTRICT_CREATED_SUCCESS: 'Район успешно создан',
  DISTRICT_DELETED_SUCCESS: 'Район успешно удален',
  DISTRICT_C_CARD_TITLE: 'Создание района',
  DISTRICT_U_CARD_TITLE: 'Изменение района',

  EVENT_UPDATED_SUCCESS: 'Мероприятие успешно обновлено',
  EVENT_CREATED_SUCCESS: 'Мероприятие успешно создано',
  EVENT_DELETED_SUCCESS: 'Мероприятие успешно удалено',
  EVENT_C_CARD_TITLE: 'Создание мероприятия',

  RESULT_IMPORT_SUCCESS: 'Результаты успешно загружены',
  RESULT_IMPORT_CONFLICT: 'Результаты успешно загружены: необходимо разрешить конфликты',
  RESULT_IMPORT_FAIL: 'Произошла ошибка при загрузке результатов',
  RESULT_C_CARD_TITLE: 'Создание результата',
  RESULT_CREATED_SUCCESS: 'Результат успешно создан',
  RESULT_UPDATED_SUCCESS: 'Результат успешно обновлен',
  RESULT_DELETED_SUCCESS: 'Результат успешно удален',

  LOGIN_TITLE: 'Вход в систему',
  LOGOUT_SUCCESS: 'Вы вышли из системы',
  LOGIN_SUCCESS: 'Вы вошли в систему',
  FORGOT_PASSWORD_TITLE: 'Восстановление пароля',
  RESET_PASSWORD_TITLE: 'Смена пароля',
  AUTH_5_MINUTES_TILL_LOGOUT: 'Сессия будет завершена через 5 минут.',

  REPORT_MAIN_INDICATORS_CARD_TITLE: 'Создание отчёта: Основные показатели',
  REPORT_SUBJECTS_TITLE: 'Создание отчёта: По предметам',
  REPORT_FULL_STUDENTS_CARD_TITLE: 'Создание отчёта: По участникам',
  REPORT_STUDENT_RESULTS_CARD_TITLE: 'Создание отчёта: Список участников',
  REPORT_TUTORS_CARD_TITLE: 'Создание отчёта: По наставникам',
  REPORT_ORGANIZATIONS_TITLE: 'Создание отчёта: По организациям',
  REPORT_SUCCESS: 'Отчёт сформирован',

  RESULT_UPLOAD_CANCEL_SUCCESS: 'Событие загрузки отменено',
  RESULT_UPLOAD_APPLY_SUCCESS: 'Событие загрузки применено',
  RESULT_UPLOAD_CANCEL_WARNING: 'Информация, внесенная в процессе разрешения конфликтов, не вернется к исходному состоянию (школа ученика, наставника и т.д.)',

  RESOLVE_SUCCESS: 'Конфликт разрешен',
  ALL_CONFLICTS_RESOLVED: 'Все конфликты разрешены',

};

interface IOption {
  [index: string]: string | any
}

interface IToString {
  [index: string]: IOption
}

const yesNoToString: IOption = {
  1: 'Нет',
  2: 'Да',
};

export const ToString: IToString = {
  is_active: yesNoToString,
  role_id: {
    1: 'Администратор',
    2: 'Куратор',
  },
  access_rule: {
    1: 'По школе',
    2: 'По району',
  },
  gender: {
    1: 'Мужской',
    2: 'Женский',
  },
  is_open: {
    1: 'Нет',
    2: 'Да',
  },
  is_citizen: yesNoToString,
  has_disability: yesNoToString,

  student: {
    is_citizen: yesNoToString,
    has_disability: yesNoToString,
    gender: {
      1: 'Мужской',
      2: 'Женский',
    },
  },
  result: {
    place_type: {
      1: 'Участник',
      2: 'Призёр',
      3: 'Победитель',
      4: 'Лауреат',
    },
  },
};
