import {ReadCardField} from '../../CommonInterfaces/Form';

export const ResultUploadReadFields: ReadCardField[] = [
  {
    title: 'Идентификатор',
    name: 'id',
  },
  {
    title: 'Статус',
    name: 'status.name',
  },
  {
    title: 'Файл импорта',
    name: 'file_uri',
    type: 'link',
  },
  {
    title: 'Количество импортированных результатов',
    name: 'result_count',
  },
  {
    title: 'Пользователь',
    name: 'user',
    type: 'user',
  },
  {
    title: 'Ошибки',
    name: 'errors',
    type: 'list',
  },
  {
    title: 'Создано',
    name: 'created_at',
    type: 'timestamp',
  },
  {
    title: 'Обновлено',
    name: 'updated_at',
    type: 'timestamp',
  },

];
