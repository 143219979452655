import {EntityWithTags} from '../../CommonInterfaces/EntityTag';

export interface IEditableDistrict extends EntityWithTags {
  'name': string,
}

export const createEmptyEditableDistrict = (): IEditableDistrict => {
  return {
    'name': '',
    'tags': {
      0: {
        name: '',
      },
    },
  };
};
