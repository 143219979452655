import {IdEntity} from '../../CommonInterfaces/IIdNameObject';
import {
  IResultEntity,
  ISchoolEntity,
  IStudentEntity,
  ITeacherEntity,
  ITeacherEntityContainer,
} from '../../CommonInterfaces/ITeacherEntity';

export interface IEditableResult {
  [index: string]: string | IStudentEntity | ITeacherEntityContainer | IResultEntity | Date | null,

  'result': IResultEntity,
  'student': IStudentEntity,

  'teachers': ITeacherEntityContainer
}

export const createEmptyEditableResult = (id = ''): IEditableResult => {
  return {
    'result': {
      event_id: id,
      subject_id: '',
      event_class: '',
      student_class: '',
      points: '',
      place: '',
      place_type: '1',
      command_name: '',
    },
    'student': {
      last_name: '',
      first_name: '',
      second_name: '',
      birthday: null,
      gender: '',
      is_citizen: '2',
      has_disability: '1',
      school: emptySchool,
    },

    'teachers': {
      0: emptyTeacher,
      1: emptyTeacher,
    },
  };
};

export const emptyDistrict: IdEntity = {
  id: '',
};

export const emptySchool: ISchoolEntity = {
  id: '',
  district: emptyDistrict,
};

export const emptyTeacher: ITeacherEntity = {
  last_name: '',
  first_name: '',
  second_name: '',
  school: emptySchool,
};
