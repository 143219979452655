import {
  createMuiTheme,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  }),
);

// const defaultTheme = createMuiTheme();

export const AppTheme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        // padding: defaultTheme.spacing(2),
        // textAlign: 'center',
      },
    },
  },
});
