import {useSnackbar} from 'notistack';
import React from 'react';
import {BackButton} from '../../Components/Buttons/BackButton';
import {CardContainer} from '../../Components/CardContainer/CardContainer';
import {CreateAndUpdateForm} from '../../Components/FormGenerators/CreateAndUpdateForm';
import {
  createEmptyEditableEvent,
  IEditableEvent,
} from '../../Components/FormGenerators/IEditableEvent';
import {RowOfButtons} from '../../Components/Layout/RowOfButtons';
import PageTitle from '../../Components/PageTitle/PageTitle';
import {LMessages} from '../../Localization/Localization';
import {IPageInterface} from '../entity/IPageInterface';
import {IASCContainer} from './AllowedSchoolsCard';
import {
  AllowedSchoolsElement,
  useAllowed,
} from './Elements/AllowedSchoolsElement';
import {EventUpdateFields} from './fields';
import {processBeforeSend} from './helpers';

/**
 * Event's create card.
 * @returns {JSX.Element}
 * @constructor
 */
export default function EventCreateCard({}: IPageInterface) {
  const emptyEntity: IEditableEvent = createEmptyEditableEvent();

  const {enqueueSnackbar} = useSnackbar();

  const [allowedBy, onDataChange] = useAllowed();

  return (
    <React.Fragment>

      <PageTitle title={LMessages.EVENT_C_CARD_TITLE}/>

      <RowOfButtons>
        <BackButton/>
      </RowOfButtons>

      <CardContainer>
        <CreateAndUpdateForm<IEditableEvent>
          action={'EVENT_CREATE'}
          fields={EventUpdateFields}
          data={emptyEntity}
          snackBar={enqueueSnackbar}
          preProcessData={processBeforeSend}
          onChange={onDataChange}
        />
      </CardContainer>
      <AllowedSchoolsElement allowedBy={allowedBy as IASCContainer}/>
    </React.Fragment>
  );
};
