import {Link} from '@reach/router';
import React from 'react';
import {Caption} from './Elements/Caption';
import {LineBody} from './Elements/LineBody';

interface IUserData {
  id: string,
  first_name: string,
  second_name: string,
  last_name: string,
}

export interface IUserLine {
  title: string,
  content: IUserData,
}

/**
 * Read-Card line for User.
 * @param {string} title
 * @param {IUserData} content
 * @returns {JSX.Element}
 * @constructor
 */
export function UserLine({
  title,
  content,
}: IUserLine) {
  const prefix = '/user/';
  return (
    <React.Fragment>
      <Caption value={title}/>

      <LineBody>
        <Link to={prefix + content.id}>
          {content.last_name} {content.first_name} {content.second_name}
        </Link>
      </LineBody>
    </React.Fragment>
  );
}
