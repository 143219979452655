import {useSnackbar} from 'notistack';
import React from 'react';
import {CardContainer} from '../../Components/CardContainer/CardContainer';
import {CreateAndUpdateForm} from '../../Components/FormGenerators/CreateAndUpdateForm';
import {
  createEmptyForget,
  IForget,
} from '../../Components/FormGenerators/ILoginCredentials';
import {ForgotPasswordFields} from './fields';
import {getCSRFCookie} from './getCSRFCookie';

export const ForgotPasswordForm = (props: any) => {
  const emptyForget = createEmptyForget();
  const {enqueueSnackbar} = useSnackbar();

  return (
    <CardContainer>
      <CreateAndUpdateForm<IForget>
        action={'FORGOT_PASSWORD'}
        fields={ForgotPasswordFields}
        beforeSubmit={getCSRFCookie}
        data={emptyForget}
        snackBar={enqueueSnackbar}
        submitTitle={'Войти'}
      />
    </CardContainer>
  );
};
