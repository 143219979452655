import * as R from 'ramda';
import React from 'react';
import {ICardData} from '../../CommonInterfaces/Form';
import {AdminOrHimself} from '../../Components/auth/AdminOrHimself';
import {BackButton} from '../../Components/Buttons/BackButton';
import {UpdateButton} from '../../Components/Buttons/UpdateButton';
import Card from '../../Components/Card/Card';
import {CardContainer} from '../../Components/CardContainer/CardContainer';
import {RowOfButtons} from '../../Components/Layout/RowOfButtons';
import PageTitle from '../../Components/PageTitle/PageTitle';
import RedirectTo404 from '../../Components/RedirectTo404';
import {IPageInterface} from '../entity/IPageInterface';
import {useGetEntityDetails, UserEntity} from '../entity/useGetEntityDetails';
import {UserReadFields} from './fields';
import UploadResultsOfUser from './UploadResultsOfUser';

/**
 * User's r-Card page.
 * @param {IPageInterface} props
 * @constructor
 */
export default function UserRCard({id}: IPageInterface) {
  const empty: ICardData = {};
  const {data, isError} = useGetEntityDetails<ICardData>(UserEntity.name,
    id as string,
    empty);

  const userName = data ? `${data.first_name} ${data.last_name}` : null;

  return (
    <React.Fragment>
      <RedirectTo404 isError={isError}/>

      {!R.isEmpty(data) && data ?
        <PageTitle
          title={`Пользователь ${userName}`}/> :
        null}

      <RowOfButtons>
        <BackButton/>
        <AdminOrHimself id={id as string}>
          <UpdateButton entity={'user'} id={id as string}/>
        </AdminOrHimself>
      </RowOfButtons>

      {
        data ?
          <CardContainer>
            <Card data={data} fields={UserReadFields}/>
          </CardContainer> : ''
      }

      {id ? <UploadResultsOfUser
        parentId={id}
        titleSuffix={`пользователя ${userName}`}
      /> : null}


    </React.Fragment>
  );
}
