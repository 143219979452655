import {
  ICreateUpdateFormField,
  ReadCardField,
} from '../../CommonInterfaces/Form';

export const EventReadFields: ReadCardField[] = [
  {
    title: 'Идентификатор',
    name: 'id',
  },
  {
    title: 'Название краткое',
    name: 'name_short',
  },
  {
    title: 'Название',
    name: 'name_full',
  },
  {
    title: 'Дата начала',
    name: 'start',
  },
  {
    title: 'Дата окончания',
    name: 'end',
  },
  {
    title: 'Детали',
    name: 'description',
  },
  {
    title: 'Максимальный балл',
    name: 'points',
  },
  {
    title: 'Является открытым',
    name: 'is_open',
  },
  {
    title: 'Направление',
    name: 'domain.name',
  },
  {
    title: 'Уровень',
    name: 'level.name',
  },
  {
    title: 'Тип',
    name: 'type.name',
  },
  {
    title: 'Первенство',
    name: 'championship.name',
  },
  {
    title: 'Форма',
    name: 'form.name',
  },
  {
    title: 'Статус',
    name: 'status.name',
  },
  {
    title: 'Академический год',
    name: 'academic_year.name',
  },
  {
    title: 'Район',
    name: 'district.name',
  },
  {
    title: 'Организация',
    name: 'school.name',
  },
  {
    title: 'Предметы и классы',
    name: 'subject_class',
  },
  {
    title: 'Организации с правом на редактирование результатов',
    name: 'allowed_schools_list',
  },
  {
    title: 'Организации с правом на редактирование результатов, выбранные индивидуально',
    name: 'allowed_schools',
  },
  {
    title: 'Метка школ с правом на редактирование результатов',
    name: 'allowed_school_tags',
  },
  {
    title: 'Метка районов с правом на редактирование результатов',
    name: 'allowed_district_tags',
  },
];

export const EventUpdateFields: ICreateUpdateFormField[] = [
  {
    title: 'Общая информация',
    type: 'caption',
  },
  {
    title: 'Название краткое',
    name: 'name_short',
  },
  {
    title: 'Название полное',
    name: 'name_full',
  },

  {
    title: 'Дата начала',
    name: 'start',
    type: 'date',
  },
  {
    title: 'Дата завершения',
    name: 'end',
    type: 'date',
  },

  {
    title: 'Дополнительная информация',
    name: 'description',
    type: 'multiline',
  },

  {
    title: 'Максимальный балл',
    name: 'points',
  },

  {
    title: 'Является открытым',
    name: 'is_open',
    type: 'yesNo',
  },

  {
    title: 'Направление',
    name: 'domain.id',
  },
  {
    title: 'Уровень',
    name: 'level.id',
  },
  {
    title: 'Тип',
    name: 'type.id',
    nullable: true,
  },
  {
    title: 'Первенство',
    name: 'championship.id',
  },
  {
    title: 'Форма',
    name: 'form.id',
  },
  {
    title: 'Статус',
    name: 'status.id',
  },
  {
    title: 'Академический год',
    name: 'academic_year.id',
  },

  {
    title: 'Район',
    name: 'district.id',
    type: 'district',
  },
  {
    title: 'Организация',
    name: 'school.id',
    type: 'district_school',
  },
  {
    title: 'Предметы и классы',
    name: 'subject_class',
    type: 'subject_class',
  },
  {
    title: 'Выбор организаций с правом на редактирование: по названию',
    name: 'allowed_schools',
  },
  {
    title: 'Выбор организаций с правом на редактирование: по метке организации',
    name: 'allowed_school_tags',
  },
  {
    title: 'Выбор организаций с правом на редактирование: по метке района',
    name: 'allowed_district_tags',
  },
];
