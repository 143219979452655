import {Link} from '@reach/router';
import React from 'react';
import {IdNameEntity} from '../CommonInterfaces/IIdNameObject';

export const TeachersRowRenderer = (rowData: any): any => {
  const uri = '/teacher/';
  const data = rowData.teacher.map((el: IdNameEntity) => {
    return <div key={el.id}><Link to={`${uri}${el.id}`}>{el.name}</Link></div>;
  });
  return (
    <span>{data ? data : null}</span>
  );
};
