import {Button} from '@material-ui/core';
import {Link} from '@reach/router';
import React from 'react';
import IMenuButtonLink from '../IMenuButtonLink';

/**
 * Button in navigation menu.
 * @constructor
 */
export default function MenuButton({title, link}: IMenuButtonLink) {
  return (
    <Button color="inherit" to={link} component={Link}>{title}</Button>
  );
}
