export interface IEditableUser {
  'login': string,
  'password': string,
  'email': string,
  'first_name': string,
  'second_name': string,
  'last_name': string,
  'phone_number_personal': string,
  'phone_number_work': string,
  'is_active': string,
  'role_id': string,
  'access_rule': string,
}

export const createEmptyEditableUser = (): IEditableUser => {
  return {
    'login': '',
    'password': '',
    'email': '',
    'first_name': '',
    'second_name': '',
    'last_name': '',
    'phone_number_personal': '',
    'phone_number_work': '',
    'is_active': '',
    'role_id': '',
    'access_rule': '',
  };
};
