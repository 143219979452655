import {MenuItem, Select} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {ValueTitleObject} from '../../../CommonInterfaces/IIdNameObject';
import {apiRequestHelper} from '../../../Helpers/ApiRequestHelper';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {set} from '../../../Store/filterDistrict';
import {selectDistrictFilterValue} from '../../../Store/selectors';
import {buildApplyFilterChange} from './Helpers/buildApplyFilterChange';
import {buildResponseToIdNameOptions} from './Helpers/responseToIdNameOptions';
import {IFilterGenerator} from './IFilterGenerator';
import {AnyOptionMale} from './StaticFilters';

export const DistrictFilter = (props: IFilterGenerator): React.ReactElement => {
  const district = useAppSelector(selectDistrictFilterValue);
  const dispatcher = useAppDispatch();

  const setDistrict = (value: string) => {
    dispatcher(set(value));
  };

  const [districtOptions, setDistrictOptions] = useState<ValueTitleObject[]>(
    []);
  useEffect(() => {
    const responseHandler = (response: any) => {
      const selectOptions = buildResponseToIdNameOptions(response);
      setDistrictOptions([AnyOptionMale, ...selectOptions]);
    };
    apiRequestHelper({
      action: 'DISTRICT_LIST', responseHandler,
    });
  }, []);

  const handleChange = buildApplyFilterChange(setDistrict, props);

  return (
    <Select value={district ? district : 0} onChange={handleChange}>
      {districtOptions.map(Option)}
    </Select>
  );
};

const Option = (el: ValueTitleObject) => {
  return <MenuItem key={el.value} value={el.value}>{el.title}</MenuItem>;
};
