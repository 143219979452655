import {Button} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import React, {useContext, useEffect, useState} from 'react';
import {useAppSelector} from '../../hooks';
import {LMessages} from '../../Localization/Localization';
import {selectLastRequestValue} from '../../Store/selectors';
import {getMe} from '../auth/PrivateRoute';
import {UserContext} from '../auth/UserContext';

export const Timer = () => {
  const {currentUser, setCurrentUser} = useContext(UserContext);

  const lastRequest = useAppSelector(selectLastRequestValue);

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const action = (key: any) => (
    <React.Fragment>
      <Button onClick={() => {
        getMe(setCurrentUser);
        closeSnackbar(key);
      }}>
        Продлить
      </Button>
    </React.Fragment>
  );

  const [messageShown, setMessageShown] = useState<boolean>(false);

  const secondsBeforeMessage = 115 * 60;
  const secondsBeforeAutoRequest = 121 * 60;
  const secondsMessageOnScreen = 5 * 60 * 1000;

  useEffect(() => {
    const timer = setInterval(() => {
      if (currentUser.authorized && lastRequest) {

        const secondsPast = (Date.now() - lastRequest) / 1000;
        if (secondsPast <= secondsBeforeMessage && messageShown) {
          setMessageShown(false);
        }

        if (secondsPast > secondsBeforeMessage && !messageShown) {
          enqueueSnackbar(LMessages.AUTH_5_MINUTES_TILL_LOGOUT, {
            variant: 'warning',
            autoHideDuration: secondsMessageOnScreen,
            action,
          });
          setMessageShown(true);
        }
        if (secondsPast > secondsBeforeAutoRequest) {
          getMe(setCurrentUser);
        }
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [currentUser, lastRequest, messageShown]);

  return null;
};
