import React from 'react';
import {Caption} from './Elements/Caption';
import {LineBody} from './Elements/LineBody';
import {IArrayLine} from './Interfaces';

/**
 * Read-Card line for timestamp.
 * @param {string} title
 * @param {string} content
 * @returns {JSX.Element}
 * @constructor
 */
export function ListLine({
  title,
  content,
}: IArrayLine) {
  const els = content ? content.map((el, index) => {
    return <LineBody key={index}>
      {el}
    </LineBody>;
  }) : null;
  return (
    <React.Fragment>
      <Caption value={title}/>
      {els}
    </React.Fragment>
  );
}
