import {useSnackbar} from 'notistack';
import * as R from 'ramda';
import React from 'react';
import {ICardData} from '../../CommonInterfaces/Form';
import {BackButton} from '../../Components/Buttons/BackButton';
import {CardContainer} from '../../Components/CardContainer/CardContainer';
import {CreateAndUpdateForm} from '../../Components/FormGenerators/CreateAndUpdateForm';
import {IEditableResult} from '../../Components/FormGenerators/IEditableResult';
import {RowOfButtons} from '../../Components/Layout/RowOfButtons';
import PageTitle from '../../Components/PageTitle/PageTitle';
import RedirectTo404 from '../../Components/RedirectTo404';
import {IPageInterface} from '../entity/IPageInterface';
import {
  ResultEntity,
  useGetEntityDetails,
} from '../entity/useGetEntityDetails';
import {ResultUpdateFields} from './fields';

/**
 * Result's update card.
 * @param {string | undefined} id
 * @return {JSX.Element}
 * @constructor
 */
export default function ResultUpdateCard({id}: IPageInterface) {
  const emptyEntity: ICardData = {};
  const {data, isError} = useGetEntityDetails<IEditableResult>(
    ResultEntity.name,
    id as string,
    emptyEntity as unknown as IEditableResult);
  const {enqueueSnackbar} = useSnackbar();

  return (
    <React.Fragment>
      <RedirectTo404 isError={isError}/>

      {data && !R.isEmpty(data) ? <PageTitle
        title={`Изменение результата #${data.result.id}`}
      /> : null}

      <RowOfButtons>
        <BackButton/>
      </RowOfButtons>

      {
        !R.isEmpty(data) ?
          <CardContainer>
            <CreateAndUpdateForm<IEditableResult>
              action={'RESULT_UPDATE'}
              id={id}
              fields={ResultUpdateFields}
              data={data as unknown as IEditableResult}
              snackBar={enqueueSnackbar}
            />
          </CardContainer> :
          null
      }
    </React.Fragment>
  );
}
