import {CssBaseline} from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(
  // <React.StrictMode> TODO: wait for updated material-ui w/o deprecated ref;
  <React.Fragment>
    <CssBaseline/>


    <App/>
  </React.Fragment>,
// </React.StrictMode>,
  document.getElementById('root'),
);

