import React from 'react';

export interface IDistrictContext {
  district: string | undefined,
  setDistrict: any
}

export const defaultDC = {
  district: undefined,
  setDistrict: undefined,
};

export const DistrictContext = React.createContext<IDistrictContext>(defaultDC);
