import {useSnackbar} from 'notistack';
import React from 'react';
import {IFormik} from '../../CommonInterfaces/IFormik';
import {BackButton} from '../../Components/Buttons/BackButton';
import {CardContainer} from '../../Components/CardContainer/CardContainer';
import {CreateAndUpdateForm} from '../../Components/FormGenerators/CreateAndUpdateForm';
import {
  createEmptyEditableResult,
  IEditableResult,
} from '../../Components/FormGenerators/IEditableResult';
import {RowOfButtons} from '../../Components/Layout/RowOfButtons';
import PageTitle from '../../Components/PageTitle/PageTitle';
import {LMessages} from '../../Localization/Localization';
import {IPageInterface} from '../entity/IPageInterface';
import {ResultUpdateFields} from './fields';

/**
 * Result's create card.
 * @returns {JSX.Element}
 * @constructor
 */
export default function ResultCreateCard({}: IPageInterface) {
  const emptyEntity: IEditableResult = createEmptyEditableResult();

  const {enqueueSnackbar} = useSnackbar();

  const handleAlternateSubmit = (formik: IFormik<any>) => {
    formik.resetForm();
  };

  // @ts-ignore
  const deepMap = (fn, xs) => map(x => is(Array, x) ? deepMap(fn, x) : fn(x),
    xs);

  const removeEmpty = (data: any) => {
    const result = data;
    for (const [key, teacher] of Object.entries(result.teachers)) {
      // @ts-ignore
      if (teacher.first_name === '' && teacher.second_name === '' &&
        // @ts-ignore
        teacher.last_name === '') {
        delete result.teachers[key];
      }
    }
    return result as unknown as IEditableResult;
  };

  return (
    <React.Fragment>

      <PageTitle title={LMessages.RESULT_C_CARD_TITLE}/>

      <RowOfButtons>
        <BackButton/>
      </RowOfButtons>

      <CardContainer>
        <CreateAndUpdateForm<IEditableResult>
          preProcessData={removeEmpty}
          action={'RESULT_CREATE'}
          action_alternative={'RESULT_CREATE_AND_STAY'}
          action_alternative_title={'Сохранить и добавить следующий'}
          fields={ResultUpdateFields}
          data={emptyEntity}
          snackBar={enqueueSnackbar}
          afterSuccess={handleAlternateSubmit}
        />
      </CardContainer>
    </React.Fragment>
  );
};
