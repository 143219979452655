import {Button, Grid} from '@material-ui/core';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import {Link} from '@reach/router';
import React from 'react';

interface IEditButton {
  entity: string,
  id: string
  variant?: 'text' | 'outlined' | 'contained';
}

/**
 * Link from Read Card to Update Card.
 * @param {string} entity
 * @param {string} id
 * @param {"text" | "outlined" | "contained" | undefined} variant
 * @returns {JSX.Element}
 * @constructor
 */
export const UpdateButton = ({
  entity,
  id,
  variant = 'outlined',
}: IEditButton) => {
  return (
    <Grid item>
      <Button
        component={Link}
        to={`/${entity}/${id}/update`}
        variant={variant}
        color={'primary'}
        startIcon={<AddBoxOutlinedIcon/>}>
        Редактировать
      </Button>
    </Grid>
  );
};
