import React from 'react';
import {useStyles} from './styles';

interface IMenuContainerProps {
  children: React.ReactNode
}

/**
 * Container for styling menu items.
 * @constructor
 */
export default function MenuContainer({children}: IMenuContainerProps) {
  const classes = useStyles();

  return (
    <div className={classes.menuContainer}>
      {children}
    </div>
  );
}
