import React, {useContext} from 'react';
import {UserContext} from './UserContext';

export const AdminRoute = ({as: Component, location, ...props}: any) => {
  const {currentUser} = useContext(UserContext);

  return (
    <div>
      {currentUser.role_id === 1 ? <Component {...props}/> : 'Access restricted'}
    </div>
  );
};
