import {
  ICreateUpdateFormField,
  ReadCardField,
} from '../../CommonInterfaces/Form';

export const UserReadFields: ReadCardField[] = [
  {
    title: 'Идентификатор',
    name: 'id',
  },
  {
    title: 'Логин',
    name: 'login',
  },
  {
    title: 'Email',
    name: 'email',
  },
  {
    title: 'Имя',
    name: 'first_name',
  },
  {
    title: 'Отчество',
    name: 'second_name',
  },
  {
    title: 'Фамилия',
    name: 'last_name',
  },
  {
    title: 'Личный телефон',
    name: 'phone_number_personal',
  },
  {
    title: 'Рабочий телефон',
    name: 'phone_number_work',
  },
  {
    title: 'Активен ли аккаунт',
    name: 'is_active',
  },
  {
    title: 'Роль ИД',
    name: 'role_id',
  },
  {
    title: 'Район',
    name: 'district.name',
  },
  {
    title: 'Организация',
    name: 'school.name',
  },
  {
    title: 'Права доступа',
    name: 'access_rule',
  },
  {
    title: 'Последняя авторизация',
    name: 'last_auth',
  },
];

export const UserUpdateFieldsFull: ICreateUpdateFormField[] = [
  {
    name: 'login',
    title: 'Логин',
  },
  {
    name: 'email',
    title: 'Email',
  },
  {
    name: 'password',
    title: 'Пароль',
    type: 'password',
  },
  {
    name: 'first_name',
    title: 'Имя',
  },
  {
    name: 'second_name',
    title: 'Отчество',
  },
  {
    name: 'last_name',
    title: 'Фамилия',
  },
  {
    name: 'phone_number_personal',
    title: 'Личный телефон',
  },
  {
    name: 'phone_number_work',
    title: 'Рабочий телефон',
  },
  {
    name: 'is_active',
    title: 'Активен ли аккаунт',
    type: 'is_active',
  },
  {
    name: 'role_id',
    title: 'Роль',
    type: 'role_id',
  },
  {
    name: 'district.id',
    title: 'Район',
    type: 'district',
  },
  {
    name: 'school.id',
    title: 'Организация',
    type: 'district_school',
  },
  {
    name: 'access_rule',
    title: 'Права доступа',
    type: 'access_rule',
  },
];

export const UserUpdateFieldsCoordinator: ICreateUpdateFormField[] = [
  {
    name: 'login',
    title: 'Логин',
  },
  {
    name: 'email',
    title: 'Email',
  },
  {
    name: 'password',
    title: 'Пароль',
    type: 'password',
  },
  {
    name: 'first_name',
    title: 'Имя',
  },
  {
    name: 'second_name',
    title: 'Отчество',
  },
  {
    name: 'last_name',
    title: 'Фамилия',
  },
  {
    name: 'phone_number_personal',
    title: 'Личный телефон',
  },
  {
    name: 'phone_number_work',
    title: 'Рабочий телефон',
  },
];
