import {IIdNameObject} from '../../../../CommonInterfaces/IIdNameObject';

export const nameToAction: IIdNameObject = {
  'domain.id': 'DOMAIN_LIST',
  'level.id': 'LEVEL_LIST',
  'type.id': 'TYPES_LIST',
  'championship.id': 'CHAMPIONSHIPS_LIST',
  'form.id': 'FORMS_LIST',
  'status.id': 'STATUS_LIST',
  'academic_year.id': 'ACADEMIC_YEAR_LIST',

  'result.event_id': 'EVENT_LIST',
  'result.subject_id': 'SUBJECTS_LIST',

  //reports
  'academic_year': 'ACADEMIC_YEAR_LIST',
  'event_id': 'EVENT_LIST',
  'level_id': 'LEVEL_LIST',
  'championship_id': 'CHAMPIONSHIPS_LIST',
  'subject_id': 'SUBJECTS_LIST',

};

export const arrayTypeNameToAction: IIdNameObject = {
  'allowed_schools': 'SCHOOLS_LIST',
  'allowed_school_tags': 'SCHOOL_TAGS_LIST',
  'allowed_district_tags': 'DISTRICTS_TAGS_LIST',
};

export const arrayTypeNameToCaption: IIdNameObject = {
  'allowed_schools': 'Организация',
  'allowed_school_tags': 'Метка организации',
  'allowed_district_tags': 'Метка района',
};
