import {Link, RouteComponentProps} from '@reach/router';
import {Column} from 'material-table';
import React from 'react';
import {AdminElement} from '../../Components/auth/AdminElement';
import {CreateButton} from '../../Components/Buttons/CreateButton';
import {ExportButton} from '../../Components/Buttons/ExportButton';
import Row from '../../Components/Layout/Row';
import {RowOfButtons} from '../../Components/Layout/RowOfButtons';
import PageTitle from '../../Components/PageTitle/PageTitle';
import {TagsRowRenderer} from '../../Components/Tags/TagsRowRenderer';
import {buildUniversalTagsFilter} from '../../Components/UniversalRListTable/Filters/buildUniversalTagsFilter';
import {DistrictContext} from '../../Components/UniversalRListTable/Helpers/DistrictContext';
import {useDistrictState} from '../../Components/UniversalRListTable/Helpers/useDistrictState';
import {UniversalRListTable} from '../../Components/UniversalRListTable/UniversalRListTable';

/**
 * District's Read list.
 * @param {RouteComponentProps} props
 * @return {JSX.Element}
 * @constructor
 */
export default function DistrictsReadList(props: RouteComponentProps) {
  const DC = useDistrictState();

  const apiUriPrefix = '/district/';
  const TagsFilter = buildUniversalTagsFilter('DISTRICTS_TAGS_LIST');

  const Columns: Column<any>[] =
    [
      {
        title: '#',
        field: 'id',
        filtering: false,
      },
      {
        title: 'Название',
        field: 'name',
        render: (rowData): React.ReactNode =>
          <Link to={`${apiUriPrefix}${rowData.id}`}>{rowData.name}</Link>,
      },
      {
        title: 'Метки',
        field: 'tags',
        sorting: false,
        filterComponent: TagsFilter,
        render: TagsRowRenderer,
      },
    ];
  const lastQueryKeyName = 'districtsReadList';

  return (
    <React.Fragment>
      <PageTitle title={'Районы'}/>
      <RowOfButtons>
        <ExportButton name={lastQueryKeyName}/>
        <AdminElement>
          <CreateButton entity={'district'}/>
        </AdminElement>
      </RowOfButtons>

      <Row>
        <DistrictContext.Provider value={DC}>
          <UniversalRListTable
            columns={Columns}
            action={'DISTRICTS_INDEX'}
            tableName={lastQueryKeyName}
          />
        </DistrictContext.Provider>
      </Row>

    </React.Fragment>
  );
}
