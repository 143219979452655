import {IdNameEntity as IOption} from '../../../../CommonInterfaces/IIdNameObject';

export const availableTypes: string[] = [
  'access_rule',
  'is_active',
  'role_id',
];

export const typeToOptions = (type: string): IOption[] => {
  switch (type) {
    case 'access_rule':
      return [
        {
          id: 1,
          name: 'По школе',
        },
        {
          id: 2,
          name: 'По району',
        },
      ];
    case 'is_active':
      return [
        {
          id: 1,
          name: 'Нет',
        },
        {
          id: 2,
          name: 'Да',
        },
      ];
    case 'role_id':
      return [
        {
          id: 1,
          name: 'Администратор',
        },
        {
          id: 2,
          name: 'Куратор',
        },
      ];
    case 'class':
      const result: IOption[] = [];
      for (let i = 1; i <= 11; i++) {
        result.push({id: i, name: i.toString()});
      }
      return result;
    default:
      return [];
  }
};
