import {
  ICreateUpdateFormField,
  ReadCardField,
} from '../../CommonInterfaces/Form';

export const DistrictFields: ReadCardField[] = [
  {
    title: 'Идентификатор',
    name: 'id',
  },
  {
    title: 'Название',
    name: 'name',
  },
  {
    title: 'Метки',
    name: 'tags',
  },
];

export const DistrictUpdateFields: ICreateUpdateFormField[] = [
  {
    title: 'Название',
    name: 'name',
  },
  {
    title: 'Метки',
    name: 'tags',
    type: 'tags',
  },
];
