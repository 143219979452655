import {Button, Grid} from '@material-ui/core';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import {Link} from '@reach/router';
import React from 'react';

interface ICreateButton {
  entity: string
}

/**
 * Link to Create Card.
 * @param {string} entity
 * @returns {JSX.Element}
 * @constructor
 */
export const CreateButton = ({entity}: ICreateButton) => {
  return (
    <Grid item>
      <Button
        component={Link}
        to={`/${entity}/create`}
        variant={'outlined'}
        color={'primary'}
        startIcon={<AddBoxOutlinedIcon/>}>
        Создать
      </Button>
    </Grid>
  );
};
