import {Grid} from '@material-ui/core';
import React from 'react';
import Row from './Row';

interface IButtonRow {
  children: React.ReactNode
}

export const RowOfButtons = ({children}: IButtonRow) => {
  return (
    <Row>
      <Grid container spacing={3} justify={'flex-end'}>
        {children}
      </Grid>
    </Row>
  );
};
