import {createStyles, makeStyles, Theme} from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuContainer: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'center',
    },
  }),
);
