import React, {useContext} from 'react';
import {UserContext} from './UserContext';

export const AdminOrHimselfRoute = ({
  as: Component,
  location,
  ...props
}: any) => {
  const {currentUser} = useContext(UserContext);

  return (
    <div>
      {currentUser.role_id === 1 ||
      currentUser.id == props.id ?
        <Component {...props}/> :
        'Access restricted'}
    </div>
  );
};
