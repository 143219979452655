import {useSnackbar} from 'notistack';
import React from 'react';
import {BackButton} from '../../Components/Buttons/BackButton';
import {CardContainer} from '../../Components/CardContainer/CardContainer';
import {CreateAndUpdateForm} from '../../Components/FormGenerators/CreateAndUpdateForm';
import {
  createEmptyEditableDistrict,
  IEditableDistrict,
} from '../../Components/FormGenerators/IEditableDistrict';
import {removeLastEmptyEntityTag} from '../../Components/FormGenerators/Inputs/SubjectClassField/EntityTag';
import {RowOfButtons} from '../../Components/Layout/RowOfButtons';
import PageTitle from '../../Components/PageTitle/PageTitle';
import {LMessages} from '../../Localization/Localization';
import {IPageInterface} from '../entity/IPageInterface';
import {DistrictUpdateFields} from './DistrictFields';

/**
 * District's Create card.
 * @return {JSX.Element}
 * @constructor
 */
export default function DistrictCreateCard({}: IPageInterface) {
  const emptyEntity: IEditableDistrict = createEmptyEditableDistrict();

  const {enqueueSnackbar} = useSnackbar();

  return (
    <React.Fragment>

      <PageTitle title={LMessages.DISTRICT_C_CARD_TITLE}/>

      <RowOfButtons>
        <BackButton/>
      </RowOfButtons>

      <CardContainer>
        <CreateAndUpdateForm<IEditableDistrict>
          action={'DISTRICT_CREATE'}
          fields={DistrictUpdateFields}
          data={emptyEntity}
          snackBar={enqueueSnackbar}
          preProcessData={removeLastEmptyEntityTag}
        />
      </CardContainer>
    </React.Fragment>
  );
};
