import {RouteComponentProps} from '@reach/router';
import React from 'react';
import PageTitle from '../../Components/PageTitle/PageTitle';
import {LMessages} from '../../Localization/Localization';
import {LoginForm} from './LoginForm';

/**
 * Login page.
 * @param {RouteComponentProps} props
 * @returns {JSX.Element}
 * @constructor
 */
export default function LoginCard({}: RouteComponentProps) {
  return (
    <React.Fragment>
      <PageTitle title={LMessages.LOGIN_TITLE}/>
      <LoginForm/>
    </React.Fragment>
  );
}

