import React, {useContext} from 'react';
import AuthWrapper from '../../pages/auth/AuthWrapper';
import {UserContext} from './UserContext';

export const AdminOrSpecialCoordinatorElement = ({...props}: any) => {
  const {currentUser} = useContext(UserContext);

  if (currentUser.role_id === 1 || currentUser.is_special) {
    return <AuthWrapper {...props}/>;
  }

  return null;
};
