import {makeRemoveLastEmptyIdNameEntitySelect} from '../../Components/FormGenerators/Inputs/SubjectClassField/ArrayOfSelect';
import {removeLastEmptySubjectClass} from '../../Components/FormGenerators/Inputs/SubjectClassField/SubjectClassField';

export const processBeforeSend = (data: any) => {
  const schoolsProcessor = makeRemoveLastEmptyIdNameEntitySelect(
    'allowed_schools');
  const schoolsTagsProcessor = makeRemoveLastEmptyIdNameEntitySelect(
    'allowed_school_tags');
  const districtTagsProcessor = makeRemoveLastEmptyIdNameEntitySelect(
    'allowed_district_tags');
  data = schoolsProcessor(data);
  data = schoolsTagsProcessor(data);
  data = districtTagsProcessor(data);
  data = removeLastEmptySubjectClass(data);

  return data;
};
