import {useState} from 'react';
import {IDistrictContext} from './DistrictContext';

export const useDistrictState = (): IDistrictContext => {
  const [currentDistrict, setCurrentDistrict] = useState<string | undefined>(
    undefined);
  return {
    district: currentDistrict,
    setDistrict: setCurrentDistrict,
  };
};
