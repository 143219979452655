import {RouteComponentProps} from '@reach/router';
import React from 'react';
import PageTitle from '../Components/PageTitle/PageTitle';

/**
 * 404 page.
 * @param {RouteComponentProps} props
 * @constructor
 */
export default function NotFound(props: RouteComponentProps) {
  return (
    <PageTitle title={'Страница не найдена'}/>
  );
}
