import React from 'react';
import {timestampToDateTime} from '../../../../Localization/DateFormatter';
import {Caption} from './Elements/Caption';
import {LineBody} from './Elements/LineBody';
import {ISimpleLine} from './Interfaces';

/**
 * Read-Card line for timestamp.
 * @param {string} title
 * @param {string} content
 * @returns {JSX.Element}
 * @constructor
 */
export function TimestampLine({
  title,
  content,
}: ISimpleLine) {
  return (
    <React.Fragment>
      <Caption value={title}/>

      <LineBody>
        <span>{timestampToDateTime(content)}</span>
      </LineBody>
    </React.Fragment>
  );
}
