import {
  Container,
  createStyles,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core';
import {Router} from '@reach/router';
import 'fontsource-roboto';
import {SnackbarProvider} from 'notistack';
import React from 'react';
import {Provider} from 'react-redux';
import {sprintf} from 'sprintf-js';
import {AdminOrHimselfRoute} from './Components/auth/AdminOrHimselfRoute';
import {AdminRoute} from './Components/auth/AdminRoute';
import PrivateRoute from './Components/auth/PrivateRoute';
import {UserContext, useUserState} from './Components/auth/UserContext';
import NavigationBar from './Components/NavigationBar/NavigationBar';
import {Timer} from './Components/Timer/Timer';
import NotFound from './pages/404';
import AuthWrapper from './pages/auth/AuthWrapper';
import ForgotPasswordCard from './pages/auth/ForgotPasswordCard';
import {Logout} from './pages/auth/Logout';
import ResetPasswordCard from './pages/auth/ResetPasswordCard';
import DistrictCreateCard from './pages/districts/c-card';
import DistrictReadCard from './pages/districts/r-card';
import DistrictsReadList from './pages/districts/r-list';
import DistrictUpdateCard from './pages/districts/u-card';
import EventCreateCard from './pages/events/c-card';
import EventCreateLikeCard from './pages/events/c-like-card';
import EventReadCard from './pages/events/r-card';
import EventsReadList from './pages/events/r-list';
import EventUpdateCard from './pages/events/u-card';
import ImportCard from './pages/import/import';
import {FullStudentReport} from './pages/reports/FullStudentReport';
import {MainIndicatorReport} from './pages/reports/MainIndicatorReport';
import {OrganizationReport} from './pages/reports/OrganizationReport';
import {StudentResultsReport} from './pages/reports/StudentResultsReport';
import {SubjectsReport} from './pages/reports/SubjectsReport';
import {TutorReport} from './pages/reports/TutorReport';
import ResultCreateCard from './pages/results/c-card';
import ResultCreateForEventCard from './pages/results/create-for-event-card';
import ResultReadCard from './pages/results/r-card';
import ResultsReadList from './pages/results/r-list';
import ResultUpdateCard from './pages/results/u-card';
import {ResolveConflictCard} from './pages/ResultUploads/conflict/ResolveConflictCard';
import ResultUploadReadCard from './pages/ResultUploads/ReadCard';
import ResultUploadsReadList from './pages/ResultUploads/ReadList';
import SchoolCCard from './pages/schools/c-card';
import SchoolsRList from './pages/schools/r-list';
import SchoolRCard from './pages/schools/ReadCard/r-card';
import SchoolUpdateCard from './pages/schools/u-card';
import StudentRCard from './pages/students/r-card';
import StudentsRList from './pages/students/r-list';
import TeacherRCard from './pages/teachers/r-card';
import TeachersRList from './pages/teachers/r-list';
import UserCCard from './pages/users/c-card';
import UserRCard from './pages/users/r-card';
import UsersRList from './pages/users/r-list';
import UserUCard from './pages/users/u-card';
import {store} from './Store/store';
import {AppTheme} from './theme/theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    container: {
      padding: 0,
    },
  }),
);

const idParam = ':id';

export const ResolveConflictCardURITemplate = '/result-upload/%s/resolve';
const ResolveConflictCardURIRoute = sprintf(ResolveConflictCardURITemplate,
  idParam);

/**
 * App entry point.
 * @constructor
 */
function App() {

  const classes = useStyles();
  const UserState = useUserState();

  return (
    <Provider store={store}>
      <ThemeProvider theme={AppTheme}>
        <UserContext.Provider value={UserState}>

          <SnackbarProvider>
            <Timer/>

            <div className={classes.root}>

              <NavigationBar/>

              <Container className={classes.container} maxWidth={'xl'}>
                <Router>
                  <ForgotPasswordCard path={'/forgot-password'}/>
                  <ResetPasswordCard path={'/reset-password/:token/:email'}/>

                  <PrivateRoute as={AuthWrapper} path={'/'}>
                    <UsersRList path={'/users/list'}/>

                    <UserRCard path={'/user/:id'}/>

                    <AdminOrHimselfRoute as={UserUCard}
                      path={'/user/:id/update'}/>
                    <AdminRoute as={UserCCard} path={'/user/create'}/>

                    <StudentsRList path={'/students/list'}/>
                    <StudentRCard path={'/student/:id'}/>

                    <TeachersRList path={'/teachers/list'}/>
                    <TeacherRCard path={'/teacher/:id'}/>

                    <SchoolsRList path={'/schools/list'}/>
                    <SchoolRCard path={'/school/:id'}/>

                    <AdminRoute as={SchoolCCard} path={'/school/create'}/>
                    <AdminRoute as={SchoolUpdateCard}
                      path={'/school/:id/update'}/>

                    <DistrictsReadList path={'/districts/list'}/>
                    <DistrictReadCard path={'/district/:id'}/>


                    <AdminRoute as={DistrictCreateCard}
                      path={'/district/create'}/>
                    <AdminRoute as={DistrictUpdateCard}
                      path={'/district/:id/update'}/>

                    <EventsReadList path={'/events/list'}/>
                    <EventReadCard path={'/event/:id'}/>

                    <AdminRoute as={EventCreateCard} path={'/event/create'}/>
                    <AdminRoute as={EventUpdateCard}
                      path={'/event/:id/update'}/>
                    <AdminRoute as={EventCreateLikeCard}
                      path={'/event/:id/create-like'}/>

                    <ResultsReadList path={'/results/list'}/>
                    <ResultReadCard path={'/result/:id'}/>
                    <ResultCreateCard path={'/result/create'}/>
                    <ResultCreateForEventCard
                      path={'/result/event/:id/create'}/>
                    <ResultUpdateCard path={'/result/:id/update'}/>

                    <ImportCard path={'/results/import'}/>

                    <MainIndicatorReport path={'/report/main-indicators'}/>
                    <FullStudentReport path={'/report/full-students'}/>
                    <StudentResultsReport path={'/report/student-results'}/>
                    <TutorReport path={'/report/tutors'}/>
                    <OrganizationReport path={'/report/organizations'}/>
                    <SubjectsReport path={'/report/subjects'}/>


                    <ResultUploadsReadList path={'/'}/>
                    <ResolveConflictCard path={ResolveConflictCardURIRoute}/>
                    <ResultUploadReadCard path={'/result-upload/:id'}/>


                    <Logout path={'/logout'}/>
                    <NotFound path={'/404'}/>
                  </PrivateRoute>
                  <NotFound default/>
                </Router>
              </Container>
            </div>
          </SnackbarProvider>
        </UserContext.Provider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
