import {ICreateUpdateFormField} from '../../CommonInterfaces/Form';

export const MainIndicatorsFields: ICreateUpdateFormField[] = [
  {
    title: 'Академический год',
    name: 'academic_year',
    nullable: true,
  },
  {
    title: 'Мероприятие',
    name: 'event_id',
    nullable: true,
  },
  {
    title: 'Район',
    name: 'district_id',
    type: 'district',
    nullable: true,
    minimum_access: 'special_coordinator',
  },
  {
    title: 'Уровень',
    name: 'level_id',
    nullable: true,
  },
  {
    title: 'Первенство',
    name: 'championship_id',
    nullable: true,
  },
  {
    title: 'Параллель',
    name: 'event_class',
  },
];

export const ReportByStudentFields: ICreateUpdateFormField[] = [
  {
    title: 'Академический год',
    name: 'academic_year',
    nullable: true,
  },
  {
    title: 'Мероприятие',
    name: 'event_id',
    nullable: true,
  },
  {
    title: 'Уровень',
    name: 'level_id',
    nullable: true,
  },
  {
    title: 'Предмет',
    name: 'subject_id',
    nullable: true,
  },
  {
    title: 'Район',
    name: 'district_id',
    type: 'district',
    child: 'school_id',
    nullable: true,
    minimum_access: 'special_coordinator',
  },
  {
    title: 'Образовательное учреждение учащегося',
    name: 'school_id',
    type: 'district_school',
    parent: 'district_id',
    nullable: true,
    minimum_access: 'special_coordinator',
  },
  {
    title: 'Первенство',
    name: 'championship_id',
    nullable: true,
  },
  {
    title: 'Параллель',
    name: 'event_class',
  },
];

export const ReportByOrganizationFields: ICreateUpdateFormField[] = [
  {
    title: 'Академический год',
    name: 'academic_year',
    nullable: true,
  },
  {
    title: 'Мероприятие',
    name: 'event_id',
    nullable: true,
  },
  {
    title: 'Уровень',
    name: 'level_id',
    nullable: true,
  },
  {
    title: 'Предмет',
    name: 'subject_id',
    nullable: true,
  },
  {
    title: 'Район',
    name: 'district_id',
    type: 'district',
    child: 'school_id',
    nullable: true,
    minimum_access: 'special_coordinator',
  },
  {
    title: 'Образовательное учреждение наставника',
    name: 'school_id',
    type: 'district_school',
    parent: 'district_id',
    nullable: true,
    minimum_access: 'special_coordinator',
  },
  {
    title: 'Первенство',
    name: 'championship_id',
    nullable: true,
  },
  {
    title: 'Параллель',
    name: 'event_class',
  },
];

export const ReportBySubjectsFields: ICreateUpdateFormField[] = [
  {
    title: 'Академический год',
    name: 'academic_year',
    nullable: true,
  },
  {
    title: 'Мероприятие',
    name: 'event_id',
    nullable: true,
  },
  {
    title: 'Уровень',
    name: 'level_id',
    nullable: true,
  },
  {
    title: 'Предмет',
    name: 'subject_id',
    nullable: true,
  },
  {
    title: 'Район',
    name: 'district_id',
    type: 'district',
    child: 'school_id',
    nullable: true,
    minimum_access: 'special_coordinator',
  },
  {
    title: 'Образовательное учреждение ученика',
    name: 'school_id',
    type: 'district_school',
    parent: 'district_id',
    nullable: true,
    minimum_access: 'special_coordinator',
  },
  {
    title: 'Первенство',
    name: 'championship_id',
    nullable: true,
  },
  {
    title: 'Параллель',
    name: 'event_class',
  },
];

export const ReportByTutorFields: ICreateUpdateFormField[] = [

  {
    title: 'Фамилия',
    name: 'last_name',
  },
  {
    title: 'Имя',
    name: 'first_name',
  },
  {
    title: 'Отчество',
    name: 'second_name',
  },
  {
    title: 'Академический год',
    name: 'academic_year',
    nullable: true,
  },
  {
    title: 'Мероприятие',
    name: 'event_id',
    nullable: true,
  },
  {
    title: 'Уровень',
    name: 'level_id',
    nullable: true,
  },
  {
    title: 'Предмет',
    name: 'subject_id',
    nullable: true,
  },
  {
    title: 'Район',
    name: 'district_id',
    type: 'district',
    child: 'school_id',
    nullable: true,
    minimum_access: 'special_coordinator',
  },
  {
    title: 'Образовательное учреждение наставника',
    name: 'school_id',
    type: 'district_school',
    parent: 'district_id',
    nullable: true,
    minimum_access: 'special_coordinator',
  },
  {
    title: 'Первенство',
    name: 'championship_id',
    nullable: true,
  },
  {
    title: 'Параллель',
    name: 'event_class',
  },
];
