import * as R from 'ramda';
import React from 'react';
import ResultsChildReadList from '../../ChildList/ResultsChildReadList';
import {ICardData} from '../../CommonInterfaces/Form';
import {BackButton} from '../../Components/Buttons/BackButton';
import Card from '../../Components/Card/Card';
import {CardContainer} from '../../Components/CardContainer/CardContainer';
import {RowOfButtons} from '../../Components/Layout/RowOfButtons';
import PageTitle from '../../Components/PageTitle/PageTitle';
import RedirectTo404 from '../../Components/RedirectTo404';
import {IPageInterface} from '../entity/IPageInterface';
import {
  StudentEntity,
  useGetEntityDetails,
} from '../entity/useGetEntityDetails';
import {StudentReadFields} from './fields';

/**
 * Student's r-Card page.
 * @param {IPageInterface} props
 * @constructor
 */
export default function StudentRCard({id}: IPageInterface) {
  const empty: ICardData = {};
  const {data, isError} = useGetEntityDetails<ICardData>(StudentEntity.name,
    id as string,
    empty);
  const name = data ? `${data.first_name} ${data.last_name}` : null;
  return (
    <React.Fragment>
      <RedirectTo404 isError={isError}/>

      {!R.isEmpty(data) && data ?
        <PageTitle
          title={`Ученик ${name}`}/> :
        null}

      <RowOfButtons>
        <BackButton/>
      </RowOfButtons>

      {
        data && !R.isEmpty(data) ?
          <CardContainer>
            <Card data={data} fields={StudentReadFields}/>
          </CardContainer> : ''
      }

      {id ?
        <ResultsChildReadList parentName={'student_id'} parentId={id}
          titleSuffix={` ученика ${name}`}/> :
        null}

    </React.Fragment>
  );
}
