import {Link, RouteComponentProps} from '@reach/router';
import {Column} from 'material-table';
import React from 'react';
import {ExportButton} from '../../Components/Buttons/ExportButton';
import Row from '../../Components/Layout/Row';
import {RowOfButtons} from '../../Components/Layout/RowOfButtons';
import PageTitle from '../../Components/PageTitle/PageTitle';
import {buildUniversalEntityFilter} from '../../Components/UniversalRListTable/Filters/buildUniversalEntityFilter';
import {DistrictContext} from '../../Components/UniversalRListTable/Helpers/DistrictContext';
import {useDistrictState} from '../../Components/UniversalRListTable/Helpers/useDistrictState';
import {UniversalRListTable} from '../../Components/UniversalRListTable/UniversalRListTable';
import {timestampToDateTime} from '../../Localization/DateFormatter';

/**
 * Result Upload's read list.
 * @param {RouteComponentProps} props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ResultUploadsReadList(props: RouteComponentProps) {
  const DC = useDistrictState();

  const uploadPrefix = '/result-upload/';
  const userPrefix = '/user/';
  const eventPrefix = '/event/';

  const statusLookup = {
    1: 'Подтвержден',
    2: 'Отменена',
    3: 'Ошибка прав доступа',
    4: 'Ошибка формата загрузки',
    5: 'Ожидание разрешения конфликта',
  };
  const eventFilter = buildUniversalEntityFilter('EVENT_LIST', 3);
  const levelFilter = buildUniversalEntityFilter('LEVEL_LIST');
  const academicYearFilter = buildUniversalEntityFilter('ACADEMIC_YEAR_LIST');

  const Columns: Column<any>[] =
    [
      {
        title: '#',
        field: 'id',
        filtering: false,
        render: (rowData): React.ReactNode =>
          <Link to={`${uploadPrefix}${rowData.id}`}>{rowData.id}</Link>,
      },
      {
        title: 'Статус',
        field: 'status',
        lookup: statusLookup,
        render: (rowData): React.ReactNode =>
          rowData.status.name,
      },
      {
        title: 'Результаты',
        field: 'result_count',
        filtering: false,
        render: (rowData): React.ReactNode =>
          <Link
            to={`${uploadPrefix}${rowData.id}`}>{rowData.result_count}</Link>,
      },
      {
        title: 'Фамилия пользователя',
        field: 'user.last_name',
        render: (rowData): React.ReactNode =>
          <Link
            to={`${userPrefix}${rowData.user.id}`}>{rowData.user.last_name}</Link>,
      },
      {
        title: 'Имя пользователя',
        field: 'user.first_name',
        render: (rowData): React.ReactNode =>
          <Link
            to={`${userPrefix}${rowData.user.id}`}>{rowData.user.first_name}</Link>,
      },
      {
        title: 'Мероприятие',
        field: 'event.id',
        filterComponent: eventFilter,

        render: (rowData): React.ReactNode =>
          <Link
            to={`${eventPrefix}${rowData.event.id}`}>{rowData.event.name}</Link>,
      },
      {
        title: 'Уровень',
        field: 'event.level.name',
        filterComponent: levelFilter,
      },
      {
        title: 'Год',
        field: 'event.academic_year.name',
        filterComponent: academicYearFilter,
      },
      {
        title: 'Создана',
        field: 'created_at',
        render: (rowData): React.ReactNode =>
          timestampToDateTime(rowData.created_at),
        filtering: false,
      },
      {
        title: 'Обновлена',
        field: 'updated_at',
        render: (rowData): React.ReactNode =>
          timestampToDateTime(rowData.updated_at),
        filtering: false,
      },
    ];
  const exceptions = [
    'user.first_name',
    'user.last_name',
    'event.level.name',
    'event.academic_year.name',
  ];
  const lastQueryKeyName = 'resultUploadsReadList';

  return (
    <React.Fragment>
      <PageTitle title={'Загрузки результатов'}/>
      <RowOfButtons>
        <ExportButton name={lastQueryKeyName}/>
      </RowOfButtons>

      <Row>
        <DistrictContext.Provider value={DC}>
          <UniversalRListTable
            searchExceptions={exceptions}
            columns={Columns}
            action={'RESULT_UPLOADS_INDEX'}
            tableName={lastQueryKeyName}
          />
        </DistrictContext.Provider>
      </Row>

    </React.Fragment>
  );
}
