export interface IEntityTag {
  name: string
}

export interface IEntityTagContainer {
  [key: number]: IEntityTag
}

export const emptyEntityTag: IEntityTag = {
  name: '',
};

export interface EntityWithTags {
  'tags': IEntityTagContainer
}
