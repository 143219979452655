import React from 'react';
import {ICardData, ReadCardField} from '../../CommonInterfaces/Form';
import {dotGet} from '../../Helpers/dotGet';
import {CardLine} from './UserCardLine/Lines/CardLine';
import {DownloadFileLine} from './UserCardLine/Lines/DownloadFileLine';
import {IdNameEntityLine} from './UserCardLine/Lines/IdNameEntityLine';
import {ListLine} from './UserCardLine/Lines/ListLine';
import {TimestampLine} from './UserCardLine/Lines/TimestampLine';
import {UserLine} from './UserCardLine/Lines/UserLine';

export interface ICard {
  data: ICardData | null,
  fields: ReadCardField[],
}

/**
 * Universal R-Card.
 * @param {ICardData | null} data
 * @param {ReadCardField[]} fields
 * @constructor
 */
export default function Card({
  data,
  fields,
}: ICard) {
  if (data) {
    const els = fields.map((el) => {
        if (!el.type) {
          return (
            <CardLine
              key={el.name}
              fieldName={el.name}
              title={el.title}
              content={data}
            />
          );
        }
        switch (el.type) {
          case 'link':
            return <DownloadFileLine
              title={el.title}
              content={dotGet(el.name)(data)}
              key={el.name}
            />;
          case 'timestamp':
            return <TimestampLine
              title={el.title}
              content={dotGet(el.name)(data)}
              key={el.name}
            />;
          case 'user':
            return <UserLine
              title={el.title}
              content={dotGet(el.name)(data)}
              key={el.name}
            />;
          case 'organization':
            return <IdNameEntityLine
              uriPrefix={'/school/'}
              title={el.title}
              content={dotGet(el.name)(data)}
              key={el.name}
            />;
          case 'district':
            return <IdNameEntityLine
              uriPrefix={'/district/'}
              title={el.title}
              content={dotGet(el.name)(data)}
              key={el.name}
            />;
          case 'list':
            return <ListLine
              title={el.title}
              content={dotGet(el.name)(data)}
              key={el.name}
            />;
          default:
            throw new Error('CardLine type processing error');
        }
      },
    );
    return (
      <div>{els}</div>
    );
  } else {
    return (
      <div/>
    );
  }
}
