import React from 'react';
import {LineList} from '../LineList';
import {LineValue} from '../LineValue';
import {Caption} from './Elements/Caption';
import {LineBody} from './Elements/LineBody';

export interface Field {
  title: string,
  fieldName: string
}

export interface IUserData {
  [key: string]: string
}

export interface ICardLine {
  fieldName: string,
  title: string,
  content: IUserData,
}

/**
 * R-Card line.
 * @param {string} fieldName
 * @param {string} title
 * @param {IUserData} content
 * @return {JSX.Element}
 * @constructor
 */
export function CardLine({
  fieldName,
  title,
  content,
}: ICardLine) {
  return (
    <div key={fieldName}>
      <Caption value={title}/>

      {Array.isArray(content[fieldName]) ?
        <LineList
          content={content}
          fieldName={fieldName}
        /> :
        <LineBody>
          <LineValue
            content={content}
            fieldName={fieldName}
          />
        </LineBody>
      }

    </div>
  );
}
