import {MuiThemeProvider, useTheme} from '@material-ui/core';
import produce from 'immer';
import MaterialTable, {Action, Column, MTableBody} from 'material-table';
import {ParsedUrlQueryInput} from 'querystring';
import React, {Dispatch, SetStateAction, useRef, useState} from 'react';
import {IRowData} from '../../CommonInterfaces/IRowData';
import {apiRequestHelper} from '../../Helpers/ApiRequestHelper';
import {uriGenerator} from '../../Helpers/UriGenerator';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {UTableLocalization} from '../../Localization/UniversalTableLocalization';
import {setExportQuery} from '../../Store/exportQuery';
import {ISavedField, setLastFilters} from '../../Store/lastFilters';
import {setLastOrder} from '../../Store/lastOrder';
import {setLastPage} from '../../Store/lastPage';
import {tableIcons} from './Styles/tableIcons';

interface ISearchParam extends ParsedUrlQueryInput {
  sortField: number,
  sortDir: 'asc' | 'desc' | undefined,
  filterField: number,
  filterValue: string
}

interface IDataPreprocessor{
    (data:any):[]
}

interface IRListTable {
  action?: string,
  actionUri?: string,
  actionIds?: string[],
  columns: Column<IRowData>[],
  searchExceptions?: string[],
  saveLastQuery?: Dispatch<SetStateAction<string>>,
  tableName: string
  tableActions?: (Action<IRowData> | ((rowData: IRowData) => Action<IRowData>))[]
  version?: number,
  filtering?: boolean
  sorting?: boolean
  components?: any
    dataPreprocessor?:IDataPreprocessor|null
}

const saveQueryForExport = (
  action: string, params: any, key: string, dispatch: any) => {
  const queryString = require('query-string');
  const {api} = uriGenerator({action});
  const exportParams = produce(params, (draft: any) => {
    delete draft['page'];
  });

  const result = api + '?' +
    queryString.stringify(exportParams as unknown as any,
      {arrayFormat: 'bracket'});
  dispatch(setExportQuery({key, value: result}));
};

/**
 * R-list table for students.
 * @constructor
 */
export function UniversalRListTable({
  action,
  actionUri,
  actionIds,
  columns: tableColumns,
  searchExceptions = [],
  saveLastQuery,
  tableName,
  tableActions = [],
  version = 0,
  filtering = true,
  sorting = true,
  components = {},
    dataPreprocessor=null,
}: IRListTable) {
  const [isLastFiltersLoaded, setIsLastFiltersLoaded] = useState<boolean>(
    false);

  const defaultFilters = useAppSelector(
    state => (!isLastFiltersLoaded) ? state.lastFilters.list[tableName] : null);

  const defaultOrder = useAppSelector(
    state => (!isLastFiltersLoaded) ? state.lastOrders.list[tableName] : null);

  /*  const defaultPage = useAppSelector(
      state => state.lastPage.list[tableName]);

    console.log('defaultPage');
    console.log(defaultPage);*/

  if (defaultFilters && !isLastFiltersLoaded) {

    defaultFilters?.forEach((val, index) => {
      for (let i = 0; i < tableColumns.length; i++) {
        if (tableColumns[i].field === val.field) {
          tableColumns[i].defaultFilter = val.value;
        }
      }

      if (defaultOrder?.fieldIndex !== undefined) {
        tableColumns[defaultOrder.fieldIndex].defaultSort = defaultOrder.order;

      }
    });
  }

  if (!isLastFiltersLoaded) {
    setIsLastFiltersLoaded(true);
  }

  const CurrentTheme = useTheme();

  const handleOrderChange = (
    orderBy: number, orderDirection: ('asc' | 'desc')) => {
    dispatch(setLastOrder(
      {key: tableName, value: {fieldIndex: orderBy, order: orderDirection}}));
  };

  const handlePageChange = (page: number) => {
    // console.log('save page');
    // console.log(page);
    dispatch(setLastPage(
      {key: tableName, value: page}));
  };

  const dispatch = useAppDispatch();
  const tableRef = useRef<any>();

  function saveFilters(tableRef: React.MutableRefObject<any>) {
    return function handler() {
      const columns: ISavedField[] = tableRef?.current?.state.columns.map(
        (column: any) => ({
          field: column.field,
          value: column.tableData.filterValue,
        }));

      dispatch(setLastFilters({key: tableName, value: columns}));
    }();
  }

  const baseComponents = {
    Body: (props: any) => <MTableBody {...props} onFilterChanged={(
      columnId: number,
      value: string) => {
      props.onFilterChanged(columnId, value);
      saveFilters(tableRef);
    }}/>,
  };

  const resultComponents = {...baseComponents, ...components};

  return (
    <MuiThemeProvider theme={CurrentTheme}>
      <MaterialTable<IRowData>
        actions={tableActions}
        tableRef={tableRef}
        components={resultComponents}
        onOrderChange={handleOrderChange}
        // onChangePage={handlePageChange}
        icons={tableIcons}
        columns={tableColumns}
        localization={UTableLocalization}
        data={(query) =>
          new Promise((resolve) => {
            const params: any = {};

            if (query.filters) {
              Object.entries(query.filters).forEach(
                ([, value]) => {
                  if (value.column.field) {
                    if (typeof value.column.field === 'string') {
                      const field = value.column.field;
                      let fField;

                      if ((field).includes('.') &&
                        searchExceptions.indexOf(field) === -1) {
                        fField = (field).substr(0,
                          (field).indexOf('.')) + '_id';
                      } else {
                        fField = field;
                      }

                      params[fField] = value.value;
                    }
                  }
                },
              );
            }

            params.page = query.page + 1;
            if (query.orderBy) {
              params.orderBy = query.orderBy.field;
              params.direction = query.orderDirection;
            }

            const responseHandler = (response: any) => {
                let data = response.data.data;
                if(dataPreprocessor){
                    data=dataPreprocessor(data)
                }
                resolve({
                data: data,
                page: response.data.meta.current_page - 1,
                totalCount: response.data.meta.total,
              });
            };
            const config = {
              params: params,
            };
            if (tableName && action) {
              saveQueryForExport(action, params, tableName, dispatch);
            }
            if (actionUri && actionIds) {
              apiRequestHelper({
                action: actionUri, id: actionIds, responseHandler, config,
              });
            } else if (action) {
              apiRequestHelper({
                action: action, responseHandler, config,
              });
            }

          })}

        options={{
          filtering: filtering,
          sorting: sorting,
          toolbar: false,
          draggable: false,
          pageSize: 20,
          pageSizeOptions: [],
          padding: 'dense',
          // initialPage: defaultPage,
        }}
      />
    </MuiThemeProvider>
  );
}

