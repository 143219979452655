import {ICreateUpdateFormField} from '../../CommonInterfaces/Form';

export const LoginCredentialFields: ICreateUpdateFormField[] = [
  {
    name: 'login',
    title: 'Логин',
  },
  {
    name: 'password',
    title: 'Пароль',
    type: 'password',
  },
];

export const ForgotPasswordFields: ICreateUpdateFormField[] = [
  {
    name: 'email',
    title: 'Email',
  },
];

export const ResetPasswordFields: ICreateUpdateFormField[] = [
  {
    name: 'email',
    title: 'Email',
    disabled: true,
  },
  {
    name: 'password',
    title: 'Пароль',
    type: 'password',
  },
  {
    name: 'password_confirmation',
    title: 'Подтверждение пароля',
    type: 'password',
  },
  {
    name: 'token',
    type: 'hidden',
  },
];
