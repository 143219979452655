import React from 'react';
import {ValueTitleObject} from '../../../CommonInterfaces/IIdNameObject';
import {filterGenerator} from '../Helpers/filterGenerator';

export const AnyOptionMale: ValueTitleObject =
  {
    value: 0,
    title: 'Любой',
  };
export const AnyOptionFemale: ValueTitleObject =
  {
    value: 0,
    title: 'Любая',
  };

export const AnyOptionNeutered: ValueTitleObject =
  {
    value: 0,
    title: 'Любое',
  };

export const yesNoFilter = filterGenerator([
  {
    value: 0,
    title: 'Любой',
  },
  {
    value: 1,
    title: 'Нет',
  },
  {
    value: 2,
    title: 'Да',
  },
]);

export const genderFilter = filterGenerator([
  {
    value: 0,
    title: 'Любой',
  },
  {
    value: 1,
    title: 'М',
  },
  {
    value: 2,
    title: 'Ж',
  },
]);

export const roleFilter = filterGenerator([
  {
    value: 0,
    title: 'Любая',
  },
  {
    value: 1,
    title: 'Администратор',
  },
  {
    value: 2,
    title: 'Куратор',
  },
]);

export const AccessByFilter = filterGenerator([
  {
    value: 0,
    title: 'Любому',
  },
  {
    value: 1,
    title: 'Школе',
  },
  {
    value: 2,
    title: 'Району',
  },
]);

export const PlaceTypeFilter = filterGenerator([
  {
    value: 0,
    title: 'Любой',
  },
  {
    value: 1,
    title: 'Участник',
  },
  {
    value: 2,
    title: 'Призёр',
  },
  {
    value: 3,
    title: 'Победитель',
  },
  {
    value: 3,
    title: 'Лауреат',
  },
]);

export const PlaceTypeLookup = {
  0: 'Любой',
  1: 'Участник',
  2: 'Призёр',
  3: 'Победитель',
  4: 'Лауреат',
};
