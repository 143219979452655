import produce from 'immer';
import {useState} from 'react';
import {Decision, IDecisionSaverCallback} from './ResolveConflictCard';

interface IUseSelectedDecision {
  def: number
}

export const useSelectedDecision = ({def}: IUseSelectedDecision) => {
  return useState<number>(def);
};

interface ISaveDecisionWrapper {
  saveDecision: IDecisionSaverCallback
  decision: Decision
  selected: number
}

export const saveDecisionWrapper = ({
  saveDecision,
  decision,
  selected,
}: ISaveDecisionWrapper) => {
  saveDecision(
    produce(decision, (draft: Decision) => {
      if (selected !== 0) {
        draft.payload = (selected == -1) ? 0 : selected
        draft.decision = (selected === -1) ? 1 : 2;
      }
    }),
  );
};
