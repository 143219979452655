import React from 'react';
import {LMessages} from '../../Localization/Localization';
import {IPageInterface} from '../entity/IPageInterface';
import {ReportByOrganizationFields} from './fields';
import {createCommonRequest, ICommonRequest} from './ReportInterfaces';
import {UniversalReportPage} from './UniversalReportPage';

export const OrganizationReport = ({}: IPageInterface) => {
  return (
    <UniversalReportPage<ICommonRequest>
      action={'REPORT_ORGANIZATIONS'}
      emptyEntity={createCommonRequest()}
      fields={ReportByOrganizationFields}
      title={LMessages.REPORT_ORGANIZATIONS_TITLE}
    />
  );
};
