import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import {Link} from '@reach/router';
import React from 'react';
import IMenuButtonLink from '../IMenuButtonLink';
import {useStyles} from './styles';

interface IDropDownMenuProps {
  title: string,
  elements: IMenuButtonLink[]
}

/**
 * Menu component.
 * @constructor
 */
export function DropDownMenu({title, elements}: IDropDownMenuProps) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const menuItems = elements.map((el) =>
    <MenuItem key={el.title}
      onClick={handleClose}
      component={Link}
      to={el.link}
    >{el.title}</MenuItem>,
  );

  return (
    <React.Fragment>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-dropdown-list-grow' : undefined}
        aria-haspopup="true"
        color="inherit"
        onClick={handleToggle}
      >
        {title}
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined}
        transition disablePortal className={classes.menuPaper}>
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ?
                'center top' :
                'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow"
                  onKeyDown={handleListKeyDown}>

                  {menuItems}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
