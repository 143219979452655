import React from 'react';
import {IdNameEntity} from '../../CommonInterfaces/IIdNameObject';

export const TagsRowRenderer = (rowData: any): any => {
  const data = rowData.tags.map((el: IdNameEntity) => {
    return <div key={el.id}>{el.name}</div>;
  });
  return (
    <span>{data ? data : null}</span>
  );
};
