import {createAction, createReducer} from '@reduxjs/toolkit';

export interface ISavedField {
  field: string,
  value?: string | number | number[]
}

interface EQC {
  [index: string]: ISavedField[]
}

interface lastFiltersState {
  list: EQC
}

export const setLastFilters = createAction<{ key: string, value: ISavedField[] }>(
  'lastFilters/set');
const initState = {list: {}} as lastFiltersState;
export const lastFiltersReducer = createReducer(initState, (builder) => {
  builder.addCase(setLastFilters, (state, action) => {
    const {key, value} = action.payload;
    state.list[key] = value;
  });
});
