import {Link} from '@reach/router';
import React from 'react';
import {IdNameEntity} from '../../../../CommonInterfaces/IIdNameObject';
import {Caption} from './Elements/Caption';
import {LineBody} from './Elements/LineBody';

export interface IIdNameEntityLine {
  title: string,
  content: IdNameEntity,
  uriPrefix: string
}

/**
 * Read-Card line for {id,name} entity.
 * @param {string} title
 * @param {IdNameEntity} content
 * @param uriPrefix
 * @returns {JSX.Element}
 * @constructor
 */
export function IdNameEntityLine({
  title,
  content,
  uriPrefix,
}: IIdNameEntityLine) {
  return (
    <React.Fragment>
      <Caption value={title}/>

      <LineBody>
        <Link to={uriPrefix + content.id}>
          {content.name}
        </Link>
      </LineBody>
    </React.Fragment>
  );
}
