import {createAction, createReducer} from '@reduxjs/toolkit';

interface LPC {
  [index: string]: number | undefined
}

interface lastPageState {
  list: LPC
}

export const setLastPage = createAction<{ key: string, value: number }>(
  'lastPage/set');
const initState = {list: {}} as lastPageState;
export const lastPageReducer = createReducer(initState, (builder) => {
  builder.addCase(setLastPage, (state, action) => {
    const {key, value} = action.payload;
    state.list[key] = value;
  });
});
