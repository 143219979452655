import {Container, Paper} from '@material-ui/core';
import React, {ReactElement} from 'react';
import Row from '../Layout/Row';
import {useStyles} from './styles';

interface ICardContainer {
  children: ReactElement | string,
  elevation?: 'medium' | 'low',
  outlined?: boolean
}

export const CardContainer = ({
  children,
  elevation = 'medium',
  outlined = false,
}: ICardContainer) => {
  const classes = useStyles();
  const elevationNumber = (elevation === 'medium') ? 2 : 1;
  const variant = outlined ? 'outlined' : 'elevation';

  return (
    <Row size={3}>
      <Container maxWidth={'sm'}>
        <Paper variant={variant} elevation={elevationNumber}>
          <Container className={classes.container}>
            {children}
          </Container>
        </Paper>
      </Container>
    </Row>
  );
};
