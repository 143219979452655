import {createAction, createReducer} from '@reduxjs/toolkit';

interface LastRequestState {
  value: number | undefined
}

export const up = createAction('lastRequest/up');
const initialState = {value: undefined} as LastRequestState;
export const lastRequestReducer = createReducer(initialState, (builder) => {
  builder.addCase(up, (state, action) => {
    state.value = Date.now();
  });
});
