import {Button, Grid} from '@material-ui/core';
import {navigate} from '@reach/router';
import React from 'react';

export const BackButton = () => {
  const back = () => {
    navigate(-1);
  };

  return (
    <Grid item>
      <Button variant={'outlined'} onClick={back}>
        Назад
      </Button>
    </Grid>
  );
};
