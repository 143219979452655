import React from 'react';
import {LMessages} from '../../Localization/Localization';
import {IPageInterface} from '../entity/IPageInterface';
import {ReportBySubjectsFields} from './fields';
import {createCommonRequest, ICommonRequest} from './ReportInterfaces';
import {UniversalReportPage} from './UniversalReportPage';

export const SubjectsReport = ({}: IPageInterface) => {
  return (
    <UniversalReportPage<ICommonRequest>
      action={'REPORT_SUBJECTS'}
      emptyEntity={createCommonRequest()}
      fields={ReportBySubjectsFields}
      title={LMessages.REPORT_SUBJECTS_TITLE}
    />
  );
};
