import * as dotProp from 'dot-prop';
import * as R from 'ramda';
import React, {useEffect, useState} from 'react';
import {IdNameEntity} from '../../../CommonInterfaces/IIdNameObject';
import {dotGet} from '../../../Helpers/dotGet';
import {ToString} from '../../../Localization/Localization';

export interface Field {
  title: string,
  fieldName: string
}

export interface IUserData {
  [key: string]: string
}

export interface IGetLineValue {
  fieldName: string,
  content: IUserData,
}

const dateFields = [
  'student.birthday',
];
const dateTimeFields = [
  'result.updated_at',
  'result.created_at',
];

/**
 * Get value for RCard line.
 * @param {string} fieldName
 * @param {IUserData} content
 * @return {JSX.Element}
 * @constructor
 */
export function LineValue({
  fieldName,
  content,
}: IGetLineValue) {
  const [data, setData] = useState<string | null>(null);
  useEffect(() => {
    if (R.isEmpty(content)) {
      return;
    }

    if (fieldName.includes('.')) {
      const value = dotGet(fieldName)(content);
      if (dateFields.indexOf(fieldName) > -1) {
        setData(new Date(value).toLocaleDateString());
        return;
      }
      if (dateTimeFields.indexOf(fieldName) > -1) {
        const date = new Date(value);
        setData(date.toLocaleDateString() + ' ' + date.toLocaleTimeString());
        return;
      }
      if (dotProp.get(ToString, fieldName)) {
        // @ts-ignore
        setData(dotProp.get(ToString, fieldName)[value]);
        return;
      }

      setData(value as string);
      return;
    }

    const value = content[fieldName];

    if (Array.isArray(value)) {
      const line = value.map((el: IdNameEntity) => {
          return el.name;
        },
      );
      setData(line.join(', '));
      return;
    }

    if (ToString[fieldName]) {
      setData(ToString[fieldName][value]);
      return;
    }

    setData(value);

  }, [fieldName, content]);

  return (
    <span>{data}</span>
  );
}
