import {
  ICreateUpdateFormField,
  ReadCardField,
} from '../../CommonInterfaces/Form';

export const SchoolFields: ReadCardField[] = [
  {
    title: 'Идентификатор',
    name: 'id',
  },
  {
    title: 'Название краткое',
    name: 'name_short',
  },
  {
    title: 'Название полное',
    name: 'name_full',
  },
  {
    title: 'Адрес',
    name: 'address',
  },
  {
    title: 'Телефон',
    name: 'phone_number',
  },
  {
    title: 'Email',
    name: 'email',
  },
  {
    title: 'Район',
    name: 'district.name',
  },
  {
    title: 'Метки',
    name: 'tags',
  },
];

export const SchoolUpdateFields: ICreateUpdateFormField[] = [
  {
    title: 'Название краткое',
    name: 'name_short',
  },
  {
    title: 'Название полное',
    name: 'name_full',
  },
  {
    title: 'Адрес',
    name: 'address',
  },
  {
    title: 'Телефон',
    name: 'phone_number',
  },
  {
    title: 'Email',
    name: 'email',
  },
  {
    title: 'Район',
    name: 'district.id',
    type: 'district',
  },
  {
    title: 'Метки',
    name: 'tags',
    type: 'tags',
  },
];
