import {useSnackbar} from 'notistack';
import React from 'react';
import {BackButton} from '../../Components/Buttons/BackButton';
import {CardContainer} from '../../Components/CardContainer/CardContainer';
import {CreateAndUpdateForm} from '../../Components/FormGenerators/CreateAndUpdateForm';
import {
  createEmptyEditableUser,
  IEditableUser,
} from '../../Components/FormGenerators/IEditableUser';
import {RowOfButtons} from '../../Components/Layout/RowOfButtons';
import PageTitle from '../../Components/PageTitle/PageTitle';
import {LMessages} from '../../Localization/Localization';
import {IPageInterface} from '../entity/IPageInterface';
import {UserUpdateFieldsFull} from './fields';

/**
 * User's C-Card.
 * @return {JSX.Element}
 * @constructor
 */
export default function UserCCard({}: IPageInterface) {
  const emptyUser: IEditableUser = createEmptyEditableUser();

  const {enqueueSnackbar} = useSnackbar();

  return (
    <React.Fragment>

      <PageTitle title={LMessages.USER_C_CARD_TITLE}/>

      <RowOfButtons>
        <BackButton/>
      </RowOfButtons>

      <CardContainer>
        <CreateAndUpdateForm<IEditableUser>
          action={'USER_CREATE'}
          fields={UserUpdateFieldsFull}
          data={emptyUser}
          snackBar={enqueueSnackbar}
        />
      </CardContainer>
    </React.Fragment>
  );
};
