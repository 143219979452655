import {ICardData} from '../../../CommonInterfaces/Form';
import {IEditableEvent} from '../../../Components/FormGenerators/IEditableEvent';
import {entityListToObject} from '../../../Components/FormGenerators/Inputs/SubjectClassField/ArrayOfSelect';
import {subjectClassArrayToObject} from '../../../Components/FormGenerators/Inputs/SubjectClassField/SubjectClassField';
import {
  EventEntity,
  useGetEntityDetails,
} from '../../entity/useGetEntityDetails';

/**
 * Get data for Event's Update and Create-Like cards.
 * @param {string} id
 * @returns {{isError: boolean, data: IEditableEvent | null}}
 */
export function useEventUpdateData(id: string) {
  const emptyEntity: ICardData = {};
  const processAfterReceive = (data: any) => {
    data = entityListToObject(data, 'allowed_schools');
    data = entityListToObject(data, 'allowed_school_tags');
    data = entityListToObject(data, 'allowed_district_tags');
    return subjectClassArrayToObject(data);
  };
  const {data, isError} = useGetEntityDetails<IEditableEvent>(
    EventEntity.name,
    id as string,
    emptyEntity as unknown as IEditableEvent,
    processAfterReceive);
  return {data, isError};
}
