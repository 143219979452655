import {createAction, createReducer} from '@reduxjs/toolkit';

interface exportQuery {
  value: string | undefined
}

interface EQC {
  [index: string]: string
}

interface exportQueryState {
  list: EQC
}

export const setExportQuery = createAction<{ key: string, value: string }>(
  'exportQuery/set');
const initState = {list: {}} as exportQueryState;
export const exportQueryReducer = createReducer(initState, (builder) => {
  builder.addCase(setExportQuery, (state, action) => {
    const {key, value} = action.payload;
    state.list[key] = value;
  });
});
