import {Typography} from '@material-ui/core';
import React from 'react';

interface ICaption {
  value: string
}

export const Caption = ({value}: ICaption) => {
  return (
    <Typography
      color={'textSecondary'}
      variant="caption" display="block"
      gutterBottom>
      {value}
    </Typography>
  );
};
