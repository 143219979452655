import {Redirect} from '@reach/router';
import React from 'react';

interface IRedirect404 {
  isError: boolean
}

/**
 * Redirect to 404 if isError.
 * @param {boolean} isError
 * @constructor
 */
export default function RedirectTo404({isError}: IRedirect404) {
  if (isError) {
    return (
      <Redirect to={'/404'} noThrow/>
    );
  } else {
    return null;
  }
}
