import {createAction, createReducer} from '@reduxjs/toolkit';

interface districtFilterState {
  value: string
}

export const set = createAction<string>('districtFilter/set');
const initialState = {value: ''} as districtFilterState;
export const districtFilterReducer = createReducer(initialState, (builder) => {
  builder.addCase(set, (state, action) => {
    state.value = action.payload;
  });
});
