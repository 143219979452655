import {Button} from '@material-ui/core';
import produce from 'immer';
import React, {useEffect, useState} from 'react';
import {
  emptyIdNameEntity,
  IIdNameEntityContainer,
} from '../../../../CommonInterfaces/IIdNameObject';
import {FormHeading} from '../../../Typography/FormHeading';
import {FullWidthDivider} from '../../../Typography/FullWidthDivider';
import {IEditableEvent} from '../../IEditableEvent';
import {UniversalEntitySelectInput} from '../UniversalEntitySelectInput';
import {useStyles} from './styles';

interface IArrayOfSelect {
  name: string
  formik: any,
  title: string
  action: string
  caption: string
  onChange?: any
}

export const entityListToObject = (data: any, field: string) => {
  if (data && Array.isArray(data[field])) {
    const ETObject: IIdNameEntityContainer = {};
    for (let i = 0; i < data[field].length; i++) {
      ETObject[i] = data[field][i];
    }
    if (Object.keys(ETObject).length === 0) {
      ETObject[0] = emptyIdNameEntity;
    }
    data[field] = ETObject;
  }
  return data;
};

export const makeRemoveLastEmptyIdNameEntitySelect = (field: string) => {
  return (data: any) => {
    const keys = Object.keys(data[field]);
    const key: number = keys.pop() as unknown as number;

    const elToRemove = data[field][key];

    if (elToRemove && elToRemove.id === '') {
      return produce(data, (draft: IEditableEvent) => {
        delete (draft[field] as IIdNameEntityContainer)[key];
      });
    }
    return data;
  };
};

export const ArrayOfSelect = ({
  name,
  formik,
  title,
  action,
  caption,
  onChange,
}: IArrayOfSelect) => {
  const [rows, setRows] = useState<any>([]);
  const [values, setValues] = useState<IIdNameEntityContainer>({});
  const [errors, setErrors] = useState<any>([]);

  const addPair = () => {
    const newEl = produce(values, (draft: IIdNameEntityContainer) => {
      const key: number = parseInt(Object.keys(draft).pop() as string);
      const newKey = key + 1;
      draft[newKey] = emptyIdNameEntity;
    });
    formik.setFieldValue(name, newEl, false);
    setValues(newEl);
  };

  useEffect(() => {

    setValues(formik.values[name]);
    setErrors(formik.errors[name]);
  }, [formik.values[name], formik.errors[name]]);

  useEffect(() => {
    if (onChange) {
      const dataToUpdate: { [x: string]: IIdNameEntityContainer; } = {};
      dataToUpdate[name] = values;
      onChange(dataToUpdate);
    }
    const lastEl = values[Object.keys(values).pop() as unknown as number];
    if ((lastEl && lastEl.id !== '')) {
      addPair();
    }

    let i = -1;
    const rows = Object.keys(values).map((el) => {
      i++;
      const deleteHandler = (i: number) => {
        let newEl: IIdNameEntityContainer;
        newEl = produce(values, (draft) => {
          delete draft[i];
        });

        if (Object.keys(newEl).length === 0) {
          newEl = {};
          newEl[0] = emptyIdNameEntity;
        }

        formik.setFieldValue(name, newEl, false);
        setValues(newEl);
      };

      return <IdNameEntityRow
        caption={caption}
        action={action}
        name={name} formik={formik}
        index={el as unknown as number}
        onDelete={deleteHandler}
        key={el}/>;
    });
    setRows(rows);
  }, [values, errors]);

  return (
    <React.Fragment>
      <FormHeading title={title}/>
      {rows}
    </React.Fragment>
  );

};

interface IIdNameEntityRow {
  formik: any
  name: string
  index: number
  onDelete: any
  action: string
  caption: string
}

const IdNameEntityRow = ({
  formik,
  name,
  index,
  onDelete,
  action,
  caption,
}: IIdNameEntityRow) => {
  const fieldDot = `${name}.${index}.id`;

  const deleteHandler = () => {
    onDelete(index);
  };

  return (
    <React.Fragment>
      <UniversalEntitySelectInput
        name={fieldDot}
        formik={formik}
        title={caption}
        action={action}
      />
      <Button
        className={useStyles().deleteButton} onClick={deleteHandler}
        variant="outlined" fullWidth type="button">
        Удалить
      </Button>
      <FullWidthDivider/>
    </React.Fragment>
  );
};
