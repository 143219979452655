import {Typography} from '@material-ui/core';
import React, {useEffect} from 'react';
import Row from '../../../Components/Layout/Row';
import {timestampToDate} from '../../../Localization/DateFormatter';
import {saveDecisionWrapper, useSelectedDecision} from './helpers';
import {ISchoolConflictResolveCard} from './interfaces';
import StudentSchoolChangedReadList from './StudentSchoolChangedReadList';

const getStudentData = (data: any) => {
  const firstName = data?.data?.student?.first_name;
  const secondName = data?.data?.student?.second_name;
  const lastName = data?.data?.student?.last_name;
  const birthday = data?.data?.student?.birthday;
  return {lastName, firstName, secondName, birthday};
};

export const StudentSchoolConflictResolveCard = ({
  data,
  id,
  type,
  decision,
  saveDecision,
}: ISchoolConflictResolveCard) => {
  const {birthday, secondName, firstName, lastName} = getStudentData(data);
  const [selected, setSelected] = useSelectedDecision({def: decision.payload});

  useEffect(() => {
    saveDecisionWrapper({decision, saveDecision, selected});
  }, [selected]);

  const schoolChangedTemplate = `Ученик (${lastName} ${firstName} ${secondName}, ${timestampToDate(
    birthday)}) сменил образовательное учреждение?`;
  const typoTemplate = `Ученик (${lastName} ${firstName} ${secondName}, ${timestampToDate(
    birthday)}) дублирует другого из-за опечатки?`;
  const head = (type as unknown as number === 1) ?
    schoolChangedTemplate :
    typoTemplate;

  return (
    <React.Fragment>
      <Row>
        <Typography variant={'h5'}>
          {head}
        </Typography>
      </Row>
      <Row>
        <Typography variant={'h6'}>
          {`Выберите существующего ученика или создание нового`}
        </Typography>
      </Row>
      <StudentSchoolChangedReadList
        selected={selected}
        setSelected={setSelected}
        actionUri={'CONFLICT_RESOLVE_OPTIONS'}
        actionIds={[id, type as unknown as string]}/>
    </React.Fragment>
  );
};
