import {MenuItem, Select} from '@material-ui/core';
import {Column, EditCellColumnDef} from 'material-table';
import React, {useEffect, useState} from 'react';
import {ValueTitleObject} from '../../../CommonInterfaces/IIdNameObject';
import {IRowData} from '../../../CommonInterfaces/IRowData';
import {apiRequestHelper} from '../../../Helpers/ApiRequestHelper';
import {useAppSelector} from '../../../hooks';
import {selectDistrictFilterValue} from '../../../Store/selectors';
import {buildResponseToIdNameOptions} from './Helpers/responseToIdNameOptions';
import {AnyOptionFemale} from './StaticFilters';

export interface IFilterGenerator {
  options: any,
  columnDef: Column<IRowData>;
  onFilterChanged: (rowId: string, value: any) => void;
}

export const SchoolFilter = (props: IFilterGenerator): React.ReactElement => {
  const DistrictValue = useAppSelector(selectDistrictFilterValue);

  const resetSchool = () => {
    setSelectedVal('');
    props.onFilterChanged(
      (props.columnDef as EditCellColumnDef).tableData.id.toString(), '');
  };
  const [schoolsOptions, setSchoolOptions] = useState<ValueTitleObject[]>(
    [AnyOptionFemale]);
  const [selectedVal, setSelectedVal] = useState<string | number>(
    props.columnDef.defaultFilter);
  useEffect(() => {
    const responseHandler = (response: any) => {
      resetSchool();
      const selectOptions = buildResponseToIdNameOptions(response);
      setSchoolOptions([AnyOptionFemale, ...selectOptions]);
    };
    if (DistrictValue) {
      apiRequestHelper({
        action: 'DISTRICT_SCHOOL_LIST', responseHandler, id: DistrictValue,
      });
    } else {
      setSchoolOptions([AnyOptionFemale]);
      resetSchool();
    }

  }, [DistrictValue]);


  /**
   * Apply filtered value to table.
   * @param {React.ChangeEvent} e
   */
  function handleChange(e: React.ChangeEvent<{ value: unknown }>) {
    const val = e.target.value as string;
    setSelectedVal(val);
    props.onFilterChanged(
      (props.columnDef as EditCellColumnDef).tableData.id.toString(), val);
  }

  return (
    <Select
      disabled={!DistrictValue || schoolsOptions.length === 1}
      value={selectedVal ? selectedVal : 0} onChange={handleChange}>
      {schoolsOptions.map(Option)}
    </Select>
  );
};

const Option = (el: ValueTitleObject) => {
  return <MenuItem key={el.value} value={el.value}>{el.title}</MenuItem>;
};
