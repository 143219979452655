import {navigate, RouteComponentProps} from '@reach/router';
import React, {useContext, useEffect, useState} from 'react';
import {
  unauthorizedUser,
  UserContext,
} from '../../Components/auth/UserContext';
import {apiService} from '../../Helpers/apiService';
import {uriGenerator} from '../../Helpers/UriGenerator';

export const Logout = ({location}: RouteComponentProps) => {
  const {setCurrentUser} = useContext(UserContext);
  const [loggedOut, setLoggedOut] = useState<boolean>(false);

  useEffect(() => {
    const {api} = uriGenerator({action: 'LOGOUT'});
    apiService.post(api).then((response) => {
      setCurrentUser(unauthorizedUser);
      setLoggedOut(true);
      navigate('/');
    });
  });

  return (
    <div/>
  );
};
