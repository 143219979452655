import {Typography} from '@material-ui/core';
import React from 'react';
import Row from '../Layout/Row';

interface IPageTitle {
  title: string
}

/**
 * Row with title.
 *
 * @param {string} title
 * @constructor
 */
export default function PageTitle({title}: IPageTitle) {
  return (
    <Row>
      <Typography variant={'h4'}>
        {title}
      </Typography>
    </Row>
  );
}
