import {ReadCardField} from '../../CommonInterfaces/Form';

export const TeacherReadFields: ReadCardField[] = [
  {
    title: 'Идентификатор',
    name: 'id',
  },
  {
    title: 'Имя',
    name: 'first_name',
  },
  {
    title: 'Отчество',
    name: 'second_name',
  },
  {
    title: 'Фамилия',
    name: 'last_name',
  },
  {
    title: 'Район',
    name: 'district.name',
  },
  {
    title: 'Организация',
    name: 'school',
    type: 'organization',
  },
];
