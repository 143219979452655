import * as R from 'ramda';
import React from 'react';
import {IWrapper} from '../CommonInterfaces/IWrapper';

interface RenderIfLoaded {
  data: any,
}

export const RenderIfLoaded = ({children, data}: RenderIfLoaded & IWrapper) => {
  if (data && !R.isEmpty(data)) {
    return (children);
  } else {
    return (
      <React.Fragment>
      </React.Fragment>
    );
  }
};
