import {Button, Grid, Link} from '@material-ui/core';
import {RouteComponentProps} from '@reach/router';
import {useSnackbar} from 'notistack';
import React, {useState} from 'react';
import {IStringIndexedByNumber} from '../../CommonInterfaces/IIdNameObject';
import {CardContainer} from '../../Components/CardContainer/CardContainer';
import {RowOfButtons} from '../../Components/Layout/RowOfButtons';
import PageTitle from '../../Components/PageTitle/PageTitle';
import {buildEntityDropdown} from '../../Components/UniversalRListTable/Filters/buildEntityDropdown';
import {
  ImportResponseMessages,
  responseToImportSuccessBuilder,
  responseToSuccessMessageBuilder,
} from '../../Helpers/ApiRequestHelper';
import {apiService} from '../../Helpers/apiService';
import {uriGenerator} from '../../Helpers/UriGenerator';
import {LMessages} from '../../Localization/Localization';

/**
 * Import result card.
 * @returns {JSX.Element}
 * @constructor
 */
export default function ImportCard({}: RouteComponentProps) {
  const [eventId, setEvent] = useState<string>('0');
  const {enqueueSnackbar} = useSnackbar();

  const eventFilter = buildEntityDropdown('EVENT_LIST', 3,
    eventId, setEvent)();

  const onChange = (event: any) => {
    const file = event.target.files[0];
    const data = new FormData();
    data.append('import', file);

    const {api, redirect} = uriGenerator(
      {action: 'RESULTS_IMPORT', id: eventId});

    const messages: ImportResponseMessages = {
      1: LMessages.RESULT_IMPORT_SUCCESS,
      5: LMessages.RESULT_IMPORT_CONFLICT,
    };
    const responseWithMessage = responseToImportSuccessBuilder(messages,
      enqueueSnackbar, redirect);
    apiService.post(api, data).then(responseWithMessage).catch((response) => {
      const error = response.response.data.errors;
      enqueueSnackbar(error, {
        variant: 'error',
      });
    });
  };

  const {api: downloadUri} = uriGenerator({action: 'RESULTS_DOWNLOAD_BLANK'});

  return (
    <React.Fragment>
      <PageTitle
        title={`Импорт результатов`}/>
      <RowOfButtons>
        <Grid item>
          <Link href={downloadUri}>
            Скачать шаблон
          </Link>
        </Grid>
      </RowOfButtons>

      <CardContainer>
        <div>
          <form>
            {eventFilter}
            <Button
              disabled={eventId === '0'}
              color="primary"
              variant="contained"
              fullWidth
              component="label"
            >
              Загрузить
              <input
                onChange={onChange}
                type="file"
                hidden
              />
            </Button>
          </form>
        </div>
      </CardContainer>

    </React.Fragment>
  );
}
